import React from 'react';
import { BarChart, Bar, XAxis, Tooltip, Legend } from 'recharts';
import {DateTime} from "luxon";

const BonusBarChart = ({reportBonus}: any) => {
    const formatDate = (timestamp:any) => {
        return DateTime.fromMillis(timestamp).toFormat('MM-yyyy');
    };

    return (
        <div className='flex-1' >
            <BarChart
                width={500}
                height={500}
                data={reportBonus}
            >
                <XAxis dataKey="createTime" tickFormatter={formatDate}/>
                {/*<YAxis />*/}
                <Tooltip labelFormatter={formatDate} />
                <Legend />
                <Bar dataKey="totalDoneBonus" fill="#4D8E5F" width={5}/>
                <Bar dataKey="totalWaitBonus" fill="#FF9933" />
            </BarChart>
        </div>
    );
}

export default BonusBarChart;