import React from 'react';
import Typography from '@mui/material/Typography';
import {AppBar, Avatar, Container, Toolbar} from '@mui/material';
import {Link} from "react-router-dom";
import {privateRoute, publicRoute} from "../../routes";
import {Email, Facebook, Phone} from "@mui/icons-material";

const Footer = () => {
  return (
      <>
          <AppBar square position='static' elevation={0} className='bg-black/90 text-white py-10'>
              <Toolbar>
                  <Container maxWidth='xl' className='flex justify-between'>
                      <div className='flex items-center gap-3'>
                          <Avatar src='/gap-logo.png' className='w-[80px] h-[80px]' />
                          <div className='text-white/80'>
                              <Typography variant='body2'>© 2022 Gap Soft.</Typography>
                              <Typography variant='body2'>All Rights Reserved.</Typography>
                          </div>
                      </div>

                      <div className='flex flex-col items-start gap-2'>
                          <Typography>About Us</Typography>
                          <Link className='flex items-center gap-2 text-white/80 hover:text-primary-main'
                                to={{
                                    pathname: publicRoute.privacyPolicy.url
                                }}
                          >Privacy policy
                          </Link>
                          <Link className='flex items-center gap-2 text-white/80 hover:text-primary-main'
                                to={{
                                    pathname: publicRoute.termsOfService.url
                                }}
                          >Terms of service
                          </Link>
                          <a
                              href={privateRoute.helpCenter.url}
                              target='_blank'
                              className='flex items-center gap-2 text-white/80 hover:text-primary-main'
                          >Help center
                          </a>

                          <Link className='flex items-center gap-2 text-white/80 hover:text-primary-main'
                                to={{
                                    pathname: publicRoute.termsOfService.url
                                }}
                          >Blog
                          </Link>
                      </div>

                      <div className='flex flex-col items-start gap-2'>
                          <Typography>Contact</Typography>
                          <a
                              href='https://www.facebook.com/gapsofts'
                              target='_blank'
                              className='flex items-center gap-2 text-white/80 hover:text-primary-main'
                          >
                              <Facebook /> Facebook
                          </a>
                          <a
                              href='mailto:admin@gapsoftware.asia'
                              target='_blank'
                              className='flex items-center gap-2 text-white/80 hover:text-primary-main'
                          >
                              <Email /> admin@gapsoftware.asia
                          </a>
                          <a
                              href='tel:0962304905'
                              target='_blank'
                              className='flex items-center gap-2 text-white/80 hover:text-primary-main'
                          >
                              <Phone /> 0962 304 905
                          </a>
                          <a
                              href='tel:07090262032'
                              target='_blank'
                              className='flex items-center gap-2 text-white/80 hover:text-primary-main'
                          >
                              <Phone /> 070 9026 2032
                          </a>
                      </div>
                  </Container>
              </Toolbar>
          </AppBar>
      </>
  );
};

export default Footer;
