import {
  AddOutlined,
  BookmarkBorderOutlined, BookmarkRemoveOutlined,
  DirectionsOutlined,
  WorkOutlineOutlined,
} from '@mui/icons-material';
import {
  Avatar,
  Button,
  CardMedia,
  Dialog,
  Hidden,
  IconButton,
  Pagination,
  Paper,
  Tooltip,
  Typography
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, Row, Select, Spin, Tag } from 'antd';
import { Alert } from 'components';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { privateRoute } from 'routes';
import { chatService, jobService, queryClient } from 'services';
import { JOB_COUNTRIES, JOB_STATUS_TYPES } from 'utils/constants';
import {formatBonus, formatCurrency, formatListToString, toFormat} from 'utils/converter';
import { JobSearch } from './components';
import {CandidatePopup} from "../Candidate/components";

type JobListProps = {
  showSearch?: boolean;
  showBanner?: boolean;
  searchParams?: any;
};

const JobList = ({ showSearch = true, showBanner = true, searchParams }: JobListProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { userId, isSuper, isAdmin, isCompany } = useSelector(profileSelector);

  const country = new URLSearchParams(location.search).get('type') ?? undefined;

  const [dataSearch, onSearchChange] = useSearch(searchParams);

  const [followed, setFollowed] = useState(['']);

  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const [jobSelected, setJobSelected] = useState(null);

  const handleOpenDialog = (object:any) => {
    setJobSelected(object);
    setIsOpenPopup(true);
  };

  const { data: { data: items = [], total = 0 } = {}, isFetching } = useQuery(
    ['getListInfoJob', dataSearch, country],
    () => jobService.getListInfoJob({ search: { ...dataSearch, country } }),
    { keepPreviousData: true
      ,
      onSuccess: (data) => {
        data.data.forEach((job) => {
          if (job.isSaved === 1 && !followed.includes(job.id+'')) {
            followed.push(job.id+'')
          }
        });
      }
    },
  );

  const { mutate: updateJob } = useMutation(jobService.updateJob, {
    onSuccess: (data, body) => {
      Alert.success({ message: t('update job successfully') });

      const { id, status }: { id: number; status: number } = body;
      queryClient.setQueryData(['getListInfoJob', dataSearch, country], {
        data: items.map((item) => {
          if (item.id === id) {
            item.status = status;
          }
          return item;
        }),
        total,
      });
    },
  });

  const { mutate: createFollow } = useMutation(chatService.createFollow, {
    onSuccess: (data, body) => {
      if (body.status === 1){
        followed.push(body.followId)
        // setFollowed((array) => array.concat(body.followId));
      }
      if(body.status === 0){
        followed.filter(id => id !== body.followId);
        setFollowed(followed.filter(id => id !== body.followId));
      }

    },
  });

  const TablePagination = () => (
    <Pagination
      count={Math.ceil(total / 10)}
      page={dataSearch.page + 1}
      onChange={(event, nextPage) => {
        onSearchChange({ page: nextPage - 1 });
      }}
    />
  );

  return (
    <>
      <div className='flex items-center flex-wrap mb-6'>
        <IconButton>
          <WorkOutlineOutlined />
        </IconButton>
        <Typography variant='h6'>{t('Jobs list')}</Typography>

        <Tag color='orange' className='ml-3'>
          {JOB_COUNTRIES.find((item) => item.code === country)?.name}
        </Tag>

        <div className='flex-1' />
        <div className='flex gap-4'>
          {/*{searchParams && (*/}
          {/*  <Button*/}
          {/*    variant='outlined'*/}
          {/*    color='secondary'*/}
          {/*    startIcon={isFollowed ? <Favorite /> : <FavoriteBorder />}*/}
          {/*    disabled={isFollowed}*/}
          {/*    onClick={() => createFollow({ userId: userId!, followId: searchParams.idCompany, type: 1, status: 1 })}*/}
          {/*  >*/}
          {/*    {isFollowed ? 'Followed' : 'Follow'}*/}
          {/*  </Button>*/}
          {/*)}*/}

          {(isSuper || isAdmin || isCompany) && (
            <Link
              to={{
                pathname: privateRoute.jobCreate.path,
                search: new URLSearchParams(searchParams).toString(),
              }}
            >
              <Button color='secondary' startIcon={<AddOutlined />}>
                {t('Create job')}
              </Button>
            </Link>
          )}
        </div>
      </div>

      {showSearch && (
        <>
          <JobSearch onSearch={onSearchChange} />
          <Paper className='flex justify-between items-center flex-wrap p-4 mb-6'>
            <Typography variant='subtitle1' color='green'>
              {total} {t('jobs matched')}
            </Typography>
            <TablePagination />
          </Paper>
        </>
      )}

      <div className='flex items-start'>
        <div className='flex-1'>
          <Spin spinning={isFetching}>
            {items.map((job) => {
              const isFollowed = followed.includes(job.id+'');

              const status = JOB_STATUS_TYPES.find((item) => item.code === job.status);
              return (
                <Paper className='flex p-4 mb-6' key={job.id}>
                  <Avatar src={job.avatar} style={{ width: 120, height: 120, margin: '0px 24px 12px 0px' }}>
                    <Avatar src='/gap_logo.png' style={{ width: 120, height: 120 }} />
                  </Avatar>

                  <div className='flex-1'>
                    <Link to={privateRoute.jobView.url(job.id)} className='inline-flex items-center flex-wrap mb-3'>
                      <Typography component='span' variant='h6' color='primary' className='mr-2'>
                        {job.title}
                      </Typography>
                    </Link>
                    {isSuper || isAdmin ? (
                      <Select
                        value={job.status}
                        onChange={(value) => {
                          updateJob({ id: job.id, status: value });
                        }}
                        style={{ width: 120, color: status?.color }}
                      >
                        {JOB_STATUS_TYPES.map((item) => (
                          <Select.Option key={item.id} value={item.code} style={{ color: item.color }}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <Tag color={status?.color}>{status?.name}</Tag>
                    )}

                    {job.totalRefer > 0  && (
                       <Tag style={{marginLeft:5}} color={status?.color}>{job.totalRefer??0}&nbsp;{t('Applied')}</Tag>
                    )}

                    <Row gutter={24}>
                      <Col style={{ width: 200 + 24 }}>
                        <Typography color='textSecondary'>
                          {t('Number of vacancies')}: <span style={{ color: 'black' }}>{job.numberOfVacancies}</span>
                        </Typography>
                      </Col>
                      <Col style={{ width: 200 + 24 }}>
                        <Typography color='textSecondary'>
                          {t('Deadline')}: <span style={{ color: 'black' }}>{toFormat(job.deadline)}</span>
                        </Typography>
                      </Col>

                      {job.bonus > 0 && (
                        <Col flex={1}>
                            <Typography color='textSecondary'>
                              {t('Bonus')}:{' '}
                              <span style={{ color: job.bonus > 0 ? 'green' : 'silver' }}>{formatBonus(job.bonus)}</span>
                            </Typography>
                        </Col>
                        )}
                    </Row>

                    <Row gutter={24}>
                      <Col style={{ width: 200 + 24 }}>
                        <Typography color='textSecondary'>
                          {t('Form')}: <span style={{ color: 'black' }}>{job.form}</span>
                        </Typography>
                      </Col>
                      <Col style={{ width: 200 + 24 }}>
                        <Typography color='textSecondary'>
                          {t('Level')}: <span style={{ color: 'black' }}>{job.level}</span>
                        </Typography>
                      </Col>
                      <Col flex={1}>
                       <Typography color='textSecondary'>{t('Workplace')}:
                          <span style={{color: 'black', marginLeft:5 }}>{formatListToString(job.workplace)}</span>
                       </Typography>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col style={{ width: 200 + 24 }}>
                        <Typography color='textSecondary'>{t('Salary range')}:</Typography>
                        <Typography>
                          {formatCurrency(job.currency, job.fromSalary)} - {formatCurrency(job.currency, job.toSalary)}
                        </Typography>
                      </Col>
                      {job.language && (
                        <Col style={{ width: 200 + 24 }}>
                          <Typography color='textSecondary'>
                           {t('Language')}:
                           <span style={{color: 'black', marginLeft:5 }}>{job.language}</span>
                          </Typography>
                         </Col>
                      )}
                    </Row>
                  </div>

                  <Hidden xsDown>
                    <div className='flex flex-col justify-between'>
                      <div>
                        <Link to={privateRoute.jobView.url(job.id)}>
                          <Tooltip title={t('View detail')}>
                            <IconButton>
                              <DirectionsOutlined color='secondary' />
                            </IconButton>
                          </Tooltip>
                        </Link>
                        {isFollowed?(
                            <Tooltip title={t('UnSaved')}>
                              <IconButton
                                  onClick={() => createFollow({ userId: userId!, followId: job.id+'', type: 5, status: 0 })}
                              >
                                <BookmarkRemoveOutlined />
                              </IconButton>
                            </Tooltip>
                        ):(
                            <Tooltip title={t('Save')}>
                              <IconButton
                                  onClick={() => createFollow({ userId: userId!, followId: job.id+'', type: 5, status: 1 })}
                              >
                                <BookmarkBorderOutlined />
                              </IconButton>
                            </Tooltip>
                        )}
                      </div>
                      <div>
                        <Button color='secondary' onClick={() => handleOpenDialog(job)}>
                          {t('Refer')}
                        </Button>
                      </div>
                    </div>
                  </Hidden>
                </Paper>
              );
            })}
          </Spin>
          {jobSelected && (
              <Dialog fullWidth maxWidth='xl' open={isOpenPopup} onClose={() => setIsOpenPopup(false)}>
                <CandidatePopup job={jobSelected} onClose={() => setIsOpenPopup(false)}/>
              </Dialog>
          )}

          {total > 0 && (
            <div className='flex justify-center'>
              <TablePagination />
            </div>
          )}
        </div>

        {showBanner && (
          <Hidden mdDown>
            <div
              style={{ width: 480, marginLeft: 24, position: 'sticky', top: 64 + 24 }}
              className='flex flex-col gap-6'
            >
              <CardMedia
                image='https://static.recruitery.co/uploads/images/3e63af6a5a96440087fb166c6e9ea997_20220505035324.jpg'
                className='h-[240px] rounded-lg'
              />
              <CardMedia
                image='https://static.recruitery.co/uploads/images/e7138a3ddad643cc911ea46ac5c7248c_20220806132413.jpg'
                className='h-[240px] rounded-lg'
              />
              <CardMedia
                image='https://static.recruitery.co/uploads/images/94fe9285bd774b9483edce28dd203321_20220207020732.jpeg'
                className='h-[240px] rounded-lg'
              />
            </div>
          </Hidden>
        )}
      </div>
    </>
  );
};

export default JobList;
