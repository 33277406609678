import { ReferList } from 'views/Refer';

type JobReferralProps = {
  job: JobType;
};

const JobReferral = ({ job }: JobReferralProps) => {
  return <ReferList showTitle={false} searchParams={{ idJob: job.id }} />;
};

export default JobReferral;
