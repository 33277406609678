import { DateTime } from 'luxon';
import { privateRoute } from 'routes';

export const formatListToString = (listValue) => listValue.join(' | ')

export const formatCurrency = (currency = 'USD', salary) =>
  Number(salary ?? 0).toLocaleString('en-EN', {
    style: 'currency',
    currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

export const formatBonus = (bonus) => formatCurrency('VND', bonus).substr(1).concat('');

export const formatLevel = (level) => {
  try {
    if (level.startsWith('Fresher')) return 'green';
    if (level.startsWith('Junior')) return 'blue';
    if (level.startsWith('Middle')) return 'yellow';
    if (level.startsWith('Senior')) return 'red';
  } catch {}
};

export const normalizeJob = ({ currency = 'USD', ...job }) => {
  return {
    ...job,
    currency: (() => {
      switch (currency) {
        case 'VNĐ':
        case 'Đ':
          return 'VND';
        case '$':
          return 'USD';
        case '¥':
          return 'JPY';
        default:
          return currency;
      }
    })(),
  };
};

export const toFormat = (miliseconds) => (miliseconds ? DateTime.fromMillis(miliseconds).toFormat('dd/MM/yyyy') : '');

export const toFormatDateTime = (miliseconds) => (miliseconds ? DateTime.fromMillis(miliseconds).toFormat('dd/MM/yyyy HH:mm:ss') : '');

export const toRelative = (miliseconds) => {
  if (!miliseconds) return '';
  const date = DateTime.fromMillis(miliseconds);
  if (date.diffNow('day').days < -30) {
    return toFormat(miliseconds);
  }
  return date.toRelative();
};

export const getImageHistory = (item) =>{

  // type job
  if (1<= item.type <= 50){
    return item.job?.company.image
  }

  //type company
  if (51<= item.type <= 100){
    return item.company?.image
  }

  //type cv
  if (101<= item.type <= 150){
    return item.resume?.avatar
  }

  //type credit
  if (151<= item.type <= 200){
    return item.resume?.avatar
  }

  return ''
}

export const noticeFormat = (item) => {
  const user = item.userCreate?.username;
  const candidate = item.resume?.candidateName;
  const reminder = DateTime.fromMillis(item.resume?.calendarReminder ?? 0).toFormat('HH:mm dd/MM/yyyy');
  const createAt = DateTime.fromMillis(item.createTime).toFormat('HH:mm dd/MM/yyyy');
  const job = item.job?.title;
  switch (item.type) {
    case 1:
      return (
        <>
          Job <b>{job}</b> đã có ứng viên <b>{candidate}</b> ứng tuyển vào <b>{createAt}</b>.
        </>
      );
    case 2:
      return (
        <>
          Trạng thái của ứng viên <b>{candidate}</b> ứng tuyển vào Job <b>{job}</b> đã được cập nhật vào{' '}
          <b>{createAt}</b>.
        </>
      );
    case 3:
      return (
        <>
          Ứng viên <b>{candidate}</b> của bạn có lịch nhắc nhở vào <b>{reminder}</b>.
        </>
      );
    case 5:
      return (
        <>
          User <b>{user}</b> vừa ứng tuyển ứng viên <b>{candidate}</b> vào Job <b>{job}</b> đang chờ phê duyệt vào{' '}
          <b>{createAt}</b>.
        </>
      );
    case 7:
      return (
        <>
          Trạng thái của ứng viên <b>{candidate}</b> đã được cập nhật vào <b>{createAt}</b>.
        </>
      );
    default:
      return (
        <>
          Bạn có thông báo mới vào <b>{createAt}</b>
        </>
      );
  }
};

export const noticeRoute = (item) => {
  if (item.type === 1) return privateRoute.jobView.url(item.job?.id + '?tab=referral');
  if (item.type === 2) return privateRoute.referList.path;
  if (item.type === 3) return privateRoute.candidateUpdate.url(item.resume?.id);
  if (item.type === 5) return privateRoute.referList.path;
  if (item.type === 7) return privateRoute.candidateUpdate.url(item.resume?.id);
  if (item.type === 8) return privateRoute.jobView.url(item.job?.id);
  if (item.type === 10) return privateRoute.jobView.url(item.job?.id);
  if (item.type === 11) return privateRoute.package.path;
  return privateRoute.home.path;
};

export function timestampToDdMmYyyy(timestamp) {
  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 to month because it's zero-based
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}