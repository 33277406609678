import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import {reportService} from "../../services";
import {Avatar, Paper, Typography} from "@mui/material";
import {formatBonus, toFormat} from "../../utils/converter";
import {Table} from "antd";

const LeaderBoardView = () => {

    const { t } = useTranslation();
    // const { isSuper, isAdmin, isRecruit, isCompany } = useSelector(profileSelector);

    const { data: { data: leaderboardCv } = {} } =
        useQuery(['getLeaderBoardCv'], () =>
            reportService.getLeaderBoardCv({leaderBoardType: 1}));
    const leaderBoardCvIndex = leaderboardCv?.map((item, index) => ({... item, index}));

    const { data: { data: leaderboardBonus } = {} } =
        useQuery(['getLeaderBoardBonus'], () =>
            reportService.getLeaderBoardBonus({leaderBoardType: 2}));
    const leaderBoardBonusIndex = leaderboardBonus?.map((item, index) => ({... item, index}));

    return (
        <div className='flex items-start'>
            <div className='flex-1'>

                <div className='flex items-center mb-6'>
                    <Typography variant='h6' className='flex-1'>
                        {t('Leader board')}
                    </Typography>
                </div>

                <div className='flex justify-content-space-between'>
                    <Paper className='flex-1 items-center mr-8'>
                        <div className='flex items-center pl-4'
                                    style={{height: 50,
                                        color: '#0168a6',
                                        fontSize: 20,
                                        fontWeight: 700,
                                        backgroundColor: '#dff3ff'
                                    }}>
                            <img src={require('assets/icons/congratulation.svg').default} />
                            <span>Top of headhunter {toFormat(Date.now())}</span>
                        </div>

                        <Table
                            scroll={{ x: 100 }}
                            bordered={false}
                            showHeader={false}
                            dataSource={leaderBoardCvIndex}
                            rowKey={(record) => record.userId}
                            pagination={false}
                            columns={[
                                {
                                    title: 'Icon',
                                    dataIndex: 'icon',
                                    width: 40,
                                    render: (_, record) => {
                                        {
                                            if(record.index === 0)
                                                return (
                                                    <div className='flex items-center gap-3'>
                                                    <img src={require('assets/icons/top-1.svg').default} />
                                                    </div>
                                            )
                                            else if(record.index === 1)
                                                return (
                                                    <div className='flex items-center gap-3'>
                                                        <img src={require('assets/icons/top-2.svg').default} />
                                                    </div>
                                                )
                                            else if(record.index === 2)
                                                return (
                                                    <div className='flex items-center gap-3'>
                                                        <img src={require('assets/icons/top-3.svg').default} />
                                                    </div>
                                                )
                                            else{
                                                return (
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Typography variant='h6'>{record.index+1}</Typography>
                                                    </div>
                                                )
                                            }
                                        }

                                    }
                                },
                                {
                                    title: 'Username',
                                    dataIndex: 'username',
                                    width: 50,
                                    render: (_, record) => (
                                        <div className='flex items-center gap-3'>
                                            <Avatar src={record.avatarUrl} />
                                            <Typography>{record.username}</Typography>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'totalCv',
                                    dataIndex: 'totalCv',
                                    width: 50,
                                    render: (_, record) => (
                                        <div className='flex items-end justify-end gap-3'>
                                            <Typography style={{color:'red'}}>{record.totalCv}{t(' Profiles')}</Typography>
                                        </div>
                                    ),
                                },
                            ]}
                        >

                        </Table>

                    </Paper>

                    <Paper className='flex-1 items-center mr-8'>
                        <div className='flex items-center pl-4'
                             style={{height: 50,
                                 color: '#0168a6',
                                 fontSize: 20,
                                 fontWeight: 700,
                                 backgroundColor: '#dff3ff'
                             }}>
                            <img src={require('assets/icons/congratulation.svg').default} />
                            <span>Top of source {toFormat(Date.now())}</span>
                        </div>

                        <Table
                            scroll={{ x: 100 }}
                            bordered={false}
                            showHeader={false}
                            dataSource={leaderBoardBonusIndex}
                            rowKey={(record) => record.userId}
                            pagination={false}
                            columns={[
                                {
                                    title: 'Icon',
                                    dataIndex: 'icon',
                                    width: 40,
                                    render: (_, record) => {
                                        {
                                            if(record.index === 0)
                                                return (
                                                    <div className='flex items-center gap-3'>
                                                        <img src={require('assets/icons/top-1.svg').default} />
                                                    </div>
                                                )
                                            else if(record.index === 1)
                                                return (
                                                    <div className='flex items-center gap-3'>
                                                        <img src={require('assets/icons/top-2.svg').default} />
                                                    </div>
                                                )
                                            else if(record.index === 2)
                                                return (
                                                    <div className='flex items-center gap-3'>
                                                        <img src={require('assets/icons/top-3.svg').default} />
                                                    </div>
                                                )
                                            else{
                                                return (
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Typography variant='h6'>{record.index+1}</Typography>
                                                    </div>
                                                )
                                            }
                                        }

                                    }
                                },
                                {
                                    title: 'Username',
                                    dataIndex: 'username',
                                    width: 50,
                                    render: (_, record) => (
                                        <div className='flex items-center gap-3'>
                                            <Avatar src={record?.avatarUrl} />
                                            <Typography>{record?.username}</Typography>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'totalBonus',
                                    dataIndex: 'totalBonus',
                                    width: 50,
                                    render: (_, record) => (
                                        <div className='flex items-end justify-end  gap-4'>
                                            <Typography style={{color:'red'}}>{formatBonus(record?.totalBonus) ?? 0}</Typography>
                                        </div>
                                    ),
                                },
                            ]}
                        >

                        </Table>

                    </Paper>

                </div>

            </div>
        </div>

    );
};

export default LeaderBoardView;