import {
  AvTimerOutlined,
  CakeOutlined,
  CallOutlined,
  EmailOutlined,
  Language,
  LocalLibraryOutlined,
} from '@mui/icons-material';
import { Avatar, Paper, Typography } from '@mui/material';
import { toFormat } from 'utils/converter';

const ReferRow = ({ refer }: { refer: CvApplyType }) => {
  return (
    <Paper
      sx={{
        '& .MuiSvgIcon-root': {
          color: '#333C',
          marginX: 1,
        },
      }}
      className='flex justify-start gap-4 p-4'
    >
      <Avatar src={refer.candidateAvatar} style={{ width: 72, height: 72 }} />
      <div className='space-y-2 min-w-[240px]'>
        <Typography className='flex items-center'>
          <CallOutlined />
          {refer.candidatePhone}
        </Typography>
        <Typography className='flex items-center'>
          <EmailOutlined />
          {refer.candidateEmail}
        </Typography>
        <Typography className='flex items-center'>
          <CakeOutlined />
          {toFormat(refer.candidateDayOfBirth)}
        </Typography>
      </div>
      <div className='space-y-2'>
        <Typography className='flex items-center'>
          <AvTimerOutlined />
          {refer.candidateSkill}
        </Typography>
        <Typography className='flex items-center'>
          <Language />
          {refer.candidateLanguage}
        </Typography>
        <Typography className='flex items-center'>
          <LocalLibraryOutlined />
          {refer.candidateLevel}
        </Typography>
      </div>
    </Paper>
  );
};

export default ReferRow;
