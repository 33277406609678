import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <Paper
            elevation={3}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '2rem',
                textAlign: 'center',
                margin: '0 auto',
                boxShadow:'none',
                position:'absolute',
                transform:'translate(-50%,-50%)',
                top: '50%',
                left: '50%',
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                404
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
                Page Not Found
            </Typography>
            <Typography variant="body1">
                The page you are looking for does not exist.
            </Typography>
            <Button
                component={Link}
                to="/home"
                variant="contained"
                color="primary"
                sx={{ marginTop: '1rem'}}
                className=' hover:bg-black/5'
            >
                Back to Home
            </Button>
        </Paper>
    );
};

export default NotFoundPage;