import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import HighlightIcon from '@mui/icons-material/Highlight';
import {
    AnalyticsOutlined, AssignmentIndOutlined, CreditScoreOutlined,
    ExpandLess,
    ExpandMore,
    HelpCenterOutlined, HistoryRounded,
    Logout,
    MenuOutlined
} from '@mui/icons-material';
import {
    Avatar,
    Divider,
    Hidden,
    IconButton,
    List,
    ListItemText,
    Paper,
    Toolbar, ListItemIcon,
} from '@mui/material';
import { Dropdown } from 'antd';
import { Darkmode, LanguageBar } from 'components';
import { AppMenu } from 'containers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { profileSelector, signOut } from 'reducers/profile';
import { privateRoute } from 'routes';
import { NotificationPopover } from 'views/Notification';
import { CurrentCoin } from "views/Package/components";
import { IMenuItem, ISubMenuItem, MiniDrawerProps } from 'models/Drawer';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined'
import HorizontalTextScroll from 'components/TextScrollHorizontal';
const drawerWidth = 240;
const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});
const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);
export default function MiniDrawer(props: MiniDrawerProps) {
    const { children } = props;
    const theme = useTheme();
    const { isSuper, isAdmin, isCompany } = useSelector(profileSelector);
    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();
    const { avatarUrl } = useSelector(profileSelector);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    useEffect(() => {
        setIsOpenMenu(false);
    }, [location]);
    const initialMenuItems: IMenuItem[] = [
        {
            label: 'Report',
            icon: <ArticleOutlinedIcon />,
            subMenu: [
                {
                    label: 'Reports',
                    link: '/reports',
                },
                {
                    label: 'Dashboard',
                    link: '/home',
                },
            ],
            collapsed: true,
            visible: true
        },
        {
            label: 'Advertisement',
            icon: <HighlightIcon />,
            subMenu: [
                {
                    label: 'Break News',
                    link: '/ads/break-news/list',
                },
            ],
            collapsed: true,
            visible: true
        },
        {
            label: 'Email marketing',
            icon: <MailOutlineOutlinedIcon />,
            subMenu: [
                {
                    label: 'Campaign',
                    link: '/email/campaign/list',
                },
                {
                    label: 'Templates',
                    link: '/email/templates/list',
                },
            ],
            collapsed: true,
            visible: isSuper || isAdmin || isCompany
        },
    ];
    const [menuItems, setMenuItems] = useState([...initialMenuItems]);
    const renderMenu = (items: IMenuItem[]) => {
        return (
            <List>
                {items.filter(filtered => filtered.visible === true).map((item: any, index: number) => (
                    <div key={index}>
                        {item.subMenu ? (
                            <ListItemButton onClick={() => handleSubMenuToggle(index)}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                {open && <ListItemText primary={item.label} />}
                                {open && (item.collapsed ? <ExpandMore /> : <ExpandLess />)}
                            </ListItemButton>
                        ) : (
                            <ListItemButton
                                component={Link}
                                to={item.link}
                                onClick={(e: any) => e.stopPropagation()} // Prevent parent collapse
                                className={location.pathname === item.link ? 'bg-black/5' : ''}
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                {open && <ListItemText primary={item.label} />}
                            </ListItemButton>
                        )}
                        {!item.collapsed && item.subMenu && (
                            <List component="div" disablePadding>
                                {item.subMenu.map((subItem: ISubMenuItem, index: number) => (
                                    <ListItemButton
                                        key={index}
                                        component={Link}
                                        to={subItem.link}
                                        onClick={(e: any) => e.stopPropagation()} // Prevent parent collapse
                                        className={location.pathname === subItem.link ? 'bg-black/5' : ''}
                                    >
                                        <ListItemIcon>{subItem.icon}</ListItemIcon>
                                        {open && <ListItemText primary={subItem.label} />}
                                    </ListItemButton>
                                ))}
                            </List>
                        )}
                    </div>
                ))}
            </List>
        );
    };
    const handleSubMenuToggle = (index: any) => {
        const updatedMenuItems = [...menuItems];
        updatedMenuItems[index].collapsed = !updatedMenuItems[index].collapsed;
        setMenuItems(updatedMenuItems);
    };
    
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <HorizontalTextScroll />
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Hidden smDown>
                        <List className='flex flex-row gap-2'>
                            <AppMenu />
                        </List>
                    </Hidden>
                    <Hidden mdUp>
                        <IconButton onClick={() => setIsOpenMenu(true)}>
                            <MenuOutlined />
                        </IconButton>
                        <Drawer open={isOpenMenu} onClose={() => setIsOpenMenu(false)}>
                            <List style={{ width: 240 }}>
                                <AppMenu />
                            </List>
                        </Drawer>
                    </Hidden>

                    <div className='flex-1 flex justify-end gap-2 mr-2'>
                        <LanguageBar init />
                        <Darkmode />
                        <NotificationPopover />
                        <CurrentCoin />
                    </div>
                    <Dropdown
                        trigger={['click']}
                        placement='bottomRight'
                        overlay={
                            <List disablePadding component={Paper} style={{ width: 200, marginTop: 8 }}>
                                <ListItemButton component={Link} to={privateRoute.profile.path}>
                                    <ListItemIcon style={{ minWidth: 30 }}>
                                        <AssignmentIndOutlined />
                                    </ListItemIcon>
                                    <ListItemText>{t('Profile')}</ListItemText>
                                </ListItemButton>
                                {/* <ListItemButton component={Link} to={privateRoute.settingsAccount.path}>
                                    <ListItemIcon style={{ minWidth: 30 }}>
                                        <SettingsIcon />
                                    </ListItemIcon>
                                    <ListItemText>{t('Setting Account')}</ListItemText>
                                </ListItemButton> */}
                                <ListItemButton component={Link} to={privateRoute.package.path}>
                                    <ListItemIcon style={{ minWidth: 30 }}>
                                        <CreditScoreOutlined />
                                    </ListItemIcon>
                                    <ListItemText>{t('Top up credit')}</ListItemText>
                                </ListItemButton>
                                <Divider />
                                <ListItemButton component={Link} to={privateRoute.leaderBoardView.path}>
                                    <ListItemIcon style={{ minWidth: 30 }}>
                                        <AnalyticsOutlined />
                                    </ListItemIcon>
                                    <ListItemText>{t('LeaderBoard')}</ListItemText>
                                </ListItemButton>
                                <ListItemButton component={Link} to={privateRoute.helpCenter.url}>
                                    <ListItemIcon style={{ minWidth: 30 }}>
                                        <HelpCenterOutlined />
                                    </ListItemIcon>
                                    <ListItemText>{t('Help center')}</ListItemText>
                                </ListItemButton>
                                <ListItemButton component={Link} to={privateRoute.history.path}>
                                    <ListItemIcon style={{ minWidth: 30 }}>
                                        <HistoryRounded />
                                    </ListItemIcon>
                                    <ListItemText>{t('History')}</ListItemText>
                                </ListItemButton>
                                <Divider />
                                <ListItemButton
                                    onClick={() => {
                                        dispatch(signOut());
                                    }}
                                >
                                    <ListItemIcon style={{ minWidth: 30 }}>
                                        <Logout />
                                    </ListItemIcon>
                                    <ListItemText>{t('Logout')}</ListItemText>
                                </ListItemButton>
                            </List>
                        }
                    >
                        <IconButton style={{ padding: 8 }}>
                            <Avatar variant='circular' src={avatarUrl} style={{ width: 36, height: 36 }} />
                        </IconButton>
                    </Dropdown>
                </Toolbar>

            </AppBar>
            <Drawer
               
                variant="permanent"
                open={open}>
                <DrawerHeader>
                    <Avatar src='/gap-logo.png' className='mx-auto object-contain h-[104px] w-[90px]' />
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                {renderMenu(menuItems)}
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {children}
            </Box>
        </Box>
    );
}
