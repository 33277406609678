import {
  AccountCircleOutlined,
  CakeOutlined,
  Language,
  LocalLibraryOutlined,
  LocationOnOutlined, SubtitlesRounded,
} from '@mui/icons-material';
import { Avatar, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { toFormat } from 'utils/converter';
import {Tag} from 'antd';

type CandidateRowProps = {
  candidate: CandidateType;
  extra?: boolean;
};

const CandidateRow = ({ candidate, extra = false }: CandidateRowProps) => {

  return (
    <Paper
      sx={{
        '& .MuiSvgIcon-root': {
          color: '#333C',
          marginX: 1,
        },
      }}
      className='flex justify-start gap-4 p-4'
    >
      <Avatar src={candidate.avatar} style={extra ? { width: 120, height: 120 } : { width: 72, height: 72 }} />
      <div>
          <div className="flex">
              <div className='space-y-2 min-w-[240px]'>
                {extra && (
                  <Typography className='flex items-center'>
                    <AccountCircleOutlined />
                    <Link to={privateRoute.candidateUpdate.url(candidate.id)}>{candidate.candidateName}</Link>
                  </Typography>
                )}
                <Typography className='flex items-center'>
                  <LocationOnOutlined />
                  {candidate.address}
                </Typography>
                <Typography className='flex items-center'>
                  <CakeOutlined />
                  {toFormat(candidate.dayOfBirth)}
                </Typography>
              </div>
              <div className='space-y-2'>
                {extra && (
                  <Typography className='flex items-center'>
                    <LocalLibraryOutlined />
                    {candidate.level}
                  </Typography>
                )}
                <Typography className='flex items-center'>
                  <SubtitlesRounded />
                  {candidate.position}
                </Typography>
                <Typography className='flex items-center'>
                  <Language />
                  {candidate.language}
                </Typography>
              </div>
          </div>
          <div className='space-y-2 mt-5'>
              {extra && (
                <>
                    {candidate.skill?.map((item, index) => (
                            <Tag key={index} className='mb-1'>
                              {item}
                            </Tag>
                          ))}
                </>
              )}
          </div>
      </div>
    </Paper>
  );
};

export default CandidateRow;
