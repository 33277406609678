import { TYPES_EMPLOYEE, TYPES_JOBS, TYPE_MAIL_SEND } from "./consts";

export const typeJobsOptions = [
    {
        label: "online",
        value: TYPES_JOBS.ONLINE
    },
    {
        label: "fulltimed",
        value: TYPES_JOBS.FULLTIMED
    },
    {
        label: "offline",
        value: TYPES_JOBS.OFFLINE
    },
    {
        label: "parttime",
        value: TYPES_JOBS.PARTIME
    },
]

export const typeEmployeeOptions = [
    {
        label: "employee1",
        value: TYPES_EMPLOYEE.EMPLOYEE1
    },
    {
        label: "employee2",
        value: TYPES_EMPLOYEE.EMPLOYEE2
    },
    {
        label: "employee3",
        value: TYPES_EMPLOYEE.EMPLOYEE3
    },
]

export const typeSendMailOption = [
    {
        label: 'Send automatically',
        value: TYPE_MAIL_SEND.DISPATCH_NOW
    },
    {
        label: 'Queue draft',
        value: TYPE_MAIL_SEND.QUEUE_DRAFT
    },
]