import React from 'react';

type ProcessProps = {
    visible?: boolean;
    value?: number;
    maxValue: number;
    color: string;
};

const ProgressBarComponent = ({ visible, value, maxValue, color }: ProcessProps) => {

    return (
        <div className="progress-bar" style={{
            width: 300,
            height: 10,
            overflow: 'hidden'
        }}>
            <div className="progress" style={{
                height: '100%',
                backgroundColor: `${color}DD`,
                transition: 'width 0.5s ease',
                width: ((value ?? 0) / maxValue) * 300
            }}>
            </div>
        </div>
    );
};

export default ProgressBarComponent;
