import { NavigateBeforeOutlined } from '@mui/icons-material';
import { IconButton, Paper, TextField, Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useQuery } from '@tanstack/react-query';
import { Col, Form, Input, Row, Select, Tabs } from 'antd';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { privateRoute } from 'routes';
import { candidateService } from 'services';
import {CANDIDATE_LEVELS, CANDIDATE_STATUS_TYPES, SKILLS} from 'utils/constants';
import {useState} from "react";
import {InputNumber} from "../../components";

const { TabPane } = Tabs;

const TalentView = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [form] = Form.useForm();
  const { urlCv } = form.getFieldsValue();
  const [dayOfBirth, setDayOfBirth] = useState<number>();

  useQuery(['getInfoCv', id], () => candidateService.getInfoCv({ id }), {
    onSuccess: (data) => {
      const { dayOfBirth } = data;
      if (dayOfBirth) setDayOfBirth(dayOfBirth);

      form.setFieldsValue({ ...data });
    },
    enabled: !!id,
  });

  return (
    <>
      <div className='flex items-center mb-6'>
        <Link to={privateRoute.candidateList.path}>
          <IconButton>
            <NavigateBeforeOutlined />
          </IconButton>
        </Link>
        <Typography variant='h6'>{t('Talent view')}</Typography>
      </div>
        <Paper className='p-4'>
          <Form form={form} layout='vertical'>
            <Row gutter={24}>
              <Col lg={12} span={24}>
                <Row gutter={24}>
                  <Col md={12} span={24}>
                    <Form.Item
                      name='candidateName'
                      label={t('Name')}
                      rules={[{ required: true, message: t('Name is required') }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name='address' label={t('Address')}>
                      <Input />
                    </Form.Item>
                    <Form.Item name='level' label={t('Level')}>
                        <Select>
                            {CANDIDATE_LEVELS.map((item) => (
                              <Select.Option key={item.id} value={item.code}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>
                    </Form.Item>
                    <Form.Item name='email'
                               label={t('Email')}
                               rules={[{ required: true, message: t('email is required') }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name='phone'
                               label={t('Phone')}
                               rules={[{ required: true, message: t('phone is required') }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name='skill'
                               label={t('Skill')}
                               rules={[{ required: true, message: t('skill is required') }]}
                    >
                      <Select mode='tags'>
                        {SKILLS.map((skill) => (
                          <Select.Option key={skill} value={skill}>
                            {skill}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name='salary' label={t('Salary Expect')} >
                      <InputNumber
                          customInput={Input}
                          thousandSeparator
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} span={24}>
                    <Form.Item label={t('Date of Birth')}>
                      <DesktopDatePicker
                        value={dayOfBirth ? DateTime.fromMillis(dayOfBirth) : null}
                        onChange={(value) => value?.isValid && setDayOfBirth(value.toMillis())}
                        renderInput={(params) => <TextField size='small' style={{ marginTop: -3 }} {...params} />}
                        inputFormat='dd/MM/yyyy'
                      />
                    </Form.Item>
                    <Form.Item name='language' label={t('Language')}>
                      <Input />
                    </Form.Item>
                    <Form.Item name='position' label={t('Position')}>
                      <Input />
                    </Form.Item>
                    <Form.Item name='status' label={t('Status')} initialValue={CANDIDATE_STATUS_TYPES[0].code}>
                        <Select>
                            {CANDIDATE_STATUS_TYPES.map((item) => (
                                <Select.Option key={item.id} value={item.code}>
                                {item.name}
                            </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name='note' label={t('Note')}>
                      <Input.TextArea />
                    </Form.Item>
                    <Form.Item name='urlCv' hidden>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col lg={12} span={24} style={{ minHeight: 600, marginBottom: 12 }}>
                <Tabs animated type='card' className='CV-Preview'>
                  {(id ? (urlCv as string[]) ?? [] : [urlCv]).map((url, index) => (
                    <TabPane style={{ minHeight: 600 }} key={index} tab={`CV ${index + 1}`}>
                      <embed src={url} style={{ width: '100%', height: '100%' }} />
                    </TabPane>
                  ))}
                </Tabs>
              </Col>
            </Row>
          </Form>

        </Paper>
    </>
  );
};

export default TalentView;
