import { CloseOutlined } from '@mui/icons-material';
import {Avatar, IconButton, Paper, Typography} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {formatBonus} from "../../../utils/converter";
import {Button} from "antd";
import {useMutation, useQuery} from "@tanstack/react-query";
import {paymentService} from "../../../services";
import {Alert} from "../../../components";

type PaymentPopupProps = PopupController & {
  packageInfo: PackageType;
};

const PaymentPopup = ({packageInfo, onClose }: PaymentPopupProps) => {
  const { t } = useTranslation();

  const { data: paymentInfo } = useQuery(['createCredit'], () => paymentService.createCredit({ packageId: packageInfo.id}));

  // const { mutate: createCredit } = useMutation(paymentService.createCredit, {
  //   onSuccess: (data, body) => {
  //     Alert.success({ message: t('Payment is successfully') });
  //     onClose();
  //   },
  // });

  const { mutate: updateCredit } = useMutation(paymentService.updateCredit, {
    onSuccess: (data, body) => {
      Alert.success({ message: t('Create Payment successfully') });
      onClose();
    },
  });

  return (
    <div className='p-4'>
      <div className='flex items-center mb-6'>
        <span style={{
          display: 'flex',
          background: '#f6b300',
          justifyContent: 'center',
          alignItems: 'center',
          color:'#ff0',
          borderRadius: '100rem',
          border: '2px solid #e78b03',
          width: 20,
          height: 20,
          marginRight: 5
        }}>G
        </span>
        <Typography variant='h6'>{t('Thông tin chuyển khoản mua G-coin')}</Typography>

        <div className='flex-1' />
        <IconButton onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </div>

      <Paper className='flex-1 border border-gray-300 rounded-lg pl-4'>
        <Typography className='title-info-payment text-blue-900'>
          Thông tin hoá đơn mua gói:&nbsp;{packageInfo.name}
        </Typography>

        <ul>
          <li>
            Trạng thái:&nbsp;
            <span>Chờ thanh toán</span>
          </li>
          <li>
            Số tiền:&nbsp;
            <span>{formatBonus(packageInfo.price)}<sup>₫</sup></span>
          </li>
        </ul>
        <h6 style={{
          textAlign: 'left',
          marginTop: 0,
          color:'#505489'
        }}>
          Vui lòng chuyển khoản với nội dung như bên dưới hoặc&nbsp;
          <h6 style={{
            display: 'inline',
            color: 'rgb(239, 20, 110)'
          }}>
            quét mã QR&nbsp;
          </h6>
          để hoàn tất việc mua gói
        </h6>

        <div className='bank-content flex items-center mt-2 mb-2'>
          <Avatar style={{ width: 125, height: 125 }}
                  src={require('assets/images/tcb_qr.png')} />
          <div className='bank_info ml-3'>
            <div className='bank_name'>Ngân hàng Techcombank</div>
            <ul>
              <li>
                Số tài khoản:&nbsp;
                <span>8379666888</span>
              </li>
              <li>
                Tên người nhận:&nbsp;
                <span>Lê Văn Trường</span>
              </li>
              <li style={{display: 'flex', alignItems: 'center'}}>
                Nội dung chuyển khoản:&nbsp;
                <h6>{paymentInfo?.maHD}</h6>
              </li>
            </ul>
          </div>
        </div>
        <div className='note-payment-checkout'>
          <span>Lưu ý:</span>
          <ul>
            <li>
              - Vui lòng chuyển khoản&nbsp;
              <h6 style={{
                display: 'inline',
                color: 'rgb(239, 20, 110)'
              }}>ĐÚNG</h6>
              &nbsp;nội dung để tự động mở gói.
            </li>
            <li>
              - Vui lòng chọn&nbsp;
              <h6 style={{
                display: 'inline',
                color: 'rgb(239, 20, 110)'
              }}>chuyển khoản nhanh 24/7</h6>
            </li>
            <li>
              -&nbsp;
              <h6 style={{
                display: 'inline',
                color: 'rgb(239, 20, 110)'
              }}>Mã QR code&nbsp;</h6>
              hỗ trợ chuyển khoản qua ứng dụng ngân hàng.
            </li>
            <li>
              - Không hỗ trợ hoàn tiền khi đã thanh toán thành công hoặc chuyển khoản sai số tiền yêu cầu.
            </li>
            <li>
              - Nếu chuyển sai nội dung hoặc sau 5 phút không mở được gói, vui lòng liên hệ kênh chat Messenger để được hỗ trợ.
            </li>
          </ul>
        </div>
        <div className='item-footer-payment flex justify-center items-center mt-4 mb-6'>
          <Button style={{
                  width: '50%',
                  backgroundColor:'#fbc02d',
                  color: '#fff',
                  fontSize: 16}}
                  onClick={() => updateCredit({ id: paymentInfo?.id, creditStatus: 1 })}
          >
            <span>
                {t('Payment Confirm')}
            </span>
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default PaymentPopup;
