import {EditOutlined, OpenInNew, PersonAddOutlined} from '@mui/icons-material';
import {Avatar, Button, Dialog, Divider, Paper, Typography} from '@mui/material';
import {Col, Row, Tag} from 'antd';
import {decode} from 'html-entities';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {profileSelector} from 'reducers/profile';
import {privateRoute} from 'routes';
import {formatBonus, formatCurrency, toFormat} from 'utils/converter';
import {CandidatePopup} from 'views/Candidate/components';
import {Comments} from 'views/Comment';

type JobDetailProps = {
    data: JobType;
    extra?: boolean;
    readOnly?: boolean;
};

const JobDetail = ({data, extra = true, readOnly = false}: JobDetailProps) => {
    // console.log(data)
    const {t} = useTranslation();
    const {id} = useParams();
    const {userId, isSuper, isAdmin, isCompany, isRecruit} = useSelector(profileSelector);
    const isOwner = data.idUserCreate === userId;

    const [isOpenPopup, setIsOpenPopup] = useState(false);

    return (
        <div>
          <Paper className='p-4'>
            <div className='flex flex-wrap'>
              <Avatar src={data.avatar} style={{width: 120, height: 120, margin: '0px 24px 12px 0px'}}>
                <Avatar src='/gap_logo.png' style={{width: 120, height: 120}}/>
              </Avatar>

              <div className='flex'>
                <div className='flex-1'>
                  <Link to={privateRoute.jobView.url(data.id)} className='mb-3'>
                    <Typography component='span' variant='h6' color='primary'>
                      {data.title}
                    </Typography>
                  </Link>

                  <Typography>
                    {t('Company')}: <Link
                      to={privateRoute.companyView.url(data.company?.id)}>{data.company?.name}</Link>
                  </Typography>

                  <Row gutter={24} style={{ marginTop: 5 }}>
                    <Col style={{width: 240 + 24}}>
                      <Typography color='textSecondary'>
                        {t('Number of vacancies')}: <span
                          style={{color: 'black'}}>{data.numberOfVacancies}</span>
                      </Typography>
                    </Col>
                    <Col style={{width: 240 + 24}}>
                      <Typography color='textSecondary'>
                        {t('Deadline')}: <span style={{color: 'black'}}>{toFormat(data.deadline)}</span>
                      </Typography>
                    </Col>
                    <Col flex={1}>
                      <Typography color='textSecondary'>
                        {t('Created')}: <span style={{color: 'black'}}>{toFormat(data.createTime)}</span>
                      </Typography>
                    </Col>
                  </Row>

                  {data.bonus > 0 && (
                      <Typography variant='h6' color='textSecondary'>
                        {t('Bonus')}:{' '}
                        <span
                            style={{color: data.bonus > 0 ? 'green' : 'silver'}}>{formatBonus(data.bonus)}</span>
                      </Typography>
                  )}

                  <Row gutter={24} style={{ marginTop: 5 }}>
                    <Col style={{width: 240 + 24}}>
                      <Typography color='textSecondary'>{t('Salary range')}:</Typography>
                      <Typography>
                        {formatCurrency(data.currency, data.fromSalary)} - {formatCurrency(data.currency, data.toSalary)}
                      </Typography>
                    </Col>
                    <Col style={{width: 240 + 24}}>
                      <Typography color='textSecondary'>{t('Workplace')}:</Typography>
                      <Typography>{data.workplace}</Typography>
                    </Col>
                    <Col flex={1}>
                      <Typography color='textSecondary'>
                        {t('Total Referral')}: <span style={{color: 'black'}}>{data.totalRefer??0}</span>
                      </Typography>
                    </Col>
                  </Row>
                  {data.contractDuration && (
                    <Row gutter={24} style={{ marginTop: 5 }}>
                          <Col style={{width: 240 + 24}}>
                            <Typography color='textSecondary'>
                             {t('Contract')}: <span style={{color: 'black'}}>{t(data.contractDuration)}</span>
                            </Typography>
                          </Col>
                    </Row>
                  )}
                </div>
              </div>
            </div>

            {extra && (
                <>
                  <Divider/>
                  <Typography variant='h6' color='primary'>
                    {t('Skill')}:
                  </Typography>
                  <Typography>
                  {data.language && (
                      <Tag className='mb-1'>{data.language}</Tag>
                  )}
                  {data.skill?.map((item, index) => (
                                                <Tag key={index} className='mb-1'>
                                                  {item}
                                                </Tag>
                                              ))}
                  </Typography>
                  <Divider/>
                  <Typography variant='h6' color='primary'>
                    {t('Description')}:
                  </Typography>
                  <Typography paragraph dangerouslySetInnerHTML={{__html: decode(data.description)}}/>

                  <Divider/>
                  <Typography variant='h6' color='primary'>
                    {t('Requirement')}:
                  </Typography>
                  <Typography paragraph dangerouslySetInnerHTML={{__html: decode(data.requirement)}}/>

                  <Divider/>
                  <Typography variant='h6' color='primary'>
                    {t('Welfare')}:
                  </Typography>
                  <Typography paragraph dangerouslySetInnerHTML={{__html: decode(data.welfare)}}/>

                  <div className='flex gap-4'>
                    <Button
                        disabled={!data.jobDescription}
                        onClick={() => window.open(data.jobDescription)}
                        startIcon={<OpenInNew/>}
                    >
                      {t('View JD')}
                    </Button>

                    {(isSuper || isAdmin || isCompany || isRecruit) && (
                        <Button color='secondary' startIcon={<PersonAddOutlined/>}
                                onClick={() => setIsOpenPopup(true)}>
                          {t('Refer candidates')}
                        </Button>
                    )}

                    {(isSuper || isAdmin || isOwner) && (
                        <Link to={privateRoute.jobUpdate.url(id)}>
                          <Button color='success' startIcon={<EditOutlined/>}>
                            {t('Edit job')}
                          </Button>
                        </Link>
                    )}

                    <Dialog fullWidth maxWidth='xl' open={isOpenPopup} onClose={() => setIsOpenPopup(false)}>
                      <CandidatePopup job={data} onClose={() => setIsOpenPopup(false)}/>
                    </Dialog>
                  </div>
                </>
            )}
          </Paper>
          <br/>
          <Paper className='p-4'>
            <div className="Comment-container">
              <Comments data={data.id}/>
            </div>
          </Paper>
        </div>
    );
};

export default JobDetail;
