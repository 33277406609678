import { Close, FilterListOutlined, SearchOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Col, Row } from 'antd';
import { InputNumber } from 'components';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import {
  CANDIDATE_LEVELS,
  CREATE_LAST_TYPES,
  CURRENCY_TYPES,
  JOB_FORMS,
  JOB_STATUS_TYPES,
  SKILLS,
  WORKPLACE_TYPES,
} from 'utils/constants';
import { CompanyTextField } from 'views/Company/components';

const JobSearch = ({ onSearch }: SearchController) => {
  const { t } = useTranslation();
  const { isAdmin, isSuper, isCompany } = useSelector(profileSelector);

  const [keyword, setKeyword] = useState('');

  const [workplace, setWorkplace] = useState('');
  const [company, setCompany] = useState('');
  const [form, setForm] = useState('');
  const [salary, setSalary] = useState(0);
  const [currency, setCurrency] = useState(CURRENCY_TYPES[0].code);

  const [skill, setSkill] = useState('');
  const [level, setLevel] = useState('');
  const [status, setStatus] = useState('');
  const [createLast, setCreateLast] = useState('');

  const handleClickSearch = () => {
    const createTime = DateTime.now().minus({ day: +createLast });
    onSearch({
      keyword,
      workplace,
      company,
      form,
      salary,
      currency: salary === 0 ? '' : currency,
      skill,
      level,
      status: status === '' ? null : status,
      createTime: createLast === '' ? 0 : createTime.startOf('day').toSeconds(),
    });
  };

  const handleClickReset = () => {
    setKeyword('');
    setWorkplace('');
    setCompany('');
    setForm('');
    setSalary(0);
    setSkill('');
    setLevel('');
    setStatus('');
    setCreateLast('');
  };

  return (
    <>
      <div className='flex gap-3 mb-6'>
        <TextField
          fullWidth
          label={t('Find your job by keyword')}
          value={keyword}
          onChange={(event) => setKeyword(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
        <Button className='md:w-[420px]' onClick={handleClickSearch}>
          {t('Search')}
        </Button>
      </div>

      <Paper className='flex flex-col p-4 mb-6'>
        <div className='flex gap-2 mb-4'>
          <FilterListOutlined />
          <Typography>{t('Filter by')}</Typography>

          <div className='ml-auto'>
            <Button variant='outlined' color='inherit' size='small' startIcon={<Close />} onClick={handleClickReset}>
              {t('Reset')}
            </Button>
          </div>
        </div>

        <Row gutter={[24, 24]} style={{ flex: 1 }}>
          <Col xl={6} md={12} span={24}>
            <TextField
              fullWidth
              select
              label={t('Workplace')}
              value={workplace}
              onChange={(event) => setWorkplace(event.target.value)}
              InputProps={{
                endAdornment: workplace && (
                  <InputAdornment position='end'>
                    <IconButton className='p-1 mr-4' onClick={() => setWorkplace('')}>
                      <Close fontSize='small' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              {WORKPLACE_TYPES.map((item) => (
                <MenuItem key={item.id} value={item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col xl={6} md={12} span={24}>
            <CompanyTextField initValue={company} onChange={(value) => setCompany(value)} />
          </Col>
          <Col xl={6} md={12} span={24}>
            <TextField
              fullWidth
              select
              label={t('Type')}
              value={form}
              onChange={(event) => setForm(event.target.value)}
              InputProps={{
                endAdornment: form && (
                  <InputAdornment position='end'>
                    <IconButton className='p-1 mr-4' onClick={() => setForm('')}>
                      <Close fontSize='small' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              {JOB_FORMS.map((item) => (
                <MenuItem key={item.id} value={item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col xl={6} md={12} span={24}>
            <TextField
              fullWidth
              label={t('Salary')}
              value={salary}
              onChange={(event) => setSalary(+event.target.value)}
              InputProps={{
                inputComponent: InputNumber as any,
                inputProps: {thousandSeparator:true},
                startAdornment: <InputAdornment position='start'>≥</InputAdornment>,
                endAdornment: (
                  <InputAdornment position='end'>
                    <TextField
                      select
                      variant='standard'
                      value={currency}
                      onChange={(event) => setCurrency(event.target.value)}
                    >
                      {CURRENCY_TYPES.map((item) => (
                        <MenuItem key={item.id} value={item.code}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </InputAdornment>
                ),
              }}
            />
          </Col>

          <Col xl={6} md={12} span={24}>
            <TextField
              fullWidth
              select
              label={t('Level')}
              value={level}
              onChange={(event) => setLevel(event.target.value)}
              InputProps={{
                endAdornment: level && (
                  <InputAdornment position='end'>
                    <IconButton className='p-1 mr-4' onClick={() => setLevel('')}>
                      <Close fontSize='small' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              {CANDIDATE_LEVELS.map((item) => (
                <MenuItem key={item.id} value={item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col xl={6} md={12} span={24}>
            <Autocomplete
              freeSolo
              value={skill}
              onChange={(event, value) => setSkill(value ?? '')}
              options={SKILLS}
              renderInput={(params) => <TextField {...params} label={t('Skill')} InputLabelProps={{ shrink: true }} />}
            />
          </Col>
          <Col xl={6} md={12} span={24}>
            <TextField
              fullWidth
              select
              label={t('Status')}
              value={status}
              onChange={(event) => setStatus(event.target.value)}
              InputProps={{
                endAdornment: status && (
                  <InputAdornment position='end'>
                    <IconButton className='p-1 mr-4' onClick={() => setStatus('')}>
                      <Close fontSize='small' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              {JOB_STATUS_TYPES.filter((item) => !item.requireAdmin || isAdmin || isSuper || isCompany).map((item) => (
                <MenuItem key={item.id} value={item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col xl={6} md={12} span={24}>
            <TextField
              fullWidth
              select
              label={t('Created')}
              value={createLast}
              onChange={(event) => setCreateLast(event.target.value)}
              InputProps={{
                endAdornment: createLast && (
                  <InputAdornment position='end'>
                    <IconButton className='p-1 mr-4' onClick={() => setCreateLast('')}>
                      <Close fontSize='small' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              {CREATE_LAST_TYPES.map((item) => (
                <MenuItem key={item.id} value={item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>
      </Paper>
    </>
  );
};

export default JobSearch;
