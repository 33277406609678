import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { Alert } from 'components';
import { APP_API, FILE_API, SOCKET_URL } from 'env';
import { camelizeKeys } from 'humps';
import { store } from 'reducers';
import { ProfileState, signOut } from 'reducers/profile';

const beforeRequest = (config: AxiosRequestConfig) => {
  const { isLoggedIn, accessToken }: ProfileState = store.getState().profile;
  if (isLoggedIn) {
    Object.assign(config.headers as any, { Authorization: `Bearer ${accessToken}` });
  }
  if (config.data instanceof FormData) {
    Object.assign(config.headers as any, { 'Content-Type': 'multipart/form-data' });
  }
  return config;
};

const onError = async (error: AxiosError) => {
  const { response } = error;
  if (response) {
    // console.log(response)
    const { status, data } = response as any;
    if (status === 401 || status === 400 || data.errorCode === 400 || data.errorCode === 401) {
      store.dispatch(signOut());
      window.location.href = '/gap';
    } else {
      Alert.error({ message: data.message });
    }
  } else {
    Alert.error({ message: `Cannot connect to Server` });
  }
  return Promise.reject(response);
};

const client = axios.create({ baseURL: APP_API });
const clientDetail = axios.create({ baseURL: APP_API });
const clientLogin = axios.create({ baseURL: APP_API });
const clientFile = axios.create({ baseURL: FILE_API });
const clientSocket = axios.create({ baseURL: SOCKET_URL });

[clientLogin].forEach((client) => {
  client.interceptors.response.use(({ data, headers }) => ({...data.data, headers}), onError);
});

[client, clientFile].forEach((client) => {
  client.interceptors.request.use(beforeRequest);
  client.interceptors.response.use(({ data }) => data, onError);
});

[clientDetail].forEach((client) => {
  client.interceptors.request.use(beforeRequest);
  client.interceptors.response.use(({ data }) => data.data, onError);
});

[clientSocket].forEach((client) => {
  client.interceptors.request.use(beforeRequest);
  client.interceptors.response.use(({ data }) => data, onError);
});

client.defaults.transformResponse = [...(axios.defaults.transformResponse as []), (data) => camelizeKeys(data)];

export { client, clientFile, clientSocket, clientLogin, clientDetail };
