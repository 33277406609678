import { t } from 'components';

export const WORKPLACE_TYPES = [
  { id: 1, code: 'Hà Nội', name: t('Hà Nội') },
  { id: 2, code: 'Đà Nẵng', name: t('Đà Nẵng') },
  { id: 3, code: 'Hồ Chí Minh', name: t('Hồ Chí Minh') },
  { id: 4, code: 'Tokyo', name: t('Tokyo') },
  { id: 5, code: 'Yokohama', name: t('Yokohama') },
  { id: 6, code: 'Osaka', name: t('Osaka') },
  { id: 7, code: 'Fukuoka', name: t('Fukuoka') },
  { id: 8, code: 'Remote', name: t('Remote') },
];

export const LANGUAGES = [
  { id: 1, code: 'Vietnamese', name: t('Vietnamese') },
  { id: 2, code: 'Japanese N1', name: t('Japanese N1') },
  { id: 3, code: 'Japanese N2', name: t('Japanese N2') },
  { id: 4, code: 'Japanese N3', name: t('Japanese N3') },
  { id: 5, code: 'English verbal', name: t('English verbal') },
  { id: 6, code: 'English write', name: t('English write') },
];

export const CREATE_LAST_TYPES = [
  { id: 1, code: 7, name: t('1 Week') },
  { id: 2, code: 30, name: t('1 Month') },
  { id: 3, code: 90, name: t('3 Months') },
];

export const CURRENCY_TYPES = [
  { id: 1, code: 'VND', name: 'VND' },
  { id: 2, code: 'USD', name: 'USD' },
  { id: 3, code: 'JPY', name: 'JPY' },
];

export const JOB_FORMS = [
  { id: 1, code: 'Full Time', name: t('Full Time') },
  { id: 2, code: 'Part Time', name: t('Part Time') },
  { id: 3, code: 'Freelance', name: t('Freelance') },
  { id: 4, code: 'Remote', name: t('Remote') },
  { id: 5, code: 'Onsite', name: t('Onsite') },
  { id: 6, code: 'Hybrid', name: t('Hybrid') },
];

export const CONTRACT_DURATION = [
  { id: 1, code: 'Official', name: t('Official') },
  { id: 2, code: '3 Months', name: t('3 Months') },
  { id: 3, code: '6 Months', name: t('6 Months') },
  { id: 4, code: '1 Year', name: t('1 Year') },
];

export const JOB_COUNTRIES = [
  { id: 1, code: 'vietnam', name: 'Việt Nam' },
  { id: 2, code: 'japan', name: 'Japan' },
];

export const COMPANY_SIZE = [
  { id: 1, code: '1-50', name: '1-50' },
  { id: 2, code: '50-100', name: '50-100' },
  { id: 3, code: '100-200', name: '100-200' },
  { id: 4, code: '>500', name: '>500' },
];

export const JOB_TYPIES = [
  { id: 1, code: 'vietnam', name: 'Việt Nam' },
  { id: 2, code: 'japan', name: 'Japan' },
  { id: 3, code: 'followed', name: 'Quan tâm' },
];

export const CANDIDATE_TYPIES = [
  { id: 1, code: 'my_candidates', name: 'My Candidates' },
];

export const USER_RANKS = [
  { id: 1, code: 'user', name: 'User' },
  { id: 2, code: 'bxh', name: 'Ranking' },
];

export const CANDIDATE_LEVELS = [
  { id: 1, code: 'Fresher', name: t('Fresher (Dưới 1 năm kinh nghiệm)') },
  { id: 2, code: 'Junior', name: t('Junior (Từ 1 đến 2 năm kinh nghiệm)') },
  { id: 3, code: 'Middle', name: t('Middle (Từ 2 đến 5 năm kinh nghiệm)') },
  { id: 4, code: 'Senior', name: t('Senior (Trên 5 năm kinh nghiệm)') },
  { id: 5, code: 'All level', name: t('All level') },
];

export const JOB_STATUS_TYPES = [
  { id: 1, code: 2, name: t('Open'), color: 'green' },
  { id: 2, code: 1, name: t('Processing'), color: 'blue', requireAdmin: 1 },
  { id: 3, code: 3, name: t('Urgent'), color: 'purple' },
  { id: 4, code: 4, name: t('Highlight'), color: 'orange' },
  { id: 5, code: -1, name: t('Close') },
];

export const PACKAGE_STATUS_TYPES = [
  { id: 1, code: -3, name: t('Package expire'), color: 'red' },
  { id: 2, code: -2, name: t('Package close'), color: 'grey' },
  { id: 3, code: 0, name: t('Waiting payment'), color: 'orange' },
  { id: 4, code: 1, name: t('Done payment'), color: 'orange' },
  { id: 5, code: 2, name: t('Active'), color: 'green' },
  { id: 6, code: 3, name: t('Package renewal'), color: 'purple' },
];

export const CANDIDATE_STATUS_TYPES = [
  { id: 1, code: 1, name: t('Open'), color: 'green' },
  { id: 2, code: -1, name: t('Close') },
];
export const REPORT_STATUS_TYPES = [
  { id: 1, code: 0, name: t('Wait Approve'), color: '#7A7A74' },
  { id: 2, code: 1, name: t('CV Passed'), color: '#2196f3' },
  { id: 3, code: 2, name: t('Interview Passed'), color: '#fbc02d' },
  { id: 4, code: 3, name: t('Probationing'), color: '#4D8E5F' },
  { id: 5, code: 6, name: t('Finished'), color: '#33691e' },
  { id: 6, code: -5, name: t('Reject'), color: '#d32f2f' },
];

export const REFERRAL_STATUS_TYPES = [
  { id: 1, code: 0, name: t('Wait Approve'), color: '#7A7A74' },
  { id: 2, code: 1, name: t('CV Passed'), color: '#2196f3' },
  { id: 3, code: -1, name: t('CV Rejected'), color: '#d32f2f' },
  { id: 4, code: 2, name: t('Interview Passed'), color: '#fbc02d' },
  { id: 5, code: -2, name: t('Interview Failed'), color: '#d32f2f' },
  { id: 6, code: 3, name: t('Probationing'), color: '#4D8E5F' },
  { id: 7, code: -3, name: t('Offer Rejected'), color: '#d32f2f' },
  { id: 8, code: -4, name: t('Probation Rejected'), color: '#d32f2f' },
  { id: 9, code: 6, name: t('Finished'), color: '#33691e' },
  { id: 10, code: -5, name: t('Reject'), color: '#d32f2f' },
];

export const USER_ROLES = [
  { id: 1, code: 1000, name: t('Super Admin') },
  { id: 2, code: 1005, name: t('Admin') },
  { id: 3, code: 1010, name: t('User') },
];

export const USER_TYPES = [
  { id: 6, code: 6, name: t('Admin') },
  { id: 1, code: 0, name: t('Recruiter') },
  { id: 2, code: 1, name: t('Company') },
  { id: 3, code: 2, name: t('Candidate') },
];

export const DDMMYYYY = 'DD/MM/YYYY';
export const DDMMYYYY_HHMM = 'DD/MM/YYYY HH:mm';

export { default as SKILLS } from './skills.json';

export const BANK_INFO = [
  { id: 1, code: 'vpbank', name: 'VPBank' },
  { id: 2, code: 'techcombank', name: 'Techcombank - NH Ky Thuong' },
  { id: 3, code: 'vietcombank', name: 'Vietcombank - NH Ngoai Thuong' },
  { id: 4, code: 'viettinbank', name: 'Viettinbank - NH Cong Thuong Viet Nam' },
  { id: 5, code: 'bidv', name: 'BIDV - NH Dau Tu Va Phat Trien Viet Nam' },
  { id: 6, code: 'agribank', name: 'Agribank - NH Nong Nghiep va PTNT VN' },
  { id: 7, code: 'mbbank', name: 'Mbbank - NH Quan Doi' },
  { id: 8, code: 'acb', name: 'ACB - NH A Chau' },
  { id: 9, code: 'hdbank', name: 'NH Phat Trien TP HCM' },
  { id: 10, code: 'tpbank', name: 'TPBANK - NH Tien Phong' },
  { id: 11, code: 'sacombank', name: 'SACOMBANK - NH Sai Gon Thuong Tin' },
];
