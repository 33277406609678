import { LoadingButton } from '@mui/lab';
import { Paper, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Alert } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { signIn } from 'reducers/profile';
import { userService } from 'services';

const LoginForm = ({ onClose }: PopupController) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm();

  const { mutate: login, isLoading } = useMutation(userService.login, {
    onSuccess: (data) => {
      Alert.success({ message: t('Welcome') });
        dispatch(signIn({ ...data, accessToken: data.headers.authorization?.substr(7) }));
    },
    onError: (error:any) => {
        Alert.error({message: error.data.message});
    },
  });

  const handleClickLogin = () => {
    handleSubmit(({ username, password }) => {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);
      login(formData);
    })();
  };

  return (
    <Paper className='flex flex-col gap-8 p-10'>
      <div className='flex flex-col gap-2 text-center'>
        <Typography variant='h5'>{t('Sign in')}</Typography>
        <Typography color='textSecondary'>{t('Enter your account')}</Typography>
      </div>

      <Controller
        name='username'
        defaultValue=''
        control={control}
        rules={{ required: t`Username is required` }}
        render={({ field, fieldState: { error } }) => (
          <TextField {...field} label={t('Username')} error={!!error} helperText={error?.message} />
        )}
      />

      <Controller
        name='password'
        defaultValue=''
        control={control}
        rules={{ required: t`Password is required` }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            type='password'
            label={t('Password')}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleClickLogin();
              }
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      />

      <LoadingButton variant='contained' size='large' loading={isLoading} onClick={handleClickLogin}>
        {t('Sign in')}
      </LoadingButton>

      {/* <Typography>
          {t("Don't have account?")} <Link to={authRoute.signUp.url}>{t('Sign up')}</Link>
        </Typography> */}
    </Paper>
  );
};

export default LoginForm;
