import {IconButton, Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import {paymentService} from "../../../services";
import {useSelector} from "react-redux";
import {profileSelector} from "../../../reducers/profile";

const CurrentCoin = () => {
    const { t } = useTranslation();
    const { isLoggedIn } = useSelector(profileSelector);

    const { data: credit } = useQuery(['getMarketPlaceInfo'],
        () => paymentService.getMarketPlaceInfo(),
        {
            enabled: isLoggedIn ?? false,
        }
    );

    return (
        <>
            <Tooltip title={t('Current coin')}>
                <IconButton>
                    <span style={{fontSize: 15}}>{credit?.remainingCoins??0}</span>
                    <span style={{
                            display: 'flex',
                            background: '#f6b300',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color:'#ff0',
                            borderRadius: '100rem',
                            border: '2px solid #e78b03',
                            width: 20,
                            height: 20,
                            fontSize: 10
                        }}>G
                    </span>
                </IconButton>
            </Tooltip>
        </>
    )
};

export default CurrentCoin;