import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { companyService } from 'services';

type CompanySelectProps = {
  initCompany?: CompanyType;
  initValue?: number;
  onChange: (value: any, option: any) => void;
};

const CompanySelect = ({ initCompany, initValue, onChange }: CompanySelectProps) => {
  const [keyword, setKeyword] = useState<any>(initValue);

  const { data: { data = [] } = {} } = useQuery(['getNameCompanies', keyword], () =>
    companyService.getNameCompanies({ keyword }),
  );

  const companyList = initCompany
    ? [initCompany, ...data].filter(
        (item, index, array) => index === array.findIndex((next) => next.id === item.id),
      )
    : [...data];

  useEffect(() => {
    setKeyword(initValue);
  }, [initValue]);

  return (
    <Select
      showSearch
      value={keyword}
      filterOption={false}
      onSearch={setKeyword}
      onChange={onChange}
      notFoundContent={'Type to search...'}
    >
      {companyList.map((item) => (
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CompanySelect;
