import { ICreateBreakNewsRequest, ICreateBreakNewsResponse, IListBreakNewsRequest, IListBreakNewsResponse, IUpdateBreakNewsRequest, IUpdateBreakNewsResponse } from "models/Ads";
import { client } from "./axios";
const api = '/api/v1/ads/breakingnews'
const createBreakNews = (body: ICreateBreakNewsRequest): Promise<ICreateBreakNewsResponse> =>
  client.post(api+ '/create', body);
const getListBreakNews = (body:IListBreakNewsRequest): Promise<IListBreakNewsResponse> =>
  client.get(`${api}/list?pageNumber=${String(body.pageNumber)}&pageSize=${String(body.pageSize)}`);
const updateBreakNews = (body: IUpdateBreakNewsRequest): Promise<IUpdateBreakNewsResponse> =>
  client.put(`${api}/update?id_breaking_new=${body.id_breaking_new}&is_active=${body.is_active}`);
const deleteBreakNews = (id: string): Promise<any> =>
  client.delete(api + '/delete/' + id,);
const getListBreakNewsPublic = (): Promise<IListBreakNewsResponse> =>
  client.get(`${api}/public`);
export default {
    createBreakNews,
    getListBreakNews,
    deleteBreakNews,
    updateBreakNews,
    getListBreakNewsPublic
}