
import {
    Button,
    Dialog,
    IconButton,
    Pagination,
    Paper,
    Typography
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { packageService, paymentService, queryClient } from 'services';
import { formatBonus, toFormat, toFormatDateTime } from "../../utils/converter";
import { useState } from "react";
import PaymentPopup from "./components/PaymentPopup";
import { ListAlt } from "@mui/icons-material";
import { Select, Table, Tag } from "antd";
import { useSearch } from "../../hooks";
import { PACKAGE_STATUS_TYPES } from "../../utils/constants";
import { useSelector } from "react-redux";
import { profileSelector } from "../../reducers/profile";
import { Alert } from "../../components";

const PackageView = () => {
    const { t } = useTranslation();

    const [dataSearch, onSearchChange] = useSearch();
    const { isSuper, isAdmin } = useSelector(profileSelector);

    const {
        data: { data: items = [], total = 0 } = {},
        isFetching,
    } = useQuery(['getListInfoCredit', dataSearch], () => paymentService.getListInfoCredit(dataSearch), {
        keepPreviousData: true,
    });

    const { data: { data: packages } = {} } =
        useQuery(['getListPackages'], () =>
            packageService.getListPackages());

    const [isOpenPopup, setIsOpenPopup] = useState(false);

    const [packageSelected, setPackageSelected] = useState(null);

    const handleOpenDialog = (object: any) => {
        setPackageSelected(object);
        setIsOpenPopup(true);
    };

    const TablePagination = () => (
        <Pagination
            count={Math.ceil(total / 10)}
            page={dataSearch.page + 1}
            onChange={(event, nextPage) => {
                onSearchChange({ page: nextPage - 1 });
            }}
        />
    );

    const { mutate: updateCredit } = useMutation(paymentService.updateCredit, {
        onSuccess: (data, body) => {
            Alert.success({ message: t('Update Payment successfully') });

            const { id, creditStatus }: { id: string; creditStatus: number } = body;
            queryClient.setQueryData(['getListInfoCredit', dataSearch], {
                data: items.map((item) => {
                    if (item.id === id) {
                        item.creditStatus = creditStatus;
                    }
                    return item;
                }),
                total,
            });
        },
    });

    return (
        <div>
            <div className='flex flex-wrap justify-center mx-auto'
                style={{
                    paddingTop: 40,
                    paddingBottom: 100,
                    maxWidth: 1200
                }}>
                <div className='flex justify-between gap-6 mt-10'>
                    <Typography variant='h4'>{t('Select your perfect plan to promote your ad')}</Typography>
                </div>

                <div className='flex flex-wrap justify-between gap-6 mt-10'>
                    {packages && (
                        packages.map((packageInfo) => {
                            return <Paper className='flex-1 leading-8 border border-gray-300 rounded-lg'>
                                <div className='flex-1'
                                    style={{
                                        color: '#fbc02d',
                                        fontSize: 34,
                                        textAlign: 'center',
                                        fontWeight: 600,
                                        margin: 30
                                    }}
                                >
                                    {packageInfo.name}
                                </div>

                                <div style={{
                                    height: 1,
                                    backgroundColor: '#fbc02d',
                                    marginLeft: 10,
                                    marginRight: 10
                                }}>
                                </div>

                                <div className='flex-1'
                                    style={{
                                        whiteSpace: 'pre-wrap',
                                        fontSize: 18,
                                        marginLeft: 10,
                                        marginTop: 20,
                                        marginBottom: 20
                                    }}
                                >
                                    {
                                        packageInfo && packageInfo.description.map((item:any) =>item && <div key={item}>{`- ${item}`}</div>)
                                    }
                                </div>

                                <div style={{
                                    height: 1,
                                    backgroundColor: '#fbc02d',
                                    marginLeft: 10,
                                    marginRight: 10
                                }}>
                                </div>

                                <div className='flex-1'
                                    style={{
                                        color: '#fbc02d',
                                        fontSize: 34,
                                        textAlign: 'center',
                                        fontWeight: 600,
                                        margin: 30
                                    }}
                                >
                                    {formatBonus(packageInfo.price)}<sup>₫</sup>
                                </div>
                                <div className='flex-1'
                                    style={{
                                        textAlign: 'center'
                                    }}>
                                    <Button style={{
                                        width: '100%',
                                        color: '#fff',
                                        fontSize: 16
                                    }}
                                        onClick={() => handleOpenDialog(packageInfo)}
                                    >
                                        <span>
                                            {t('Buy now')}
                                        </span>
                                    </Button>

                                </div>
                            </Paper>
                        })
                    )}
                    <Dialog open={isOpenPopup} onClose={() => setIsOpenPopup(false)}>
                        {packageSelected && (
                            <PaymentPopup packageInfo={packageSelected} onClose={() => setIsOpenPopup(false)} />
                        )}
                    </Dialog>
                </div>
            </div>

            <div className='flex items-center'>
                <IconButton>
                    <ListAlt />
                </IconButton>
                <Typography variant='h6'>{t('Package list')}</Typography>
            </div>

            <Paper className='mb-6'>
                <Table
                    scroll={{ x: 500 }}
                    bordered={false}
                    loading={isFetching}
                    rowKey={(record) => record.id}
                    dataSource={items}
                    pagination={false}
                    columns={[
                        {
                            title: 'Username',
                            dataIndex: 'username',
                            width: 100,
                            render: (_, record) => (
                                <div className='flex items-center gap-3'>
                                    <Typography>
                                        {record.userCreate.username}
                                    </Typography>
                                </div>
                            ),
                        },
                        {
                            title: 'Package Name',
                            dataIndex: 'packageName',
                            width: 100,
                            render: (_, record) => (
                                <div className='flex items-center gap-3'>
                                    <Typography>
                                        {record.packageInfo.name}
                                    </Typography>
                                </div>
                            ),
                        },
                        {
                            title: 'Package Status',
                            dataIndex: 'packageStatus',
                            width: 100,
                            sorter: true,
                            render: (_, record) => {
                                const status = PACKAGE_STATUS_TYPES.find((item) => item.code === record.creditStatus);
                                return isSuper || isAdmin ? (
                                    <Select
                                        value={record.creditStatus}
                                        onChange={(value) => {
                                            updateCredit({ id: record.id, creditStatus: value });
                                        }}
                                        style={{ color: status?.color }}
                                    >
                                        {PACKAGE_STATUS_TYPES.map((item) => (
                                            <Select.Option key={item.id} value={item.code} style={{ color: item.color }}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <Tag color={status?.color}>{status?.name}</Tag>
                                );
                            },
                        },
                        {
                            title: 'Remaining coins',
                            dataIndex: 'remainingCoins',
                            width: 100,
                            sorter: true,
                            render: (_, record) => record.remainingCoins,
                        },
                        {
                            title: 'Created',
                            dataIndex: 'createTime',
                            width: 120,
                            sorter: true,
                            render: (_, record) => toFormatDateTime(record.createTime),
                        },
                        {
                            title: 'Expired',
                            dataIndex: 'expiredTime',
                            width: 100,
                            sorter: true,
                            render: (_, record) => toFormat(record.expiredTime),
                        }
                    ]}
                />
            </Paper>
            {total > 0 && (
                <div className='flex justify-center'>
                    <TablePagination />
                </div>
            )}
        </div>


    );
};

export default PackageView;
