import {
  BadgeOutlined,
  BiotechOutlined,
  Check,
  ErrorOutline,
  LocalOfferOutlined,
  WorkHistoryOutlined,
} from '@mui/icons-material';
import { Step, StepLabel, Stepper, Tab, Tabs } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { candidateService, jobService, referService } from 'services';
import { REFERRAL_STATUS_TYPES } from 'utils/constants';
import { CandidateRow } from 'views/Candidate/components';
import { CompanyPaper } from 'views/Company/components';
import { JobDetail } from 'views/Job/components';

const steps = [
  { label: 'Approve', issues: [0], icon: <ErrorOutline /> },
  { label: 'Screen CV', issues: [1, -1], icon: <BadgeOutlined /> },
  { label: 'Interview', issues: [2, 3, -3], icon: <BiotechOutlined /> },
  { label: 'Offer', issues: [4, -4], icon: <LocalOfferOutlined /> },
  { label: 'Probation', issues: [5, -5], icon: <WorkHistoryOutlined /> },
];

const ReferView = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data: refer } = useQuery(
    ['getDetailRefer', id],
    () => referService.getDetailRefer({ id: +id! }),
    {},
  );

  const { data: candidate } = useQuery(
    ['getInfoCv', refer?.idCv],
    () => candidateService.getInfoCv({ id: refer?.idCv }),
    { enabled: !!refer?.idCv },
  );

  const { data: job = null } = useQuery(
    ['getInfoJob', refer?.idJob],
    () => jobService.getInfoJob({ idJob: refer?.idJob! }),
    { enabled: !!refer?.idJob },
  );

  const activeIndex = steps.findIndex((step) => step.issues.includes(refer?.status!));

  return (
    <div>
      <Stepper activeStep={activeIndex} alternativeLabel>
        {steps.map((step, index) => {
          const status = REFERRAL_STATUS_TYPES.find((item) => item.code === refer?.status);
          return (
            <Step key={index}>
              <StepLabel
                classes={{
                  iconContainer: activeIndex >= index ? 'text-secondary-main' : 'text-black/50',
                  labelContainer: activeIndex >= index ? 'font-bold text-lg text-center' : '',
                  label: 'uppercase',
                }}
                icon={step.icon}
                optional={
                  index === activeIndex ? (
                    <span style={{ color: status?.color }}>{status?.name}</span>
                  ) : index < activeIndex ? (
                    <Check className='text-green-700' />
                  ) : (
                    <></>
                  )
                }
              >
                {step.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <Row gutter={24}>
        <Col xl={12} span={24}>
          <Tabs value={0} centered className='mt-4'>
            <Tab label={t('Job')} />
          </Tabs>
          {job ? <JobDetail data={job!} extra={false} readOnly /> : <></>}
        </Col>
        <Col xl={12} span={24}>
           <Tabs value={0} centered className='mt-4'>
              <Tab label={t('Candidate')} />
           </Tabs>
           {candidate ? <CandidateRow candidate={candidate} extra /> : <></>}
        </Col>

{/*         <Col xl={12} span={24}> */}
{/*           <Tabs value={0} centered> */}
{/*             <Tab label={t('Company')} /> */}
{/*           </Tabs> */}
{/*           {refer ? <CompanyPaper id={refer?.comp.id!} readOnly /> : <></>} */}
{/*         </Col> */}
      </Row>
    </div>
  );
};

export default ReferView;
