import {client, clientDetail, clientLogin} from './axios';

const api = '/api/v1/user';

const login = (body: FormData): Promise<any> => clientLogin.post(`${api}/login`, body);
const signUp = (body: FormData): Promise<any> => client.post(`${api}/signup`, body);

const getListUser = (body: any): Promise<GetListUserType> =>
  client.post(api, { constructor: 'getListUser', params_request: body, }, { headers: {
      'Content-Type': 'application/json'
    }});
const getListFriend = (body: any): Promise<GetListUserType> =>
  client.post(api, { constructor: 'getMyFriends', search: body }, { headers: {
      'Content-Type': 'application/json'
    }});
const getUserInfo = (body: any): Promise<UserType> =>
    clientDetail.post(api, { constructor: 'getUserInfo', params_request: body }, { headers: {
      'Content-Type': 'application/json'
    }});

const updateUserInfo = (body: any): Promise<any> =>
  client.post(api, { constructor: 'updateUserInfo', params_request: body }, { headers: {
      'Content-Type': 'application/json'
    }});
const changePassword = (body: any): Promise<any> =>
  client.post(api, { constructor: 'changePassword', params_request: body }, { headers: {
      'Content-Type': 'application/json'
    }});
const updateRole = (body: any): Promise<any> => client.post(api, { constructor: 'updateRole', params_request: body }, { headers: {
    'Content-Type': 'application/json'
  }});
const deleteUser = (body: any): Promise<any> => client.post(api, { constructor: 'deleteUser', params_request: body }, { headers: {
    'Content-Type': 'application/json'
  }});

export default {
  login,
  signUp,

  getListUser,
  getListFriend,
  getUserInfo,

  updateUserInfo,
  changePassword,
  updateRole,
  deleteUser,
};
