import {
    WorkOutlineOutlined,
} from '@mui/icons-material';
import {
    Avatar, Button, Hidden,
    IconButton,
    Pagination,
    Paper,
    Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import {Col, Row, Spin, Tag} from 'antd';
import { Alert } from 'components';
import {useSearch} from 'hooks';
import { useTranslation } from 'react-i18next';
import { jobService, queryClient, referService} from 'services';
import {JOB_STATUS_TYPES} from 'utils/constants';
import {formatBonus, formatCurrency, formatListToString, toFormat} from 'utils/converter';
import {Link} from "react-router-dom";
import {privateRoute} from "../../routes";


type SystemSuggestionJobProps = {
  cv: CandidateType;
};
const SystemSuggestionJob = ({ cv } : SystemSuggestionJobProps) => {
  const { t } = useTranslation();

  const [dataSearch, onSearchChange] = useSearch({ idCv: cv.id });

  const { data: { data: items = [], total = 0 } = {}, isFetching } = useQuery(
      ['getSystemSuggestJob', dataSearch],
      () => referService.getSystemSuggestJob({ search: dataSearch }),
      { keepPreviousData: true },
  );

  const { mutate: applyCvToJob } = useMutation(jobService.applyCvToJob, {
    onSuccess: (data, body) => {
      Alert.success({ message: t('Refer to Job successfully') });
      queryClient.invalidateQueries(['getListJobCvApplied']);
      },
  });

  const TablePagination = () => (
      <Pagination
          count={Math.ceil(total / 10)}
          page={dataSearch.page + 1}
          onChange={(event, nextPage) => {
            onSearchChange({ page: nextPage - 1 });
          }}
      />
  );

  return (
      <>
          <div className='flex items-center flex-wrap mt-10'>
              <IconButton>
                  <WorkOutlineOutlined />
              </IconButton>
              <Typography variant='h6'>{t('System Suggestion list')}</Typography>
          </div>

          <div className='flex items-start'>
              <div className='flex-1'>
                  <Row gutter={24}>
                      <Col lg={12} span={24}>
                          <Spin spinning={isFetching}>
                              {items.map((job) => {
                                  const status= JOB_STATUS_TYPES.find((item) => item.code === job.status);
                                  return (
                                      <Paper className='flex p-4 mb-6' key={job.id}>
                                          <Avatar src={job.company?.image} style={{ width: 120, height: 120, margin: '0px 24px 12px 0px' }}>
                                              <Avatar src='/gap_logo.png' style={{ width: 120, height: 120 }} />
                                          </Avatar>

                                          <div className='flex-1'>
                                              <Link to={privateRoute.jobView.url(job.id)} className='inline-flex items-center flex-wrap mb-3'>
                                                  <Typography component='span' variant='h6' color='primary' className='mr-2'>
                                                      {job.title}
                                                  </Typography>
                                              </Link>

                                              <Tag color={status?.color}>{status?.name}</Tag>

                                              <Row gutter={24}>
                                                  <Col style={{ width: 240 + 24 }}>
                                                      <Typography color='textSecondary'>
                                                          {t('Number of vacancies')}: <span style={{ color: 'black' }}>{job.numberOfVacancies}</span>
                                                      </Typography>
                                                  </Col>
                                                  <Col style={{ width: 240 + 24 }}>
                                                      <Typography color='textSecondary'>
                                                          {t('Deadline')}: <span style={{ color: 'black' }}>{toFormat(job.deadline)}</span>
                                                      </Typography>
                                                  </Col>
                                              </Row>

                                              {job.bonus > 0 && (
                                                  <Typography variant='h6' color='textSecondary'>
                                                      {t('Bonus')}:{' '}
                                                      <span style={{ color: job.bonus > 0 ? 'green' : 'silver' }}>{formatBonus(job.bonus)}</span>
                                                  </Typography>
                                              )}
                                              <Row gutter={24}>
                                                  <Col style={{ width: 240 + 24 }}>
                                                      <Typography color='textSecondary'>
                                                          {t('Form')}: <span style={{ color: 'black' }}>{job.form}</span>
                                                      </Typography>
                                                  </Col>
                                                  <Col style={{ width: 240 + 24 }}>
                                                      <Typography color='textSecondary'>
                                                          {t('Level')}: <span style={{ color: 'black' }}>{job.level}</span>
                                                      </Typography>
                                                  </Col>
                                              </Row>
                                              <Row gutter={24}>
                                                  <Col style={{ width: 240 + 24 }}>
                                                      <Typography color='textSecondary'>{t('Salary range')}:</Typography>
                                                      <Typography>
                                                          {formatCurrency(job.currency, job.fromSalary)} - {formatCurrency(job.currency, job.toSalary)}
                                                      </Typography>
                                                  </Col>
                                                  <Col flex={1}>
                                                      <Typography color='textSecondary'>{t('Workplace')}:</Typography>
                                                      <Typography>{formatListToString(job.workplace)}</Typography>
                                                  </Col>
                                              </Row>
                                          </div>
                                          <Hidden xsDown>
                                              <div>
                                                  <Button
                                                      variant='outlined'
                                                      color='secondary'
                                                      onClick={() => applyCvToJob({ idJob: job.id, idCv: cv.id })}
                                                  >
                                                      {t('Refer')}
                                                  </Button>

                                              </div>
                                          </Hidden>
                                      </Paper>
                                  );
                              })}
                          </Spin>

                          {total > 0 && (
                              <div className='flex justify-center'>
                                  <TablePagination />
                              </div>
                          )}
                      </Col>
                  </Row>

              </div>

          </div>
      </>
  );
};

export default SystemSuggestionJob;
