import React from 'react';
import {commentService} from "../../services/comment";
import {Avatar, Col, Form, Input, Row, Comment} from 'antd';
import {useMutation, useQuery} from "@tanstack/react-query";
import {useSelector} from "react-redux";
import {profileSelector} from "../../reducers/profile";
import {AddCommentOutlined} from "@mui/icons-material";
import {IconButton, Paper} from "@mui/material";
import {useTranslation} from "react-i18next";
import {toFormatDateTime} from "../../utils/converter";
import {Alert} from "../../components";
import {LoadingButton} from "@mui/lab";

const Comments = ({ data: idJob}: any) => {
    const {t} = useTranslation();

    const {avatarUrl} = useSelector(profileSelector);

    const [isOpenComment, setIsOpenComment] = React.useState(false);

    const [form] = Form.useForm();

    const handleClickComment = React.useCallback(() => {
        setIsOpenComment((isOpen) => !isOpen);
    }, []);

    const { mutate: createComment} = useMutation(
        commentService.createJobComment, {
            onSuccess: () => {
                Alert.success({ message: t('Create comment successfully') });
                form.setFieldsValue({ content: '' });
            },
        },
    );

    const {data: {data: comments = [], total = 0} = {}}
        = useQuery(['getListInfoCv'],
        () => commentService.getJobComment({jobId: idJob, page: 0}),
        {
            keepPreviousData: true,
            enabled: !isOpenComment,
        },
    );
    const handleClickCreate = () => {
        form.validateFields().then((values) => {
            createComment({
                jobId: idJob,
                ...values,
            });
        });
    };

    return (
        <>
            <div className="Comment-container flex-row justify-content-space-between">
                <div className="Footer-item noselect" onClick={handleClickComment}>
                    <IconButton>
                        <AddCommentOutlined/>
                    </IconButton>
                    <span>{total}{t(' Comments')}</span>
                </div>
            </div>
            {isOpenComment && (
                <div className="Comment-container">
                    <Paper style={{paddingRight: '10px' }}>
                        <Form form={form} layout='vertical'>
                            <Row gutter={24}>
                                <Col style={{width: 40}}>
                                    <Avatar src={avatarUrl}/>
                                </Col>
                                <Col flex={1}>
                                    <Form.Item
                                        name='content'
                                        rules={[{required: true, message: t('content is required')}]}>
                                        <Input placeholder='Nhập bình luận của bạn'/>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <LoadingButton
                                        variant='contained'
                                        color='success'
                                        // startIcon={<Send />}
                                        onClick={handleClickCreate}
                                    >
                                        Send
                                    </LoadingButton>
                                </Col>
                            </Row>
                        </Form>
                    </Paper>

                    <div className="Response-Comment-container">
                        {comments.map((comment) => {
                                return (
                                    <div className="Comment-container flex-row" key={comment.id}>
                                        <Comment
                                            author={comment.userCreate.username}
                                            avatar={<Avatar src={comment.userCreate.avatarUrl}/>}
                                            content={<p>{comment.content}</p>}
                                            datetime={toFormatDateTime(comment.timeCreate)}
                                        />
                                    </div>
                                );
                            }
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default Comments;