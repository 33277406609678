import { Editor } from '@tinymce/tinymce-react';
import DynamicFieldButton from './DynamicFieldButton';
import { TINYMCE_KEY } from 'env';
import { Col, Row } from 'antd';
import { Typography } from '@mui/material';
import { useRef } from 'react';

interface TinyMCEProps {
  onChange: any;
  dynamicFields: dynamicFields[];
  value: string;
}
interface dynamicFields {
  shortcode: string;
  label: string;
}
export const EmailEditor: React.FC<TinyMCEProps> = (props: TinyMCEProps) => {
  const { onChange, dynamicFields, value } = props;
  const editorRef = useRef<any>(null);
  const handleInsertDynamicField = (shortcode: string) => {
    const editorInstance = editorRef.current;
    try {
      if (editorInstance) {
        const insertValue = `{{${shortcode}}}`;
        editorInstance.execCommand('mceInsertContent', false, insertValue);
      } else {
        console.log('Editor or editorRef is not available');
      }
    } catch (error) {
      console.error('Insertion error:', error);
    }
  };
  return (
    <>
      <Row gutter={24}>
        <Col lg={4}>
          <Typography className='strong text-base font-bold'>Shortcode</Typography>
        </Col>
        <Col lg={20}>
          <div className='flex flex-wrap gap-2 mb-4'>
            {dynamicFields.map((field) => (
              <DynamicFieldButton
                key={field.shortcode}
                shortcode={field.shortcode}
                onClick={() => handleInsertDynamicField(field.shortcode)}
              />
            ))}
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col lg={4}>
          <Typography className='strong text-base font-bold'>Content</Typography>
        </Col>
        <Col lg={20} span={20}>
          <Editor
            apiKey={TINYMCE_KEY}
            init={{
              menubar: false,
              branding: false,
              height: 300,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar: [
                'fullscreen | undo redo | image | code',
                'formatselect | bold italic backcolor link',
                'alignleft aligncenter alignright alignjustify',
                'bullist numlist outdent indent | help',
              ].join(' | '),
              // toolbar_mode: 'floating',
            }}
            value={value}
            onEditorChange={onChange}
            onInit={(evt, editor) => (editorRef.current = editor)}
          />
        </Col>
      </Row>
    </>
  );
};

export default EmailEditor;
