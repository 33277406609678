import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { chatService } from 'services';

type Props = {
  user: UserType;
  onClick: () => void;
  onTimestamp: (timestamp: number) => void;
};

const ChatLastest = ({ user, onClick, onTimestamp }: Props) => {
  const { ...profile } = useSelector(profileSelector);

  const messageQueries = ['chatService.fetchMessage', profile.userId, user.userId];
  const { data } = useInfiniteQuery(messageQueries, () =>
    chatService
      .fetchMessage({ senderId: profile.userId!, recipientId: user.userId })
      .then(({ data }) => data.reverse()),
  );

  const message = data?.pages?.[0].slice()?.reverse()[0];
  const isSender = profile.userId === message?.senderId;
  const content = message ? `${isSender ? 'You' : message.senderName}: ${message.content || '<Ảnh>'}` : 'No message';

  useEffect(() => {
    if (message?.timestamp) {
      onTimestamp(message.timestamp!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message?.timestamp]);

  return (
    <ListItem dense button divider className='hover:bg-black/10' onClick={onClick}>
      <ListItemAvatar>
        <Avatar src={user.avatarUrl}>
          <Avatar src='/gap_logo.png' />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <div className='flex justify-between'>
            <b>{user.username}</b>
            <span>{message ? DateTime.fromMillis(message.timestamp).toFormat('dd MMM') : ''}</span>
          </div>
        }
        secondary={<div className='max-line-2'>{content}</div>}
      />
    </ListItem>
  );
};

export default ChatLastest;
