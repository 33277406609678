import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { useCallback, useState } from 'react';

const useTable = (sort?: any) => {
  const [dataSort, setDataSort] = useState(sort);

  const onTableChange = useCallback(
    (pagination: TablePaginationConfig, filters: Record<string, any>, sorter: SorterResult<any>) => {
      const { field, order } = sorter;
      if (order === 'ascend') setDataSort(`${field}-asc`);
      else if (order === 'descend') setDataSort(`${field}-desc`);
      else setDataSort('');
    },
    [],
  );

  return [dataSort, onTableChange];
};

export default useTable;
