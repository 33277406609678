import {client, clientDetail} from './axios';

const api = '/api/v1/company';

const getListInfoCompany = (body: any): Promise<GetListCompanyType> =>
  client.post(api, { constructor: 'getListInfoCompany', params_request: body });
const getNameCompanies = (body: any): Promise<GetListCompanyType> =>
  client.post(api, { constructor: 'getNameCompanies', params_request: body });
const getInfoCompany = (body: any): Promise<CompanyType> =>
    clientDetail.post(api, { constructor: 'getInfoCompany', params_request: body });

const createCompany = (body: any): Promise<any> =>
  client.post(api, { constructor: 'createCompany', params_request: body });
const updateCompany = (body: any): Promise<any> =>
  client.post(api, { constructor: 'updateCompany', params_request: body });
const deleteCompany = (body: any): Promise<any> =>
  client.post(api, { constructor: 'deleteCompany', params_request: body });

const fetchCompanySuggestion = (): Promise<GetListCompanyType> =>
    client.get(api + `/suggestion`, );

export default {
  getListInfoCompany,
  getNameCompanies,
  getInfoCompany,

  createCompany,
  updateCompany,
  deleteCompany,
  fetchCompanySuggestion,
};
