import { client } from './axios';

const api = '/api/v1/market';

const unlockTalent = (body: any): Promise<any> => client.post(`${api}/unlock-cv`, body);

const getListUnlockTalent = (body: any): Promise<GetUnlockCvType> =>
    client.post(`${api}/list-cv`, {params_request: body});

export default {
  unlockTalent,
  getListUnlockTalent
};
