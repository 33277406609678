import { ArrowDropDownOutlined, TranslateOutlined } from '@mui/icons-material';
import { Button, List, ListItemButton, Paper, Typography } from '@mui/material';
import { Dropdown } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { storageSelector, updateStorage } from 'reducers/storage';

export const LANGUAGE_BARS = [
  { id: 1, name: 'English', code: 'en' },
  { id: 2, name: 'Tiếng Việt', code: 'vi' },
];

type LanguageBarProps = {
  init?: boolean;
};

const LanguageBar = ({ init }: LanguageBarProps) => {
  const dispatch = useDispatch();
  const { language } = useSelector(storageSelector);
  const { i18n } = useTranslation();

  const handleChangeLanguage = (code: string) => {
    dispatch(updateStorage({ language: code }));
    i18n.changeLanguage(code);
  };

  useEffect(() => {
    if (init) {
      const language = localStorage.getItem('language');
      const code = LANGUAGE_BARS.find((item) => item.code === language)?.code ?? 'en';
      dispatch(updateStorage({ language: code }));
      i18n.changeLanguage(code);
    }
  }, [init, i18n, dispatch]);

  const languageChoose = LANGUAGE_BARS.find((item) => item.code === language) ?? LANGUAGE_BARS[0];

  return (
    <Dropdown
      trigger={['click']}
      placement='bottomRight'
      className='hidden sm:flex'
      overlay={
        <List disablePadding component={Paper} style={{ marginTop: 12 }}>
          {LANGUAGE_BARS.map((item) => (
            <ListItemButton
              key={item.id}
              className='py-1'
              classes={{
                selected: 'bg-black/5',
              }}
              selected={item.code === languageChoose.code}
              onClick={() => handleChangeLanguage(item.code)}
            >
              <Typography component='code' variant='overline' className='mr-3'>
                {item.code}
              </Typography>
              <Typography variant='button'>{item.name}</Typography>
            </ListItemButton>
          ))}
        </List>
      }
    >
      <Button
        variant='outlined'
        color='inherit'
        className='min-w-[160px] text-black/80'
        startIcon={<TranslateOutlined />}
        endIcon={<ArrowDropDownOutlined />}
      >
        {languageChoose.name}
      </Button>
    </Dropdown>
  );
};

export default LanguageBar;
