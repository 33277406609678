import { Group } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Avatar, Paper, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { PerfectScrollbar } from 'components';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { chatService } from 'services';
import { USER_TYPES } from 'utils/constants';

const CardSuggestion = () => {
  const { userId } = useSelector(profileSelector);

  const { data: { data: users } = {} } = useQuery(['fetchSuggestion'], () => chatService.fetchSuggestion({ userId }));

  const [followed, setFollowed] = useState(['']);
  const { mutate: createFollow } = useMutation(chatService.createFollow, {
    onSuccess: (data, body) => {
      setFollowed((array) => array.concat(body.followId));
    },
  });

  return (
    <Paper
      style={{
        width: 400,
        marginLeft: 80,
        // position: 'sticky',
        top: 64 + 24,
        maxHeight: 'calc(100vh - 180px)',
      }}
      className='flex flex-col'
    >
      <div className='flex items-center gap-3 py-3 px-4 border-b'>
        <Group />
        <Typography variant='h6'>People you might know</Typography>
      </div>
      <PerfectScrollbar>
        <div className='flex flex-col gap-5 py-3 px-4'>
          {users?.map((user) => {
            const isFollowed = followed.includes(user.userId);
            return (
              <div key={user.userId} className='flex gap-3'>
                <Avatar src={user.avatarUrl} style={{ width: 52, height: 52 }}>
                  <Avatar src='/gap_logo.png' style={{ width: 52, height: 52 }} />
                </Avatar>
                <div>
                  <div className='flex items-center gap-1'>
                    <Typography fontWeight={600}>{user.username}</Typography>
                    <span>•</span>
                    <Typography color='textSecondary'>
                      {USER_TYPES.find((item) => item.code === user.userType)?.name}
                    </Typography>
                  </div>
                  <LoadingButton
                    variant='outlined'
                    color='secondary'
                    size='small'
                    className='rounded-full mt-2'
                    disabled={isFollowed}
                    onClick={() => createFollow({ userId: userId!, followId: user.userId, type: 0, status: 1 })}
                  >
                    {isFollowed ? 'Following' : 'Follow'}
                  </LoadingButton>
                </div>
              </div>
            );
          })}
        </div>
      </PerfectScrollbar>
    </Paper>
  );
};

export default CardSuggestion;
