import { AssignmentIndOutlined, CloseOutlined } from '@mui/icons-material';
import { Button, IconButton, Pagination, Paper, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Table } from 'antd';
import { Alert } from 'components';
import { useSearch, useTable } from 'hooks';
import { useTranslation } from 'react-i18next';
import { candidateService, jobService, queryClient } from 'services';
import { CANDIDATE_STATUS_TYPES } from 'utils/constants';
import { toFormat } from 'utils/converter';
import { CandidateRow, TalentPoolSearch } from '.';

type CandidatePopupProps = PopupController & {
  job: JobType;
};

const CandidatePopup = ({ job, onClose }: CandidatePopupProps) => {
  const { t } = useTranslation();

  const [dataSearch, onSearchChange] = useSearch({ idJob: job.id });
  const [dataSort, onTableChange] = useTable();

  const { data: { data: items = [], total = 0 } = {}, isFetching } = useQuery(
    ['getListInfoJob', dataSearch, dataSort],
    () => candidateService.getReferralCv({ search: dataSearch, sort: dataSort }),
    { keepPreviousData: true },
  );

  const { mutate: applyCvToJob } = useMutation(jobService.applyCvToJob, {
    onSuccess: (data, body) => {
      Alert.success({ message: t('Refer candidate successfully') });
      queryClient.invalidateQueries(['getListJobCvApplied']);

      onClose();
    },
  });

  const TablePagination = () => (
    <Pagination
      count={Math.ceil(total / 10)}
      page={dataSearch.page + 1}
      onChange={(event, nextPage) => {
        onSearchChange({ page: nextPage - 1 });
      }}
    />
  );

  return (
    <div className='p-4'>
      <div className='flex items-center mb-6'>
        <IconButton>
          <AssignmentIndOutlined />
        </IconButton>
        <Typography variant='h6'>{t('Candidate list')}</Typography>

        <div className='flex-1' />
        <IconButton onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </div>
      <TalentPoolSearch onSearch={onSearchChange} />

      <Paper className='mb-6'>
        <Table
          scroll={{ y: 480, x: 800 }}
          bordered={false}
          loading={isFetching}
          rowKey={(record) => record.id}
          dataSource={items}
          pagination={false}
          onChange={onTableChange}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              width: 180,
              sorter: true,
              render: (_, record) => record.candidateName,
            },
            { title: 'Language', dataIndex: 'language', width: 120 },
            { title: 'Level', dataIndex: 'level', width: 120, sorter: true },
            {
              title: 'Created at',
              dataIndex: 'time',
              width: 120,
              sorter: true,
              render: (_, record) => toFormat(record.createTime),
            },
            {
              title: 'Location',
              dataIndex: 'location',
              width: 120,
              sorter: true,
              render: (_, record) => record.address,
            },
            {
              title: 'Position',
              dataIndex: 'position',
              width: 120,
              render: (_, record) => record.position,
            },
            {
              title: 'Status',
              dataIndex: 'status',
              width: 100,
              sorter: true,
              render: (_, record) => CANDIDATE_STATUS_TYPES.find((item) => item.code === record.status)?.name,
            },
            {
              dataIndex: '',
              align: 'right',
              width: 120,
              render: (_, record) => (
                <Button
                  variant='outlined'
                  color='secondary'
                  onClick={() => applyCvToJob({ idJob: job.id, idCv: record.id })}
                >
                  {t('Refer')}
                </Button>
              ),
            },
          ]}
          expandable={{
            expandedRowRender: (record) => <CandidateRow candidate={record} />,
          }}
        />
      </Paper>

      {total > 0 && (
        <div className='flex justify-center'>
          <TablePagination />
        </div>
      )}
    </div>
  );
};

export default CandidatePopup;
