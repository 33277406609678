import { Translation } from 'react-i18next';

export { default as Alert } from './Alert';
export { default as InputNumber } from './InputNumber';
export { default as LoadingIcon } from './LoadingIcon';
export { default as RichTextEditor } from './RichTextEditor';

export const t = (message: string) => <Translation>{(t) => t(message)}</Translation>;
export { default as PerfectScrollbar } from 'react-perfect-scrollbar';
export { default as InfiniteScroll } from 'react-infinite-scroller';

export { default as LanguageBar } from './LanguageBar';
export { default as Darkmode } from './Darkmode';

export { default as ChatUser } from './ChatUser';
export { default as ChatLastest } from './ChatLastest';
export { default as ChatSticky } from './ChatSticky';
export { default as EmailEditor} from './EmailEditor';
export { default as DynamicFieldButton} from './DynamicFieldButton';
export { default as RecruiterJAboutJoin} from './RecruiterJAboutJoin';
export { default as RecruiterAboutContact} from './RecruiterAboutContact';
export { default as RecruiterAbout} from './RecruiterAbout';