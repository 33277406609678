import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

type InputNumberProps = NumberFormatProps & {
  onChange: (...args: any) => void;
  [key: string]: any;
};

const InputNumber = React.forwardRef(({ onChange, customInput, allowNegative, ...props }: InputNumberProps, ref) => (
  <NumberFormat
    getInputRef={ref}
    customInput={customInput}
    allowNegative={allowNegative ?? false}
    {...props}
    onValueChange={({ floatValue }) => {
      onChange({ target: { value: floatValue } });
    }}
  />
));

export default InputNumber;
