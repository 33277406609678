import {client, clientDetail} from './axios';

const api = '/api/v1/cv';

const getListInfoCv = (body: any): Promise<GetReferralCvType> =>
  client.post(api, { constructor: 'getListInfoCv', ...body });

const getListTalentPool = (body: any): Promise<GetReferralCvType> =>
    client.post(api, { constructor: 'getListTalentPool', ...body });

const getInfoCv = (body: any): Promise<CandidateType> =>
    clientDetail.post(api, { constructor: 'getInfoCv', params_request: body });

const getReferralCv = (body: any): Promise<GetReferralCvType> =>
  client.post(api, { constructor: 'getReferralCv', ...body });

const createCv = (body: any): Promise<any> => client.post(api, { constructor: 'createCv', params_request: body });
const deleteCv = (body: any): Promise<any> => client.post(api, { constructor: 'deleteCv', params_request: body });
const updateCv = (body: any): Promise<any> => client.post(api, { constructor: 'updateCv', params_request: body });

export default {
  getListInfoCv,
  getInfoCv,
  getListTalentPool,
  getReferralCv,

  createCv,
  deleteCv,
  updateCv,
};
