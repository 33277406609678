import {PrivacyPolicy, TermsOfService} from "../views/Common";

const publicRoute = {
  privacyPolicy:{
    path:'/privacy-policy',
    url: '/public/privacy-policy',
    element:<PrivacyPolicy />,
  },
  termsOfService:{
    path:'/terms-of-service',
    url: '/public/terms-of-service',
    element:<TermsOfService />,
  },
  blog:{
    path:'/blog',
    url: '/public/blog',
    element:<TermsOfService />,
  },
};

export default publicRoute;
