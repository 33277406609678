import { AddOutlined, CheckOutlined, CloudUploadOutlined, NavigateBeforeOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { IconButton, Paper, TextField, Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, Form, Input, Row, Select, Upload } from 'antd';
import { Alert, InputNumber, RichTextEditor } from 'components';
import { decode } from 'html-entities';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { privateRoute } from 'routes';
import { fileService, jobService } from 'services';
import {CANDIDATE_LEVELS, CURRENCY_TYPES, JOB_FORMS, JOB_STATUS_TYPES, WORKPLACE_TYPES, SKILLS, CONTRACT_DURATION, LANGUAGES} from 'utils/constants';
import { normalizeJob } from 'utils/converter';
import { CompanySelect } from 'views/Company/components';

const JobUpdate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { isSuper, isAdmin } = useSelector(profileSelector);

  const idCompany = new URLSearchParams(location.search).get('idCompany') ?? undefined;

  const [form] = Form.useForm();

  const [deadline, setDeadline] = useState<number>();
  const [description, setDescription] = useState('');
  const [requirement, setRequirement] = useState('');
  const [welfare, setWelfare] = useState('');

  const { data: job } = useQuery(['getInfoJob', id], () => jobService.getInfoJob({ idJob: +id! }), {
    onSuccess: (data) => {
      const { deadline, description, requirement, welfare, company, ...job } = normalizeJob(data) as JobType;
      if (deadline) setDeadline(deadline);
      if (description) setDescription(decode(description));
      if (requirement) setRequirement(decode(requirement));
      if (welfare) setWelfare(decode(welfare));

      form.setFieldsValue({ ...job, idCompany: company?.id });
    },
    enabled: !!id,
  });


  const { mutate: uploadJob, isLoading: isLoadingJob } = useMutation(fileService.uploadFile, {
    onSuccess: (data) => {
      const { url } = data;
      form.setFieldsValue({ jobDescription: url });
    },
  });

  const { mutate: updateJob, isLoading: isLoadingUpdate } = useMutation(
    id ? jobService.updateJob : jobService.createJob,
    {
      onSuccess: () => {
        if (id) {
          Alert.success({ message: t('Update job successfully') });
          navigate(privateRoute.jobView.url(id));
        } else {
          Alert.success({ message: t('Create job successfully') });
          navigate(privateRoute.jobList.path);
        }
      },
    },
  );

  const handleClickUpdate = () => {
    form.validateFields().then((values) => {
      updateJob({
        id: +id!,
        ...values,
        ...{ idJobType: 1, deadline, description, requirement, welfare },
      });
    });
  };

  return (
    <>
      <div className='flex items-center flex-wrap mb-6'>
        <Link to={privateRoute.jobList.path}>
          <IconButton>
            <NavigateBeforeOutlined />
          </IconButton>
        </Link>
        <Typography variant='h6'>{id ? t('Update job') : t('Create job')}</Typography>
      </div>

      <Paper className='p-4'>
        <Form form={form} layout='vertical'>
          <Row gutter={24}>
            <Col lg={12} span={24}>
              <Row gutter={24}>
                <Col flex={1}>
                  <Form.Item
                    name='title'
                    label={t('Title')}
                    rules={[{ required: true, message: t('Title is required') }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col md={12} span={24}>
                  <Form.Item
                    name='idCompany'
                    label={t('Company')}
                    rules={[{ required: true, message: t('Company is required') }]}
                  >
                    <CompanySelect
                      initCompany={job?.company}
                      initValue={job?.company?.id ?? (idCompany ? +idCompany : undefined)}
                      onChange={(value) => form.setFieldsValue({ idCompany: value })}
                    />
                  </Form.Item>
                  <Form.Item name='contractDuration' label={t('Contract Duration')}>
                      <Select>
                        {CONTRACT_DURATION.map((item) => (
                            <Select.Option key={item.id} value={item.code}>
                              {item.name}
                            </Select.Option>
                        ))}
                      </Select>
                  </Form.Item>
                  <Form.Item name='workplace' label={t('Workplace')}
                    rules={[{ required: true, message: t('Workplace is required') }]}
                  >
                    <Select mode='tags'>
                      {WORKPLACE_TYPES.map((item) => (
                          <Select.Option key={item.id} value={item.code}>
                            {item.name}
                          </Select.Option>
                      ))}
                    </Select>

                  </Form.Item>
                  <Form.Item name='position' label={t('Position')}>
                    <Input />
                  </Form.Item>
                  <Form.Item name='level' label={t('Level')}>
                    <Select>
                      {CANDIDATE_LEVELS.map((item) => (
                          <Select.Option key={item.id} value={item.code}>
                            {item.name}
                          </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name='form' label={t('Type')} initialValue={JOB_FORMS[0].code}>
                    <Select>
                      {JOB_FORMS.map((item) => (
                        <Select.Option key={item.id} value={item.code}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} span={24}>
                  <Form.Item label={t('Deadline')} required>
                    <DesktopDatePicker
                      value={deadline ? DateTime.fromMillis(deadline) : null}
                      onChange={(value) => value?.isValid && setDeadline(value.toMillis())}
                      renderInput={(params) => <TextField size='small' style={{ marginTop: -3 }} {...params} />}
                      inputFormat='dd/MM/yyyy'
                      minDate={DateTime.now()}
                    />
                  </Form.Item>
                  <Form.Item name='language' label={t('Language')}>
                        <Select>
                          {LANGUAGES.map((item) => (
                              <Select.Option key={item.id} value={item.code}>
                                {item.name}
                              </Select.Option>
                          ))}
                        </Select>
                  </Form.Item>
                  <Form.Item
                    name='numberOfVacancies'
                    label={t('Number of vacancies')}
                    rules={[{ required: true, message: t('Number is required') }]}
                  >
                    <InputNumber customInput={Input} />
                  </Form.Item>
                  <Form.Item name='bonus' label={t('Bonus')}>
                    <InputNumber
                      customInput={Input}
                      thousandSeparator
                      addonAfter={
                        <Select defaultValue={CURRENCY_TYPES[0].code}>
                          <Select.Option value={CURRENCY_TYPES[0].code}>{CURRENCY_TYPES[0].name}</Select.Option>
                        </Select>
                      }
                    />
                  </Form.Item>
                  <Form.Item name='status' label={t('Status')} initialValue={JOB_STATUS_TYPES[1].code}>
                    <Select disabled={!(isSuper || isAdmin)}>
                      {JOB_STATUS_TYPES.map((item) => (
                        <Select.Option key={item.id} value={item.code}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item name='skill'
                             label={t('Skill')}
                  >
                    <Select mode='tags'>
                      {SKILLS.map((skill) => (
                          <Select.Option key={skill} value={skill}>
                            {skill}
                          </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col md={12} span={24}>
                  <Form.Item
                    name='fromSalary'
                    label={t('From Salary')}
                    rules={[{ required: true, message: t('Salary is required') }]}
                  >
                    <InputNumber
                      customInput={Input as any}
                      thousandSeparator
                      addonAfter={
                        <Form.Item name='currency' initialValue={CURRENCY_TYPES[0].code} style={{ margin: -1 }}>
                          <Select className='w-[unset]'>
                            {CURRENCY_TYPES.map((item) => (
                              <Select.Option key={item.id} value={item.code}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col md={12} span={24}>
                  <Form.Item name='toSalary' label='To Salary'>
                    <InputNumber
                      customInput={Input}
                      thousandSeparator
                      addonAfter={
                        <Form.Item name='currency' style={{ margin: -1 }}>
                          <Select className='w-[unset]'>
                            {CURRENCY_TYPES.map((item) => (
                              <Select.Option key={item.id} value={item.code}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item name='jobDescription' hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item label={t('Job description')}>
                    <Upload
                      accept='application/pdf'
                      listType='picture'
                      maxCount={1}
                      customRequest={({ file, onSuccess }) => {
                        const formData = new FormData();
                        formData.append('file', file);
                        formData.append('fileType', 'jd_job');
                        uploadJob(formData, {
                          onSuccess: () => {
                            onSuccess?.({});
                          },
                        });
                      }}
                    >
                      <LoadingButton
                        variant='outlined'
                        color='inherit'
                        startIcon={<CloudUploadOutlined />}
                        loading={isLoadingJob}
                      >
                        {t('Upload')}
                      </LoadingButton>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col lg={12} span={24}>
              <Form.Item label={t('Description')}>
                <RichTextEditor value={description} onEditorChange={setDescription} />
              </Form.Item>
              <Form.Item label={t('Requirement')}>
                <RichTextEditor value={requirement} onEditorChange={setRequirement} />
              </Form.Item>
              <Form.Item label={t('Welfare')}>
                <RichTextEditor value={welfare} onEditorChange={setWelfare} />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <LoadingButton
          variant='contained'
          color='success'
          startIcon={id ? <CheckOutlined /> : <AddOutlined />}
          loading={isLoadingUpdate}
          onClick={handleClickUpdate}
        >
          {id ? t('Update job') : t('Create job')}
        </LoadingButton>
      </Paper>
    </>
  );
};

export default JobUpdate;
