
import {
    Pagination,
    Paper,

} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {Table, Tag} from 'antd';
import {useSearch, useTable} from 'hooks';
import {referService} from 'services';
import { REFERRAL_STATUS_TYPES } from 'utils/constants';
import {formatBonus} from 'utils/converter';


type ReferListProps = {
    searchParams?: any;
};

const BonusHistory = ({searchParams }: ReferListProps) => {

    const [dataSearch, onSearchChange] = useSearch(searchParams);
    const [onTableChange] = useTable();

    const {
        data: { data: items = [], total = 0 } = {},
        isFetching,
    } = useQuery(['getBonusHistory', dataSearch], () => referService.getBonusHistory(dataSearch), {
        keepPreviousData: true,
    });


    const TablePagination = () => (
        <Pagination
            count={Math.ceil(total / 10)}
            page={dataSearch.page + 1}
            onChange={(event, nextPage) => {
                onSearchChange({ page: nextPage - 1 });
            }}
        />
    );

    return (
        <>
            <Paper className=''>
                <Table
                    scroll={{ x: 800 }}
                    bordered={false}
                    loading={isFetching}
                    rowKey={(record) => record.id}
                    dataSource={items}
                    pagination={false}
                    onChange={onTableChange}
                    columns={[
                        {
                            title: 'Candidate',
                            dataIndex: 'name',
                            width: 120,
                            render: (_, record) => record.cv.candidateName,
                        },
                        {
                            title: 'Company Name',
                            dataIndex: 'companyName',
                            width: 120,
                            render: (_, record) => record.comp.name,
                        },
                        {
                            title: 'Job title',
                            dataIndex: 'jobTitle',
                            width: 120,
                            render: (_, record) => record.job.title,
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            width: 200,
                            sorter: true,
                            render: (_, record) => {
                                const status = REFERRAL_STATUS_TYPES.find((item) => item.code === record.status);
                                return (
                                    <Tag color={status?.color}>{status?.name}</Tag>
                                );
                            },
                        },
                        {
                            title: 'Bonus',
                            dataIndex: 'bonus',
                            width: 120,
                            sorter: true,
                            render: (_, record) => formatBonus(record.job.bonus),
                        },
                    ]}
                />
            </Paper>

            {total > 0 && (
                <div className='flex justify-center mt-2'>
                    <TablePagination />
                </div>
            )}
        </>
    );
};

export default BonusHistory;
