import { Button, IconButton, Pagination, Paper, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { adsService } from 'services';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { AddOutlined } from '@mui/icons-material';
import { Space, Table, Popconfirm, Form } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined } from '@ant-design/icons';
import { Alert } from 'components';
import { timestampToDdMmYyyy } from 'utils/converter';
import Switch from '@mui/material/Switch';
import FormCreate from './components/FormCreate';

interface DataType {
  id: number;
  key: string;
  Name: string;
  Created: number;
  Status: boolean;
  Action: string;
}
const BreakNewsList = () => {
  const { t } = useTranslation();
  const PAGE_SIZE = 10;
  const [pageNumber, setPageNumber] = useState(0);
  const [dataTable, setDataTable] = useState<any>([]);
  const [foceUpdate, setFoceUpdate] = useState(false);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const {
    data: { data = [], total = 0, page = 0 } = {},
    refetch,
    isFetching,
  } = useQuery(
    ['getListBreakNews'],
    () =>
      adsService.getListBreakNews({
        pageNumber: pageNumber,
        pageSize: PAGE_SIZE,
      }),
    {
      keepPreviousData: true,
      onSuccess: (res) => {
        setDataTable(res.data);
      },
    },
  );
  useEffect(() => {
    refetch();
  }, [refetch, foceUpdate]);
  useEffect(() => {
    refetch();
  }, [pageNumber, refetch]);
  const { mutate: deleteBreakNews } = useMutation(adsService.deleteBreakNews, {
    onSuccess: () => {
      Alert.success({ message: t('Delete campaign successfully') });
      refetch();
    },
  });
  const { mutate: updateBreakNews, isLoading: isLoadingUpdate } = useMutation(adsService.updateBreakNews, {
    onSuccess: (res) => {
      refetch();
    },
  });
  const onChangeSwitch = (event: any, id: number) => {
    updateBreakNews({
      id_breaking_new: id,
      is_active: event.target.checked,
    });
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const columns: ColumnsType<DataType> = [
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'Created at',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 120,
      align: 'center',
      render: (item) => <p>{timestampToDdMmYyyy(item)}</p>,
    },
    {
      title: 'Show/Hide',
      dataIndex: 'active',
      key: 'active',
      width: 100,
      align: 'center',
      render: (item, record) => (
        <Switch checked={item} onChange={(event: any) => onChangeSwitch(event, Number(record.id))} />
      ),
    },
    {
      title: 'Delete',
      key: 'delete',
      width: 100,
      align: 'center',
      dataIndex: 'action',
      render: (_, record) => (
        <Space size='middle'>
          <Popconfirm
            key='delete'
            placement='left'
            title='Bạn có chắc chắn muốn xoá?'
            onConfirm={() => deleteBreakNews(String(record.id))}
            okText='Có'
            cancelText='Không'
          >
            <DeleteOutlined style={{ fontSize: '16px', color: '#ed6f6f' }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const TablePagination = () => (
    <Pagination
      className='my-5'
      count={Math.ceil(total / PAGE_SIZE)}
      page={page + 1}
      onChange={(event, nextPage) => {
        setPageNumber(nextPage - 1);
      }}
    />
  );
  const handleCreate = async () => {
    await form.resetFields();
    showDrawer();
  };
  return (
    <>
      <Paper className='p-4 flex items-center flex-wrap mb-6'>
        <IconButton>
          <AlternateEmailIcon />
        </IconButton>
        <Typography variant='h6'>{t('Break news List')}</Typography>
        <div className='flex-1' />
        <div className='flex gap-4'>
          <Button color='secondary' startIcon={<AddOutlined />} onClick={() => handleCreate()}>
            Thêm mới
          </Button>
        </div>
      </Paper>
      <Paper className='p-6'>
        <Table columns={columns} dataSource={dataTable} loading={isFetching} pagination={false} />
        <TablePagination />
      </Paper>
      <FormCreate
        form={form}
        setFoceUpdate={setFoceUpdate}
        foceUpdate={foceUpdate}
        open={open}
        showDrawer={showDrawer}
        onClose={onClose}
      />
    </>
  );
};

export default BreakNewsList;
