import {
  AssignmentIndOutlined,
  DashboardOutlined,
  DateRangeOutlined,
  GroupOutlined, PaidOutlined, PersonOutlined,
  RefreshOutlined,
  WorkOutlineOutlined,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Grid, Hidden, IconButton, Paper, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { privateRoute } from 'routes';
import { reportService } from 'services';
import { CardSuggestion, TopCompanySuggest } from './components';
import {formatBonus} from "../../utils/converter";
import {JobRecently, JobUrgently} from "../Job/components";

type BoardProps = {
  visible?: boolean;
  value?: React.ReactNode;
  name?: string;
  extra?: React.ReactNode;
  icon: React.ReactNode;
  url: string;
  color: string;
};

const Board = ({ visible, name, value, extra, icon, url, color }: BoardProps) => {
  const { t } = useTranslation();

  return visible ? (
    <Grid item xs={12} md={6}>
      <Paper style={{ backgroundColor: `${color}DD` }}>
        <div className='flex justify-between p-4 pb-8'>
          <div style={{ color: '#FFF' }}>
            <Typography variant='h4'>{value ?? 0}</Typography>
            <Typography variant='h6'>{name}</Typography>
            <Typography variant='subtitle1' color='#000A'>
              {extra}
            </Typography>
          </div>
          <Box
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 90,
                color: '#FFF9',
              },
            }}
          >
            {icon}
          </Box>
        </div>
        <Divider />
        <Link
          to={url}
          className='flex justify-center text-lg hover:brightness-90 p-4 py-1'
          style={{ backgroundColor: `${color}FF`, color: '#FFF' }}
        >
          {t('View')}
        </Link>
      </Paper>
    </Grid>
  ) : (
    <></>
  );
};

const Home = () => {
  const { t } = useTranslation();
  const { isSuper, isAdmin, isUser, isRecruit, isCompany } = useSelector(profileSelector);
  const {
    data: {data} = {},
    isFetching,
    refetch,
  } = useQuery(['getDashboard'], () => reportService.getDashboard());

  return (
    <div className='flex items-start'>
      <div className='flex-1'>
        <div className='flex items-center mb-6'>
          <IconButton>
            <DashboardOutlined />
          </IconButton>
          <Typography variant='h6' className='flex-1'>
            {t('Dashboard')}
          </Typography>

          <div className='flex-1' />
          <LoadingButton color='inherit' startIcon={<RefreshOutlined />} loading={isFetching} onClick={() => refetch()}>
            {t('Refresh')}
          </LoadingButton>
        </div>

        <Grid container spacing={2}>
          <Board
            visible={isSuper || isAdmin || isRecruit || isCompany}
            name={t('Total Jobs')}
            color='#339f97'
            value={data?.totalJob}
            extra={`Open: ${data?.totalOpenJob ?? 0}`}
            icon={<WorkOutlineOutlined />}
            url={privateRoute.jobList.path}
          />
          <Board
            visible={isSuper || isAdmin || isRecruit}
            name={t('Total Candidates')}
            color='#009551'
            value={data?.totalCv}
            extra={`Active: ${data?.totalOpenCv ?? 0}`}
            icon={<AssignmentIndOutlined />}
            url={privateRoute.candidateList.path}
          />
          <Board
            visible={isSuper || isAdmin}
            name={t('Total Referral')}
            color='#dca146'
            value={data?.totalReferral}
            icon={<DateRangeOutlined />}
            url={privateRoute.referList.path}
          />
          <Board
            visible={isSuper || isAdmin}
            name={t('Total Users')}
            color='#3949ab'
            value={data?.totalUser}
            extra={
              <div>
                {`Total Recruiter: ${data?.totalHrUser ?? 0}`}
                <br />
                {`Total Company: ${data?.totalCompanyUser ?? 0}`}
              </div>
            }
            icon={<GroupOutlined />}
            url={privateRoute.userList.path}
          />

          <Board
            visible={isCompany && isUser}
            name={t('Refer for all job')}
            color='#9c27b0'
            value={data?.totalCompanyReferral}
            extra={`Refer on open job: ${data?.totalCompanyActiveReferral ?? 0}`}
            icon={<WorkOutlineOutlined />}
            url={privateRoute.referList.path}
          />
          <Board
            visible={isRecruit && isUser}
            name={t('Total Referral')}
            color='#ec407a'
            value={data?.totalUserReferral}
            extra={`Done: ${data?.totalDoneReferral ?? 0}`}
            icon={<PersonOutlined />}
            url={privateRoute.referList.path}
          />

          <Board
              visible={isRecruit || isCompany}
              name={t('Total Done Bonus')}
              color='#f2bc33'
              value={formatBonus(data?.totalDoneBonus)}
              extra={`Wait: ${formatBonus(data?.totalWaitBonus) ?? 0}`}
              icon={<PaidOutlined />}
              url={privateRoute.reportView.path}
          />
        </Grid>

        <Grid container spacing={2} style={{marginTop:10}}>
          <JobRecently />
          <JobUrgently />
        </Grid>

      </div>


      <Hidden mdDown>
        {isRecruit && (
            <TopCompanySuggest />
        )}
        {(isSuper || isAdmin || isCompany) && (
            <CardSuggestion />
        )}

      </Hidden>
    </div>
  );
};

export default Home;
