import React from 'react'
interface IPropertiesSvg {
    width?: number;
    height?: number
}
export const EmployeeSvg = ({ width = 20, height = 12.5 }: IPropertiesSvg) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 12.5">
            <g id="Group_9430" data-name="Group 9430" transform="translate(-501.5 -564.789)">
                <g id="Group_8947" data-name="Group 8947" transform="translate(440.488 547.279)">
                    <circle id="Ellipse_1380" data-name="Ellipse 1380" cx="2.95" cy="2.95" r="2.95" transform="translate(63.867 19.28)" fill="none" stroke="#323233" stroke-miterlimit="10" stroke-width="1" />
                    <path id="Path_7796" data-name="Path 7796" d="M64.913,21.047a5.842,5.842,0,0,1,3.7,5.435" transform="translate(4.078 3.529)" fill="none" stroke="#323233" stroke-miterlimit="10" stroke-width="1" />
                    <circle id="Ellipse_1381" data-name="Ellipse 1381" cx="2.191" cy="2.191" r="2.191" transform="translate(73.985 18.011)" fill="none" stroke="#323233" stroke-miterlimit="10" stroke-width="1" />
                    <path id="Path_7798" data-name="Path 7798" d="M69.209,19.8a4.34,4.34,0,0,1,2.746,4.037" transform="translate(8.557 2.037)" fill="none" stroke="#323233" stroke-miterlimit="10" stroke-width="1" />
                    <path id="Path_7799" data-name="Path 7799" d="M66.506,21.8a5.274,5.274,0,0,1,2.132-2.073" transform="translate(5.734 2.127)" fill="none" stroke="#323233" stroke-miterlimit="10" stroke-width="1" />
                    <path id="Path_7800" data-name="Path 7800" d="M68.61,21.047a5.842,5.842,0,0,0-3.7,5.435" transform="translate(-3.401 3.529)" fill="none" stroke="#323233" stroke-miterlimit="10" stroke-width="1" />
                </g>
            </g>
        </svg>

    )
}

export const CupSvg = ({ width = 18.289, height = 19.671 }: IPropertiesSvg) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18.289 19.671"><defs></defs><path className="a" d="M28.258,9.12a.457.457,0,0,0-.456-.436H24.489V7.408a.457.457,0,0,0-.457-.457H14.207a.457.457,0,0,0-.457.457V8.684H10.437a.457.457,0,0,0-.456.436c-.083,1.856.641,5.478,3.925,6.194.53,2.3,2.372,4.461,4.757,4.71v5.684H15.749a.457.457,0,0,0,0,.914h6.74a.457.457,0,0,0,0-.914H19.576V20.024A5.281,5.281,0,0,0,23.2,17.778a.457.457,0,0,0-.736-.541,4.253,4.253,0,0,1-3.345,1.9c-2.509,0-4.455-2.763-4.455-5.141V7.865h8.91v6.272a2.164,2.164,0,0,1-.069.67.457.457,0,0,0,.342.549.449.449,0,0,0,.14.008c.014,0,.029,0,.043,0a.475.475,0,0,0,.078-.006C27.586,14.764,28.343,11.018,28.258,9.12ZM10.9,9.6H13.75V14c0,.112,0,.225.012.339C11.3,13.566,10.94,10.706,10.9,9.6Zm13.592,4.731c0-.061,0-.124,0-.2V9.6h2.85C27.286,10.7,26.914,13.556,24.489,14.329Z" transform="translate(-9.975 -6.951)" /></svg>

    )
}

export const HealthSvg = ({ width = 20, height = 12.5 }: IPropertiesSvg) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17.7 20.331"><defs></defs><g transform="translate(0 0)"><path className="a" d="M143.6,37.448a1.373,1.373,0,0,1-.466-.084c-1.3-.466-5.7-2.311-7.344-6.217-1.221-2.908-1.09-7.671-.932-9.581,0-.83.811-1.137,1.184-1.277,1.594-.624,5.592-2.256,6.925-3a1.4,1.4,0,0,1,1.342,0c1.361.746,5.3,2.367,6.888,2.992a1.565,1.565,0,0,1,1.193,1.277h0c.112,1.911.242,6.673-.979,9.581-1.64,3.905-6.012,5.741-7.307,6.217A1.329,1.329,0,0,1,143.6,37.448Zm0-19.4a.506.506,0,0,0-.224.056c-1.417.774-5.368,2.386-7.028,3.076-.494.186-.6.317-.606.466-.1,1.864-.233,6.44.932,9.171,1.491,3.551,5.592,5.257,6.794,5.695a.412.412,0,0,0,.3,0c1.212-.438,5.275-2.144,6.767-5.695,1.137-2.731,1.016-7.326.932-9.171,0-.149-.112-.27-.6-.466-1.678-.662-5.592-2.274-7-3.048A.5.5,0,0,0,143.6,18.053Z" transform="translate(-134.756 -17.117)"/></g><g transform="translate(5.618 7.322)"><path className="a" d="M143.076,30.573h0a.473.473,0,0,1-.336-.186L140.876,27.9a.467.467,0,0,1,.093-.652.475.475,0,0,1,.652.093l1.547,2.069,4.567-4.3a.466.466,0,0,1,.659,0l0,0a.456.456,0,0,1,.007.646l-.007.007-4.977,4.66A.474.474,0,0,1,143.076,30.573Z" transform="translate(-140.783 -24.973)"/></g></svg>
    )
}

export const LinkedInSvg = ({ width = 16, height = 16 }: IPropertiesSvg) => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" data-supported-dps="16x16" className="mercado-match" width={width} height={height} focusable="false">
                        <path d="M14 0H2a1 1 0 00-1 1v15l3-2h10a1 1 0 001-1V1a1 1 0 00-1-1zM5 12H3V5h2zM4 4.2A1.27 1.27 0 012.75 3a1.25 1.25 0 012.5 0A1.27 1.27 0 014 4.2zm9 7.8h-2V8.73c0-.79-.18-1.93-1.34-1.93A1.74 1.74 0 008 8.61V12H6V5h1.85v1a2.72 2.72 0 012.29-1.2C12.27 4.8 13 6.09 13 8.29z" fill="#0a66c2"></path>
                    </svg>
    )
}