import { AttachFileOutlined, CameraAltOutlined, Close, InsertEmoticonOutlined, Send } from '@mui/icons-material';
import {
  Avatar,
  Collapse,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { Popover } from 'antd';
import { InfiniteScroll, PerfectScrollbar } from 'components';
import { default as EmojiPicker } from 'emoji-picker-react';
import { DateTime } from 'luxon';
import { Fragment, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { chatService, fileService, queryClient, userService } from 'services';
import { getBase64 } from 'utils/common';

type Props = {
  user: UserType;
  onClose: () => void;
};

const ChatUser = ({ user, onClose }: Props) => {
  const { ...profile } = useSelector(profileSelector);

  const messageQueries = ['chatService.fetchMessage', profile.userId, user.userId];
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    messageQueries,
    ({ pageParam: page }) =>
      chatService
        .fetchMessage({ senderId: profile.userId!, recipientId: user.userId, page })
        .then(({ data }) => data.reverse()),
    {
      getNextPageParam: (messages, pages) => {
        if (messages.length === 0) return undefined;
        return pages.length;
      },
      keepPreviousData: true,
    },
  );

  const { data: receiver = user } = useQuery(['getUserInfo', user.userId], () =>
    userService.getUserInfo({ userId: user.userId }),
  );

  const [isOpenEmoji, setOpenEmoji] = useState(false);
  const [isOpenChat, setOpenChat] = useState(true);
  const [content, setContent] = useState('');
  const scrollEl = useRef<HTMLElement>();

  const [images, setImages] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickSend = async () => {
    setContent('');
    setImages([]);
    setFiles([]);
    if (!content.trim() && files.length === 0) return;

    let urls;
    if (files.length > 0) {
      urls = await Promise.all(
        files.map((file) => {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('fileType', 'messenger');
          return fileService.uploadFile(formData).then(({ url }) => url);
        }),
      );
    }

    const message = {
      id: Math.random().toString(),
      senderId: profile.userId!,
      recipientId: user.userId,
      senderName: profile.username!,
      recipientName: user.username,
      content: content.trim(),
      images: urls?.join(','),
      timestamp: Date.now(),
    };
    chatService.sendMessage(message);
    queryClient.setQueryData(messageQueries, {
      ...data,
      pages: data?.pages.map((page, index) => (index ? page : page.concat(message))),
    });
    setTimeout(() => {
      scrollEl.current!.scrollTop = scrollEl.current!.scrollHeight;
    }, 0);
  };

  const handleChangeFile = (event: any) => {
    const files = event.target.files as FileList | null;
    Array.from(files ?? []).forEach((file) => {
      getBase64(file, (base64: any) => {
        setImages((images) => images.concat(base64));
        setFiles((files) => files.concat(file));
      });
    });
  };

  const reversedPages = data?.pages?.slice().reverse();
  return (
    <Paper className='w-[320px] rounded-t-lg'>
      <div
        className='flex items-center gap-3 p-2 border-b rounded-t-lg hover:bg-black/5 hover:cursor-pointer'
        onClick={() => setOpenChat((open) => !open)}
      >
        <Avatar variant='circular' src={receiver.avatarUrl} style={{ width: 36, height: 36 }} />
        <Typography variant='subtitle2'>{receiver.username}</Typography>

        <div className='flex-1 flex justify-end'>
          <IconButton size='small' onClick={onClose}>
            <Close />
          </IconButton>
        </div>
      </div>

      <Collapse in={isOpenChat} className='overflow-hidden'>
        <PerfectScrollbar className='min-h-[240px] max-h-[360px]' containerRef={(ref) => (scrollEl.current = ref)}>
          <InfiniteScroll
            useWindow={false}
            hasMore={hasNextPage}
            loadMore={() => {
              fetchNextPage();
            }}
            isReverse
          >
            <div></div>
            {reversedPages?.map((messages, index) => {
              return (
                <Fragment key={index}>
                  {messages.map((message) => {
                    const sender = message.senderId === profile.userId ? profile : receiver;
                    const images = message.images?.split(',') ?? [];
                    return (
                      <ListItem key={message.id} dense className='hover:bg-black/5'>
                        <ListItemAvatar>
                          <Avatar src={sender.avatarUrl}>
                            <Avatar src='/gap_logo.png' />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <div className='flex justify-start gap-1'>
                              <b>{sender.username}</b>
                              <span>•</span>
                              <span>{DateTime.fromMillis(message.timestamp).toFormat('hh:mm a')}</span>
                            </div>
                          }
                          secondary={
                            <div className='whitespace-pre-line break-words'>
                              {message.content}
                              {images.length > 0 && (
                                <div className='flex flex-wrap gap-2 mt-2'>
                                  {message.images?.split(',').map((item, index) => (
                                    <img key={index} src={item} className='h-[80px] border rounded' />
                                  ))}
                                </div>
                              )}
                            </div>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </Fragment>
              );
            })}
          </InfiniteScroll>
        </PerfectScrollbar>

        <div className='sticky inset-0 top-[unset] bg-white border-t-2'>
          <ListItem divider className='flex flex-col items-start'>
            <TextField
              fullWidth
              multiline
              minRows={2}
              maxRows={2}
              placeholder='Enter your messenge...'
              InputProps={{ className: 'bg-black/5 px-3 py-2' }}
              value={content}
              onChange={(event) => {
                setContent(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  handleClickSend();
                }
              }}
            />

            {images.length > 0 && (
              <div className='flex flex-wrap gap-2 mt-2'>
                {images.map((image, index) => (
                  <div key={index} className='relative'>
                    <img src={image} className='h-[60px] border rounded' />
                    <IconButton
                      size='small'
                      className='absolute top-[2px] right-[2px] p-[2px] bg-white/60 hover:bg-white/80'
                      onClick={() => {
                        setImages((images) => images.filter((item, removeIndex) => removeIndex !== index));
                        setFiles((files) => files.filter((item, removeIndex) => removeIndex !== index));
                      }}
                    >
                      <Close className='w-[14px] h-[14px]' />
                    </IconButton>
                  </div>
                ))}
              </div>
            )}
          </ListItem>
          <ListItem className='flex justify-between'>
            <div className='flex gap-1'>
              <IconButton size='small' onClick={() => inputRef.current?.click()}>
                <CameraAltOutlined fontSize='small' />
              </IconButton>
              <input hidden multiple type='file' ref={inputRef} accept='image/*' onChange={handleChangeFile} />

              <IconButton size='small'>
                <AttachFileOutlined fontSize='small' className='rotate-45' />
              </IconButton>

              <Popover
                showArrow={false}
                destroyTooltipOnHide={false}
                overlayClassName='Emoji-Popover'
                visible={isOpenEmoji}
                onVisibleChange={setOpenEmoji}
                content={
                  <EmojiPicker
                    lazyLoadEmojis
                    onEmojiClick={(emoji) => {
                      setContent((text) => text.concat(emoji.emoji));
                      setOpenEmoji(false);
                    }}
                  />
                }
              >
                <IconButton size='small'>
                  <InsertEmoticonOutlined fontSize='small' />
                </IconButton>
              </Popover>
            </div>
            <IconButton size='small' color='info' onClick={handleClickSend}>
              <Send fontSize='small' />
            </IconButton>
          </ListItem>
        </div>
      </Collapse>
    </Paper>
  );
};

export default ChatUser;
