import { clientSocket } from './axios';
import { stompClient } from './client';

const fetchMessage = ({ senderId, recipientId, ...params }: FetchMessageParams): Promise<FetchMessageResponse> =>
  clientSocket.get(`/messages/${senderId}/${recipientId}`, { params });
const fetchMessageLatest = ({ recipientId, ...params }: FetchMessageParams): Promise<FetchMessageResponse> =>
  clientSocket.get(`/messages/latest/${recipientId}`, { params });

const fetchSuggestion = (params: FetchSuggestionParams): Promise<FetchSuggestionResponse> =>
  clientSocket.get(`/api/v1/user/suggestion`, { params });
const createFollow = (body: CreateFollowBody): Promise<any> => clientSocket.post(`/follow/create-follow`, body);

const sendMessage = (body: SendMessageBody): Promise<any> => stompClient.send('/app/chat', {}, JSON.stringify(body));

export default {
  fetchMessage,
  fetchMessageLatest,

  fetchSuggestion,
  createFollow,

  sendMessage,
};
