import { Button } from '@mui/material'
import { Card, Col, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const RecruiterJAboutJoin = () => {
  return (
    <>
    <p className='text-3xl font-bold my-3'>Công việc đã hoàn thành (0)</p>
    <Card className='bg-[#fbcc5729] rounded'>
        <Row gutter={24}>
          <Col span = {12} sm={24} xs={24} md={12} lg={12}>
              <p className='text-3xl font-bold'>Are you a Recruiter/Headhunter?</p>
              <p className='text-base my-3'>Get access to 2,000+ Headhunting job orders</p>
              <Button variant="outlined" className='normal-case border rounded-full'><Link to='#' className='hover:underline'>  Join us as A recruiter</Link></Button>
          </Col>
          <Col span = {12} sm={24} xs={24} md={12} lg={12}>
              <p className='text-3xl font-bold'>Are you an Employer?</p>
              <p className='text-base my-3'>Thousands of headhunters ready to help you</p>
              <Button variant="outlined" className='normal-case border rounded-full'><Link to='#' className='hover:underline'>  Join us as A Employer</Link></Button>
          </Col>
        </Row>
    </Card>
    </>
  )
}

export default RecruiterJAboutJoin