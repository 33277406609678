import {Box, Divider, Grid, Paper, Typography} from "@mui/material";

type BoardProps = {
    visible?: boolean;
    value?: React.ReactNode;
    name?: string;
    icon: React.ReactNode;
    color: string;
};

const Board = ({ visible, name, value, icon, color }: BoardProps) => {
    return visible ? (
        <Grid item xs={6} md={3}>
            <Paper style={{ backgroundColor: `#FFF9` }}>
                <div className='flex justify-between p-4 pb-8'>
                    <div style={{ color: `${color}DD` }}>
                        <Typography variant='h5'>{name}</Typography>
                        <Typography variant='h3'>{value ?? 0}</Typography>
                    </div>
                    <Box
                        sx={{
                            '& .MuiSvgIcon-root': {
                                fontSize: 30,
                                color: `${color}DD`,
                            },
                        }}
                    >
                        {icon}
                    </Box>
                </div>
                <Divider />
            </Paper>
        </Grid>
    ) : (
        <></>
    );
};

export default Board;