import { client } from './axios';

const api = '/api/v1/notice';

const getListNotificationUnread = (body: any): Promise<GetListNoticeType> =>
  client.post(api, { constructor: 'getListNotificationUnread', params_request: body });

const getListNotificationByUser = (body: any): Promise<GetListNoticeType> =>
    client.post(api, { constructor: 'getListNotificationByUser', params_request: body });

const updateNotification = (body: any): Promise<any> =>
  client.post(api, { constructor: 'updateNotification', params_request: body });
const removeNotification = (body: any): Promise<any> =>
  client.post(api, { constructor: 'removeNotification', params_request: body });

export default {
  getListNotificationUnread,
  getListNotificationByUser,
  updateNotification,
  removeNotification,
};
