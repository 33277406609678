import {
  AccessTimeOutlined,
  Check,
  DeleteOutline,
  MoreHorizOutlined,
  NotificationsActiveOutlined,
} from '@mui/icons-material';
import {
  Avatar,
  Badge,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  Tooltip,
} from '@mui/material';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { InfiniteScroll, PerfectScrollbar } from 'components';
import { useAnchor } from 'hooks';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { privateRoute } from 'routes';
import { noticeService, queryClient } from 'services';
import {noticeRoute, toRelative } from 'utils/converter';

const NotificationPopover = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isLoggedIn } = useSelector(profileSelector);

  const [anchorMenu, openMenu, onOpenMenu, onCloseMenu] = useAnchor();
  const [anchorItem, openItem, onOpenItem, onCloseItem] = useAnchor();

  const [chosenItem, setChosenItem] = useState<NoticeType>();

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['getListNotificationByUser'],
    ({ pageParam: page }) => noticeService.getListNotificationByUser({ page }),
    {
      getNextPageParam: (data, pages) => {
        if (data.data.length < 10) return undefined;
        return pages.length + 1;
      },
      keepPreviousData: true,
      enabled: isLoggedIn ?? false,
    },
  );

  const { data: { total = 0 } = {} } = useQuery(['getListNotificationUnread', { page: 0 }],
      () => noticeService.getListNotificationUnread({ page: 0 }),
      {
        enabled: isLoggedIn ?? false,
      }
  );

  const { mutate: updateNotification } = useMutation(noticeService.updateNotification, {
    onSuccess: (data, body) => {
      const { id, status }: { id: number; status: number } = body;
      queryClient.setQueryData(['getListNotificationByUser'], ({ pages, pageParams }: any) => ({
        pageParams,
        pages: pages.map((page: GetListNoticeType) => ({
          ...page,
          notifications: page.data.map((notice) => (notice.id === id ? { ...notice, status } : notice)),
        })),
      }));
      queryClient.invalidateQueries(['getListNotificationUnread']);
    },
  });

  const onRead = (item: NoticeType, status: number) => {
    if (item.isSystem) return;
    updateNotification({
      id: item.id,
      idJob: item.job?.id,
      idCv: item.resume?.id,
      type: item.type,
      status,
    });
  };

  const { mutate: removeNotification } = useMutation(noticeService.removeNotification, {
    onSuccess: (data, body) => {
      const { ids }: { ids: number[] } = body;
      queryClient.setQueryData(['getListNotificationByUser'], ({ pages, pageParams }: any) => ({
        pageParams,
        pages: pages.map((page: GetListNoticeType) => ({
          ...page,
          notifications: page.data.filter((notice) => !ids.includes(notice.id)),
        })),
      }));
    },
  });

  return (
    <>
      <Tooltip title={t('Notifications')}>
        <IconButton onClick={onOpenMenu}>
          <Badge badgeContent={total} color='error'>
            <NotificationsActiveOutlined />
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorMenu}
        open={Boolean(openMenu)}
        onClose={onCloseMenu}
        MenuListProps={{
          disablePadding: true,
          className: 'w-[480px] max-h-[600px] pt-[41px] pb-[36px] max-w-[75vw]',
        }}
        style={{ marginTop: 16 }}
      >
        <PerfectScrollbar>
          <InfiniteScroll useWindow={false} hasMore={hasNextPage} loadMore={() => fetchNextPage()}>
            {data?.pages.map(({ data: items }, index) => (
              <Fragment key={index}>
                {items.map((item) => (
                  <ListItem
                    key={item.id}
                    dense
                    button
                    divider
                    selected={item.status === 0}
                    className='hover:bg-black/10'
                    onClick={() => {
                      onRead(item, 1);
                      navigate(noticeRoute(item));
                      onCloseMenu();
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar src={item.job?.avatar}>
                        <Avatar src='/gap_logo.png' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      // primary={noticeFormat(item)}
                      primary={item.message}
                      secondary={
                        <div className='flex items-center gap-1'>
                          <AccessTimeOutlined fontSize='small' />
                          {toRelative(item.updateTime)}
                        </div>
                      }
                    />

                    {item.isSystem === 0 && (
                      <ListItemSecondaryAction>
                        <IconButton
                          edge='end'
                          size='small'
                          onClick={(event) => {
                            onOpenItem(event);
                            setChosenItem(item);
                          }}
                        >
                          <MoreHorizOutlined fontSize='small' />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))}
              </Fragment>
            ))}
            {data?.pages.length === 0 && (
              <ListItem>
                <ListItemText primary={t("You don't have any notifications")} />
              </ListItem>
            )}
          </InfiniteScroll>
        </PerfectScrollbar>

        <ListItem dense divider className='absolute top-0 left-0 right-0'>
          <ListItemText primary={t('Notifications')} primaryTypographyProps={{ variant: 'subtitle1' }} />
        </ListItem>
        <ListItemButton
          dense
          component={Link}
          to={privateRoute.notificationList.path}
          className='absolute bottom-0 left-0 right-0 bg-white'
          style={{ borderTop: '1px solid #0002' }}
          onClick={() => onCloseMenu()}
        >
          <ListItemText
            primary={t('View all')}
            primaryTypographyProps={{ color: 'primary' }}
            style={{ textAlign: 'center' }}
          />
        </ListItemButton>
      </Menu>

      <Menu anchorEl={anchorItem} open={openItem} onClose={onCloseItem}>
        <ListItemButton
          dense
          onClick={() => {
            onRead(chosenItem!, chosenItem?.status ^ 1);
            onCloseItem();
          }}
        >
          <Check className='mr-2' />
          <ListItemText primary={chosenItem?.status ? t('Mark as unread') : t('Mark as read')} />
        </ListItemButton>
        <ListItemButton
          dense
          onClick={() => {
            removeNotification({ ids: [chosenItem?.id] });
            onCloseItem();
          }}
        >
          <DeleteOutline className='mr-2' />
          <ListItemText primary={t('Remove notification')} />
        </ListItemButton>
      </Menu>
    </>
  );
};

export default NotificationPopover;
