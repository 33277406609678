import { client, clientDetail } from './axios';

const api = '/api/v1/job';

const apiPublic = '/api/v1/web/job';

const getListInfoJob = (body: any): Promise<GetListJobType> =>
  client.post(api, { constructor: 'getListInfoJob', ...body });

const getRecentlyJob = (): Promise<GetListJobType> => client.get(`${api}/recently`);

const getUrgentJob = (): Promise<GetListJobType> => client.get(`${api}/urgent`);

const getPublicJob = (body: any): Promise<GetListJobType> =>
    client.post(apiPublic, { constructor: 'searchJob', params_request: body });

const getInfoJob = (body: any): Promise<JobType> =>
    clientDetail.post(api, { constructor: 'getInfoJob', params_request: body });

const applyCvToJob = (body: any): Promise<any> =>
  client.post(api, { constructor: 'applyCvToJob', params_request: body });
const deleteApplyCvToJob = (body: any): Promise<any> =>
  client.post(api, { constructor: 'deleteApplyCvToJob', params_request: body });

const createJob = (body: any): Promise<any> => client.post(api, { constructor: 'createJob', params_request: body });
const deleteJob = (body: any): Promise<any> => client.post(api, { constructor: 'deleteJob', params_request: body });
const updateJob = (body: any): Promise<any> => client.post(api, { constructor: 'updateJob', params_request: body });

export default {
  getListInfoJob,
  getInfoJob,

  applyCvToJob,
  deleteApplyCvToJob,

  createJob,
  deleteJob,
  updateJob,

  getRecentlyJob,
  getUrgentJob,
  getPublicJob
};
