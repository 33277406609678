import { TYPE_EMPLOYEEE, TYPE_JOBS, TYPE_SEND_MAIL } from "./types";

export const TYPES_JOBS: Record<TYPE_JOBS, number> = {
    OFFLINE: 1, 
    ONLINE: 2,
    FULLTIMED: 3,
    PARTIME: 4,
}

export const TYPES_EMPLOYEE: Record<TYPE_EMPLOYEEE, number> = {
    EMPLOYEE1: 1,
    EMPLOYEE2: 2,
    EMPLOYEE3: 3,
}

export const TYPE_MAIL_SEND: Record<TYPE_SEND_MAIL, boolean> = {
    DISPATCH_NOW: true,
    QUEUE_DRAFT: false
}