import { LandingPage } from 'views/Auth';

const authRoute = {
  landing: {
    path: '/',
    url: '/gap',
    element: <LandingPage />,
  },
};

export default authRoute;
