import { useMutation, useQuery } from '@tanstack/react-query';
import { Card, Col, Modal, Row } from 'antd'
import { Alert } from 'components';
import { IGetListEmailData } from 'models/Email';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { mailService } from 'services';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, IconButton, Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { privateRoute } from 'routes';
import {decode} from 'html-entities';
import { AddOutlined } from '@mui/icons-material';
const TemplateList = () => {
    const { isSuper, isAdmin, isCompany } = useSelector(profileSelector);
    const { t } = useTranslation();

    const {
        data: { data = [] } = {},
        refetch
    } = useQuery(['getListEmail'], () => mailService.getListEmail(), {
        keepPreviousData: true,
    });
    const boldTitleStyle = {
        fontWeight: 'bold', // Add the bold font weight
    };
    const maxBodyHeightStyle = {
        height: '300px', // Set the maximum height to 500px
        overflow: 'auto',   // Enable scrolling if content exceeds 500px
    };
    const cardBoxShadowStyle = {
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add box shadow with the specified values
    };
    const { mutate: deleteTempEmail } = useMutation(mailService.deleteTempEmail, {
        onSuccess: () => {
            Alert.success({ message: t('Delete template successfully') });
            refetch();
        },
    });
    const onRemoveTemplate = (id: string) => {
        Modal.confirm({
            title: t('Confirmation'),
            content: t('Are you sure you want to delete this template?'),
            onOk: () => deleteTempEmail(id),
            okText: 'Delete',
            okType: 'danger',
        });
    }
    return (
        <>
            <Paper className='p-4 flex items-center flex-wrap mb-6'>
                <IconButton>
                    <AlternateEmailIcon />
                </IconButton>
                <Typography variant='h6'>Danh sách template</Typography>
                <div className='flex-1' />
                <div className='flex gap-4'>

                    {(isSuper || isAdmin || isCompany) && (
                        <Link
                            to={{
                                pathname: privateRoute.EmailTempCreate.path,
                            }}
                        >
                            <Button color='secondary' startIcon={<AddOutlined />}>
                                Thêm mới
                            </Button>
                        </Link>
                    )}
                </div>
            </Paper>

            <Paper className='p-6'>
                <Row gutter={24}>
                    {
                        data && data.length > 0 && data.map((item: IGetListEmailData, index: number) => {
                            return (
                                <Col span={6} xs = {24} sm = {12} md = {8} lg ={6} key={index}>
                                    <Card
                                        title={<span style={boldTitleStyle}>{item.name}</span>}
                                        style={cardBoxShadowStyle}
                                        bodyStyle={maxBodyHeightStyle}
                                        className='my-5 bg-[#f0f0f0] border overflow-y-auto'
                                         bordered={true}
                                        actions={[
                                            <Link to={{
                                                pathname: privateRoute.EmailTempUpdate.url(item.id),
                                            }}>
                                                <EditOutlined key="edit" />
                                            </Link>,
                                            <DeleteOutlined key="ellipsis" onClick={() => onRemoveTemplate(String(item.id))} />,
                                        ]}
                                    >
                                         <div dangerouslySetInnerHTML={{ __html:  decode(item.content) }}></div>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>

            </Paper>
        </>
    )
}

export default TemplateList