import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'reducers';

export type StorageState = {
  language?: string;
  darkmode?: string;
};

export const storageSlice = createSlice({
  name: 'storage',
  initialState: {} as StorageState,
  reducers: {
    updateStorage: (state: StorageState, { payload: { language, darkmode } }) => {
      if (language) localStorage.setItem('language', language);
      if (darkmode) localStorage.setItem('darkmode', darkmode);
      return { ...state, language, darkmode };
    },
  },
});

export const { updateStorage } = storageSlice.actions;

export const storageSelector = ({ storage }: RootState) => storage;

export default storageSlice.reducer;
