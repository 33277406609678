import {client, clientDetail} from './axios';

const api = '/api/v1/refer';

const getDetailRefer = (body: any): Promise<CvApplyType> =>
    clientDetail.post(api, { constructor: 'getDetailRefer', search: body });

const getListJobCvApplied = (body: any): Promise<GetListJobCvAppliedType> =>
  client.post(api, { constructor: 'getListJobCvApplied', search: body });

const getSystemSuggestCV = (body: any): Promise<GetReferralCvType> =>
    client.post(api, { constructor: 'getSystemSuggestCV', ...body });

const getSystemSuggestJob = (body: any): Promise<GetReferralJobType> =>
    client.post(api, { constructor: 'getSystemSuggestJob', ...body });

const getBonusHistory = (body: any): Promise<GetListJobCvAppliedType> =>
    client.post(api, { constructor: 'getBonusHistory', search: body });

export default {
  getDetailRefer,
  getListJobCvApplied,
  getBonusHistory,
  getSystemSuggestCV,
  getSystemSuggestJob
};
