import {
  AssignmentIndOutlined,
} from '@mui/icons-material';
import {
  Button,
  IconButton,
  Pagination,
  Paper,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import {Table, Tag} from 'antd';
import { Alert } from 'components';
import {useSearch, useTable} from 'hooks';
import { useTranslation } from 'react-i18next';
import { jobService, queryClient, referService} from 'services';
import {CANDIDATE_STATUS_TYPES} from 'utils/constants';
import {formatBonus, toFormat} from 'utils/converter';
import {CandidateRow, CandidateSearch} from "../Candidate/components";


type SystemReferListProps = {
  job: JobType;
};
const SystemSuggestionCV = ({ job } : SystemReferListProps) => {
  const { t } = useTranslation();

  const [dataSearch, onSearchChange] = useSearch({ idJob: job.id });
  const [dataSort, onTableChange] = useTable();

  const { data: { data: items = [], total = 0 } = {}, isFetching } = useQuery(
      ['getSystemSuggestCV', dataSearch, dataSort],
      () => referService.getSystemSuggestCV({ search: dataSearch, sort: dataSort }),
      { keepPreviousData: true },
  );

  const { mutate: applyCvToJob } = useMutation(jobService.applyCvToJob, {
    onSuccess: (data, body) => {
      Alert.success({ message: t('Refer candidate successfully') });
      queryClient.invalidateQueries(['getListJobCvApplied']);
      },
  });

  const TablePagination = () => (
      <Pagination
          count={Math.ceil(total / 10)}
          page={dataSearch.page + 1}
          onChange={(event, nextPage) => {
            onSearchChange({ page: nextPage - 1 });
          }}
      />
  );

  return (
      <div className='p-4'>
        <div className='flex items-center mb-6'>
          <IconButton>
            <AssignmentIndOutlined />
          </IconButton>
          <Typography variant='h6'>{t('System suggestion')}</Typography>

          <div className='flex-1' />

        </div>
        <CandidateSearch onSearch={onSearchChange} />

        <Paper className='mb-6'>
          <Table
              scroll={{ y: 480, x: 800 }}
              bordered={false}
              loading={isFetching}
              rowKey={(record) => record.id}
              dataSource={items}
              pagination={false}
              onChange={onTableChange}
              columns={[
                {
                  title: 'Name',
                  dataIndex: 'name',
                  width: 180,
                  sorter: true,
                  render: (_, record) => record.candidateName,
                },
                { title: 'Address', dataIndex: 'address', width: 120 },
                { title: 'Level', dataIndex: 'level', width: 120, sorter: true },
                {
                  title: 'Skill',
                  dataIndex: 'skill',
                  width: 180,
                  render: (_, record) =>
                      record.skill?.map((item, index) => (
                          <Tag key={index} className='mb-1'>
                            {item}
                          </Tag>
                      )),
                },
                {
                  title: 'Salary Expect',
                  dataIndex: 'salary',
                  width: 120,
                  sorter: true,
                  render: (_, record) => {
                      return record.salaryExpectMin===0 ?
                          (<span>{t('Negotiate')}</span>)
                          :(<span>{formatBonus(record.salaryExpectMin)} - {formatBonus(record.salaryExpectMax)}</span>) },
                },
                {
                  title: 'Status',
                  dataIndex: 'status',
                  width: 100,
                  sorter: true,
                  render: (_, record) => CANDIDATE_STATUS_TYPES.find((item) => item.code === record.status)?.name,
                },
                {
                  title: 'Created at',
                  dataIndex: 'time',
                  width: 120,
                  sorter: true,
                  render: (_, record) => toFormat(record.createTime),
                },
                {
                  dataIndex: '',
                  align: 'right',
                  width: 120,
                  render: (_, record) => (
                      <Button
                          variant='outlined'
                          color='secondary'
                          onClick={() => applyCvToJob({ idJob: job.id, idCv: record.id })}
                      >
                        {t('Refer')}
                      </Button>
                  ),
                },
              ]}
              expandable={{
                expandedRowRender: (record) => <CandidateRow candidate={record} />,
              }}
          />
        </Paper>

        {total > 0 && (
            <div className='flex justify-center'>
              <TablePagination />
            </div>
        )}
      </div>
  );
};

export default SystemSuggestionCV;
