import { CandidateCreate, CandidateList, CandidateUpdate, TalentPoolList, UnlockCandidates } from 'views/Candidate';
import { CompanyCreate, CompanyList, CompanyUpdate, CompanyView } from 'views/Company';
import { Home } from 'views/Home';
import { JobCreate, JobList, JobUpdate, JobView } from 'views/Job';
import { NotificationList } from 'views/Notification';
import { Profile } from 'views/Profile';
import { ReferList, ReferView } from 'views/Refer';
import { UserList, UserView } from 'views/User';
import { ReportView, LeaderBoardView } from 'views/Report';
import { PackageView } from "../views/Package";
import TalentView from "../views/Candidate/TalentView";
import { HistoryView } from "../views/History";
import NotFound from 'views/404';
import { EmailTempCreate, EmailTempList, EmailTempUpdate, EmailTempView } from 'views/EmailTemplates';
import { EmailCampaignCreate, EmailCampaignList, EmailCampaignUpdate, EmailCampaignView } from 'views/EmailCampaign';
import BreakNewsList from 'views/Ads/BreakNewsList';
import { ProfileLayout, SettingLayout } from 'layouts';
const privateRoute = {
  helpCenter: {
    path: '',
    element: '',
    url: 'http://help-center.gapsoftware.asia/'
  },
  home: {
    path: '/home',
    element: <Home />,
  },
  profile: {
    path: '/profile',
    element: <Profile />,
  },
  newProfile: {
    path: '/new-profile',
    element: <ProfileLayout />,
  },
  settingsAccount: {
    path: '/settings',
    element: <SettingLayout />,
  },
  package: {
    path: '/package',
    element: <PackageView />,
  },
  jobList: {
    path: '/jobs',
    element: <JobList />,
  },
  jobCreate: {
    path: '/jobs/create',
    element: <JobCreate />,
  },
  jobView: {
    path: '/jobs/:id',
    url: (id: any) => `/jobs/${id}`,
    element: <JobView />,
  },
  jobUpdate: {
    path: '/jobs/:id/edit',
    url: (id: any) => `/jobs/${id}/edit`,
    element: <JobUpdate />,
  },

  companyList: {
    path: '/companies',
    element: <CompanyList />,
  },
  companyCreate: {
    path: '/companies/create',
    element: <CompanyCreate />,
  },
  companyView: {
    path: '/companies/:id',
    url: (id: any) => `/companies/${id}`,
    element: <CompanyView />,
  },
  companyUpdate: {
    path: '/companies/:id/edit',
    url: (id: any) => `/companies/${id}/edit`,
    element: <CompanyUpdate />,
  },

  candidateList: {
    path: '/candidates',
    element: <CandidateList />,
  },

  marketPlace: {
    path: '/marketplace',
    element: <TalentPoolList />,
  },

  unlockCV: {
    path: '/marketplace/unlock',
    element: <UnlockCandidates />,
  },

  candidateCreate: {
    path: '/candidates/create',
    element: <CandidateCreate />,
  },
  candidateUpdate: {
    path: '/candidates/:id/edit',
    url: (id: any) => `/candidates/${id}/edit`,
    element: <CandidateUpdate />,
  },

  talentView: {
    path: '/marketplace/:id/view',
    url: (id: any) => `/marketplace/${id}/view`,
    element: <TalentView />,
  },

  referList: {
    path: '/referals',
    element: <ReferList />,
  },
  referView: {
    path: '/referals/:id',
    url: (id: any) => `/referals/${id}`,
    element: <ReferView />,
  },

  userList: {
    path: '/users',
    element: <UserList />,
  },
  userView: {
    path: '/users/:id',
    url: (id: any) => `/users/${id}`,
    element: <UserView />,
  },

  notificationList: {
    path: '/notfications',
    element: <NotificationList />,
  },

  reportView: {
    path: '/reports',
    element: <ReportView />,
  },

  leaderBoardView: {
    path: '/reports/leaderboard',
    element: <LeaderBoardView />,
  },
  history: {
    path: '/history',
    element: <HistoryView />,
  },
  notFound: {
    path: '/not-found',
    element: <NotFound />,
  },
  EmailTempUpdate: {
    path: '/email/templates/:id/edit',
    url: (id: any) => `/email/templates/${id}/edit`,
    element: <EmailTempUpdate />,
  },
  EmailTempCreate: {
    path: '/email/templates/create',
    element: <EmailTempCreate />,
  },
  EmailTempList: {
    path: '/email/templates/list',
    element: <EmailTempList />,
  },
  EmailTempView: {
    path: '/email/templates/:id',
    url: (id: any) => `/email/templates/${id}`,
    element: <EmailTempView />,
  },
  EmailCampaignList: {
    path: '/email/campaign/list',
    element: <EmailCampaignList />,
  },
  EmailCampaignCreate: {
    path: '/email/campaign/create',
    element: <EmailCampaignCreate />,
  },
  EmailCampaignUpdate: {
    path: '/email/campaign/:id/edit',
    url: (id: any) => `/email/campaign/${id}/edit`,
    element: <EmailCampaignUpdate />,
  },
  EmailCampaignView: {
    path: '/email/campaign/:id',
    url: (id: any) => `/email/campaign/${id}`,
    element: <EmailCampaignView />,
  },
  BeakNewList: {
    path: '/ads/break-news/list',
    element: <BreakNewsList />,
  },
};

export default privateRoute;
