import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'reducers';

export type ProfileState = {
  isLoggedIn?: boolean;
  accessToken?: string;
  userId?: string;
  username?: string;
  email?: string;
  avatarUrl?: string;

  isSuper?: boolean;
  isAdmin?: boolean;
  isUser?: boolean;
  isRecruit?: boolean;
  isCompany?: boolean;
  isCandide?: boolean;
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {} as ProfileState,
  reducers: {
    signIn: (state: ProfileState, { payload: { roleId, userType, ...profile } }) => {
      profile = { ...state, ...profile };

      if (roleId)
        Object.assign(profile, {
          isSuper: roleId === 1000,
          isAdmin: roleId === 1005,
          isUser: roleId === 1010,
          isRecruit: userType === 0,
          isCompany: userType === 1,
          isCandide: userType === 2,
        });

      localStorage.setItem('profile', JSON.stringify(profile));
      return { ...profile, isLoggedIn: true };
    },
    signOut: () => {
      localStorage.removeItem('profile');
      return { isLoggedIn: false };
    },
  },
});

export const { signIn, signOut } = profileSlice.actions;

export const profileSelector = ({ profile }: RootState) => profile;

export default profileSlice.reducer;
