import StompJS from 'stompjs';
import SockJS from 'sockjs-client';
import { QueryClient } from '@tanstack/react-query';
import { SOCKET_URL } from 'env';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: 60 * 1000,
      refetchOnMount: 'always',
    },
  },
});

const webSocket = new SockJS(`${SOCKET_URL}/ws`);
export const stompClient = StompJS.over(webSocket);
