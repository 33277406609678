import { Button } from '@mui/material'
import { Card } from 'antd'
import { LinkedInSvg } from 'assets/icons'
import React from 'react'

const RecruiterAboutContact = () => {
    return (
        <Card className='rounded'>
            <h5 className='text-lg font-bold'>
                Hỗ trợ thay đổi công việc:
            </h5>
            <p>Gửi hồ sơ của bạn đến headhunter này để giúp bạn tìm được cơ hội mới hoặc được thu nhập tốt hơn</p>
            <Button className='text-white normal-case w-full my-3'>Liên hệ với Nguyễn Văn Huy</Button>
            <Button variant="outlined" className='normal-case w-full border'><LinkedInSvg/><p className='ml-2 text-[#0073b1]'>LinkedIn</p></Button>
        </Card>
    )
}

export default RecruiterAboutContact