import { Autocomplete, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { companyService } from 'services';

type CompanyTextFieldProps = {
  initValue?: any;
  onChange: (...args: any) => void;
};

const CompanyTextField = ({ initValue, onChange }: CompanyTextFieldProps) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<any>(null);
  const [keyword, setKeyword] = useState('');

  const { data: { data = [] } = {} } = useQuery(['getNameCompanies', keyword], () =>
    companyService.getNameCompanies({ keyword }),
  );

  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  return (
    <Autocomplete
      freeSolo
      inputValue={keyword}
      onInputChange={(event, value) => {
        setKeyword(value);
      }}
      value={value}
      onChange={(event, value) => {
        setValue(value?.label ?? value);
        onChange(value?.label ?? value);
      }}
      options={data.map((company) => ({ id: company.id, label: company.name }))}
      isOptionEqualToValue={(option, value) => option === value || option.label === value}
      renderInput={(params) => <TextField {...params} label={t('Company')} InputLabelProps={{ shrink: true }} />}
    />
  );
};

export default CompanyTextField;
