import React from 'react'
import { useParams } from 'react-router-dom';

const TemplateView = () => {
    const { id } = useParams();
  return (
    <div>TemplateView {id}</div>
  )
}

export default TemplateView