import { Brightness4Outlined, Brightness5Outlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { disable as disableDarkMode, enable as enableDarkMode, setFetchMethod } from 'darkreader';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { storageSelector, updateStorage } from 'reducers/storage';

export const THEME_BARS = [
  { id: 1, name: 'Light Mode', code: 'light' },
  { id: 2, name: 'Dark Mode', code: 'dark' },
];

type LanguageBarProps = {
  init?: boolean;
};

const Darkmode = ({ init }: LanguageBarProps) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { darkmode } = useSelector(storageSelector);

  const handleChangeMode = useCallback(
    (isLight: boolean) => {
      if (isLight) {
        dispatch(updateStorage({ darkmode: THEME_BARS[0].code }));
        disableDarkMode();
      } else {
        dispatch(updateStorage({ darkmode: THEME_BARS[1].code }));
        setFetchMethod(window.fetch);
        enableDarkMode({ contrast: 90, sepia: 0 });
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (init) {
      const darkmode = localStorage.getItem('darkmode');
      const code = THEME_BARS.find((item) => item.code === darkmode)?.code ?? 'dark';
      const isLight = code === THEME_BARS[0].code;
      handleChangeMode(isLight);
    }
  }, [init, handleChangeMode, dispatch]);

  const themeChoose = THEME_BARS.find((item) => item.code === darkmode) ?? THEME_BARS[0];
  const isDark = themeChoose.code === THEME_BARS[1].code;

  return (
    <Tooltip title={t('Toggle Dark Mode')}>
      <IconButton onClick={() => handleChangeMode(isDark)}>
        {isDark ? <Brightness4Outlined /> : <Brightness5Outlined />}
      </IconButton>
    </Tooltip>
  );
};

export default Darkmode;
