import { AddOutlined, CheckOutlined, NavigateBeforeOutlined } from '@mui/icons-material';
import { IconButton, Paper, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, Form, Input, Row } from 'antd';
import { EmailEditor } from 'components';
import { decode } from 'html-entities';
import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import privateRoute from 'routes/privateRoute';
import { mailService } from 'services';
import { Alert } from 'components';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

function TemplateCreate() {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [content, setContent] = useState<string>('');
  const handleEditorChange: any = (content: string) => {
    setContent(content);
  };
  useQuery(['getInfoEmailDetail', id], () => mailService.getInfoEmailDetail(id!), {
    onSuccess: (res) => {
      const { data } = res;
      const { content } = data;
      if (content) setContent(decode(content));

      form.setFieldsValue({ ...data });
    },
    enabled: !!id,
  });
  const { mutate: updateTemplate, isLoading: isLoadingUpdate } = useMutation(
    id ? mailService.updateTempEmail : mailService.createTempEmail,
    {
      onSuccess: () => {
        if (id) {
          Alert.success({ message: t('Update email template successfully') });
          navigate(privateRoute.EmailTempList.path);
        } else {
          Alert.success({ message: t('Create email template successfully') });
          navigate(privateRoute.EmailTempList.path);
        }
      },
    },
  );
  const handleClickUpdate = () => {
    form.validateFields().then((values) => {
      updateTemplate({
        id: id!,
        ...values,
        // ...{ content },
      });
    });
  };
  const dynamicFields = [
    { shortcode: 'job_title', label: 'job title' },
    { shortcode: 'company_name', label: 'company name' },
    { shortcode: 'candidate_name', label: 'candidate name' },
  ];
  return (
    <>
      <div className='flex items-center mb-6'>
        <Link to={privateRoute.EmailTempList.path}>
          <IconButton>
            <NavigateBeforeOutlined />
          </IconButton>
        </Link>
        <Typography variant='h6'>{id ? t('Update template') : t('Create template')}</Typography>
      </div>
      <Row gutter={24}>
        <Col lg={18}>
          <Paper className='p-4'>
            <Form form={form} layout='vertical'>
              <Row gutter={24}>
                <Col lg={4}>
                  <Typography className='strong text-base font-bold'>Template Name</Typography>
                </Col>
                <Col lg={20} span={20}>
                  <Form.Item name='name' label={''} rules={[{ required: true, message: 'Template Name' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
              name='content'
              rules={[{ required: true, message: 'Vui lòng nhập nội dung' }]}
              >
                <EmailEditor 
                  onChange={handleEditorChange} 
                  dynamicFields={dynamicFields} 
                  value={content}
                />
              </Form.Item>
              <Row gutter={24}>
                <Col lg={4} span={4}></Col>
                <Col lg={20} span={20}>
                  <LoadingButton
                    variant='contained'
                    color='success'
                    startIcon={id ? <CheckOutlined /> : <AddOutlined />}
                    loading={isLoadingUpdate}
                    onClick={handleClickUpdate}
                  >
                    {id ? t('Update template') : t('Create template')}
                  </LoadingButton>
                </Col>
              </Row>
            </Form>
          </Paper>
        </Col>
      </Row>
    </>
  );
}

export default TemplateCreate;
