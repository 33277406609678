import { DeleteOutlined, NavigateBeforeOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Hidden, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Modal, Tag } from 'antd';
import { Alert } from 'components';
import { useTabs } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { privateRoute } from 'routes';
import { jobService } from 'services';
import { JOB_STATUS_TYPES } from 'utils/constants';
import { CompanyPaper } from 'views/Company/components';
import {JobDetail, JobReferral} from './components';
import SystemSuggestionCV from "../Refer/SystemSuggestionCV";

const JobView = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { id } = useParams();
  const { isSuper, isAdmin, isCompany, isRecruit } = useSelector(profileSelector);

  const { data: job } = useQuery(['getInfoJob', id], () => jobService.getInfoJob({ idJob: +id! }));

  const { mutate: deleteJob, isLoading: isLoadingDelete } = useMutation(jobService.deleteJob, {
    onSuccess: () => {
      Alert.success({ message: t('Delete job successfully') });

      navigate(privateRoute.jobList.path, { replace: true });
    },
  });

  const tabs = [{ code: 'detail', label: t('Detail'), component: <JobDetail data={job!} /> }].concat(
    isSuper || isAdmin || isCompany || isRecruit
      ? [
          { code: 'referral', label: t('Referral'), component: <JobReferral job={job!} /> }
        ]
      : [],
  ).concat(
      isSuper || isAdmin || isCompany
          ? [
            { code: 'system referral', label: t('System Referral'), component: <SystemSuggestionCV job={job!} /> },
          ]
          : [],
  );
  const [activeTab, onChangeTab] = useTabs(tabs);

  if (!job) return null;
  return (
    <>
      <div className='flex items-center flex-wrap mb-3'>
        <Link to={privateRoute.jobList.path}>
          <IconButton>
            <NavigateBeforeOutlined />
          </IconButton>
        </Link>
        <Typography variant='h6'>{job?.title}</Typography>

        <Tag color={JOB_STATUS_TYPES.find((item) => item.code === job?.status)?.color} className='ml-3'>
          {JOB_STATUS_TYPES.find((item) => item.code === job?.status)?.name}
        </Tag>

        <div className='flex-1' />
        {(isSuper || isAdmin || isCompany) && (
          <LoadingButton
            variant='outlined'
            color='error'
            loading={isLoadingDelete}
            startIcon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                title: t('Confirmation'),
                content: t('Are you sure you want to delete this job?'),
                onOk: () => deleteJob({ idJob: +id! }),
                okText: 'Delete',
                okType: 'danger',
              });
            }}
          >
            {t('Delete job')}
          </LoadingButton>
        )}
      </div>

      <div className='flex items-start'>
        <div className='flex-1'>
          <Tabs value={activeTab} onChange={onChangeTab}>
            {tabs.map((tab) => (
              <Tab key={tab.code} label={tab.label} value={tab.code} />
            ))}
          </Tabs>
          {tabs.map((tab) => (
            <div key={tab.code} hidden={tab.code !== activeTab} className='mt-4'>
              {tab.component}
            </div>
          ))}
        </div>

        <Hidden mdDown>
          <div
            hidden={activeTab !== 'detail'}
            style={{ width: 600, marginLeft: 24, paddingTop: 20, position: 'sticky', top: 64 + 24 - 20 }}
          >
            <Typography variant='h6' className='mb-1.5'>
              {t('Company info')}
            </Typography>
            <CompanyPaper id={job.company?.id!} />
          </div>
        </Hidden>

      </div>
    </>
  );
};

export default JobView;
