import { Col, Divider, Row } from 'antd';
import { ChatSticky, RecruiterJAboutJoin } from 'components';
import BreadCrumb from 'components/BreadCrumb'
import {RecruiterAboutContact, RecruiterAbout} from 'components';
import { AppHeader } from 'containers';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import authRoute from 'routes/authRoute';
import privateRoute from 'routes/privateRoute';
import { useQuery } from '@tanstack/react-query';
import { userService } from 'services';

const ProfileLayout = () => {
    const navigate = useNavigate();
    const { isLoggedIn, userId } = useSelector(profileSelector);
    const [userInfo, setUserInfo] = useState<any>()
    useEffect(() => {
        if (!isLoggedIn) {
            navigate(authRoute.landing.url, { replace: true });
        }
    }, [isLoggedIn, navigate]);
    const { data: user } = useQuery(['getUserInfo', userId], () => userService.getUserInfo({ userId }), {
        onSuccess: (user) => {
            setUserInfo(user)
        },
      });
    const config = [
        {
            text: 'Home',
            isLink: false,
            url: ''
        },
        {
            text: 'Recruiter',
            isLink: true,
            url: '/recruiter'
        },
        {
            text: userInfo?.username,
            isLink: true,
            url: `/recruiter/${userInfo?.username}`
        },
    ]
    return (
        <>
            <AppHeader />
            <div className='p-6 max-w-7xl mx-auto'>
                <Routes>
                    {Object.values(privateRoute).map((item) => (
                        <Route key={item.path} path={item.path} element={item.element} />
                    ))}
                    <Route path='*' element={<Navigate to={privateRoute.home.path} replace />} />
                </Routes>
                <BreadCrumb config={config} />
                <Row gutter={24}>
                    <Col span = {16} sm={24} xs={24} md={16} lg={16}>
                        <RecruiterAbout username = {userInfo?.username} type ={userInfo?.type} avatar ={userInfo?.avatarUrl }/>
                    </Col>
                    <Col span = {8} sm={24} xs={24} md={8} lg={8}>
                        <RecruiterAboutContact/>
                    </Col>
                </Row>
                <Divider dashed style={{ borderWidth: '2px 0 0' }} />
                <RecruiterJAboutJoin/>
            </div>
            <ChatSticky />

        </>
    )
}

export default ProfileLayout