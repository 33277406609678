import {
  AssignmentIndOutlined,
  DirectionsOutlined,
  MoreVert,
} from '@mui/icons-material';
import {
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Pagination,
  Paper,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Dropdown, Table, Tag } from 'antd';
import { useSearch, useTable } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { privateRoute } from 'routes';
import { candidateService } from 'services';
import { CANDIDATE_STATUS_TYPES } from 'utils/constants';
import { formatLevel, toFormat, toRelative } from 'utils/converter';
import { CandidateRow, CandidateSearch } from './components';
import marketplace from "../../services/marketplace";

const UnlockCandidates = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const {  isCandide } = useSelector(profileSelector);

  const [dataSearch, onSearchChange] = useSearch();
  const [dataSort, onTableChange] = useTable();

  const {
    data: { data: items = [], total = 0 } = {},
    isFetching,
  } = useQuery(
    ['getListUnlockTalent', dataSearch, dataSort],
    () => marketplace.getListUnlockTalent(dataSearch),
    {
      keepPreviousData: true,
      enabled: !isCandide,
    },
  );

  useQuery(['getInfoCv'], () => candidateService.getInfoCv({}), {
    enabled: isCandide,
    onSuccess: (candidate) => {
      if (candidate.id) {
        navigate(privateRoute.talentView.url(candidate.id), { replace: true });
      }
    },
  });

  const TablePagination = () => (
    <Pagination
      count={Math.ceil(total / 10)}
      page={dataSearch.page + 1}
      onChange={(event, nextPage) => {
        onSearchChange({ page: nextPage - 1 });
      }}
    />
  );

  return (
    <>
      <div className='flex items-center flex-wrap mb-6'>
        <IconButton>
          <AssignmentIndOutlined />
        </IconButton>
        <Typography variant='h6'>{t('Unlock Candidates')}</Typography>

      </div>
      <CandidateSearch onSearch={onSearchChange} />

      <Paper className='flex justify-between items-center flex-wrap p-4 mb-6'>
        <Typography variant='subtitle1' color='green'>
          {total} {t('candidates matched')}
        </Typography>
        <TablePagination />
      </Paper>

      <Paper className='mb-6'>
        <Table
          scroll={{ x: 800 }}
          bordered={false}
          loading={isFetching}
          rowKey={(record) => record.id}
          dataSource={items}
          pagination={false}
          onChange={onTableChange}

          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              width: 180,
              sorter: true,
              render: (_, record) => record.resumeInfo.candidateName,
            },
            {
              title: 'Level',
              dataIndex: 'level',
              width: 120,
              sorter: true,
              render: (_, record) => <Tag color={formatLevel(record.resumeInfo.level)}>{record.resumeInfo.level}</Tag>,
            },
            {
              title: 'Skill',
              dataIndex: 'skill',
              width: 180,
              render: (_, record) =>
                record.resumeInfo.skill?.map((item, index) => (
                  <Tag key={index} className='mb-1'>
                    {item}
                  </Tag>
                )),
            },
            {
              title: 'Calendar',
              dataIndex: 'calendar',
              width: 120,
              sorter: true,
              render: (_, record) => toFormat(record.resumeInfo.calendarReminder),
            },
            {
              title: 'Status',
              dataIndex: 'status',
              width: 100,
              sorter: true,
              render: (_, record) => {
                const status = CANDIDATE_STATUS_TYPES.find((item) => item.code === record.resumeInfo.status);
                return (
                  <Tag color={status?.color}>{status?.name}</Tag>
                );
              },
            },
            {
              title: 'Price',
              dataIndex: 'price',
              width: 30, render: (_, record) =>
                  <div className='flex'>
                      <strong>{record.price??0}</strong>
                      <span style={{
                          display: 'flex',
                          background: '#f6b300',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color:'#ff0',
                          borderRadius: '100rem',
                          border: '2px solid #e78b03',
                          width: 20,
                          height: 20,
                          marginLeft: 5
                      }}>G
                    </span>
                  </div>
            },
            {
              title: 'Created',
              dataIndex: 'createTime',
              width: 120,
              sorter: true,
              render: (_, record) => toRelative(record.createTime),
            },
            {
              dataIndex: '',
              align: 'right',
              width: 40,
              render: (_, record) => (
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <List component={Paper}>
                      <Link to={privateRoute.talentView.url(record.resumeInfo.id)}>
                        <ListItemButton>
                          <ListItemIcon>
                            <DirectionsOutlined color='secondary' />
                          </ListItemIcon>
                          <ListItemText primary={t('View detail')} className='text-black/80' />
                        </ListItemButton>
                      </Link>
                    </List>
                  }
                >
                  <IconButton size='small'>
                    <MoreVert />
                  </IconButton>
                </Dropdown>
              ),
            },
          ]}
          expandable={{
            expandedRowRender: (record) => <CandidateRow candidate={record.resumeInfo} />,
          }}
        />
      </Paper>

      {total > 0 && (
        <div className='flex justify-center'>
          <TablePagination />
        </div>
      )}
    </>
  );
};

export default UnlockCandidates;
