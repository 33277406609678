import {
  AttachMoneyRounded,
  BoltOutlined,
  CodeRounded,
  Email,
  Facebook,
  GroupsOutlined,
  HowToRegOutlined,
  Phone,
  PublicOutlined,
  Savings,
  Stream,
  ThumbUpRounded,
  TimerOutlined,
  VerifiedUserRounded,
  WorkOutline,
} from '@mui/icons-material';
import {AppBar, Avatar, Button, Container, Dialog, Grid, Paper, Toolbar, Typography} from '@mui/material';
import { useState } from 'react';
import { LoginForm, RegisterForm } from '.';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import {privateRoute, publicRoute} from 'routes';
import { Link } from 'react-router-dom';
import CarouselCompany from "../Common/CarouselCompany";
import JobPublic from "../Job/components/JobPublic";

const LandingPage = () => {
  const [isOpenLogin, setOpenLogin] = useState(false);
  const [isOpenRegister, setOpenRegister] = useState(false);

  return (
    <>
      <AppBar square position='sticky' elevation={0} className='bg-white/90 py-2'>
        <Toolbar>
          <Container maxWidth='lg' className='flex justify-between'>
            <Link
                  to={{
                    pathname: '/gap'
                  }}
            >
              <Avatar src='/gap-logo.png' className='w-[90px] h-[90px]' />
            </Link>
            <div className='flex items-center gap-4'>
              <Button variant='text' size='large' color='inherit' onClick={() => setOpenLogin(true)}>
                Login
              </Button>
              <Button variant='contained' size='large' color='info' onClick={() => setOpenRegister(true)}>
                Sign Up
              </Button>
            </div>
          </Container>
        </Toolbar>

        <Dialog fullWidth maxWidth='xs' open={isOpenLogin} onClose={() => setOpenLogin(false)}>
          <LoginForm onClose={() => setOpenLogin(false)} />
        </Dialog>
        <Dialog fullWidth maxWidth='xs' open={isOpenRegister} onClose={() => setOpenRegister(false)}>
          <RegisterForm onClose={() => setOpenRegister(false)} />
        </Dialog>
      </AppBar>

      <Container maxWidth='lg' className='py-10'>
        <div className='flex items-center gap-10'>
          <div className='flex flex-col gap-6 w-[480px]'>
            <Typography variant='h3' fontWeight={600}>
              Nền tảng tuyển dụng 4.0
            </Typography>
            <Typography>
              Trong thời đại kinh tế 4.0, với sự phát triển nhanh chóng của các doanh nghiệp thì vấn đề nhân sự luôn
              được các doanh nghiệp quan tâm hàng đầu. GAP SOFT nhận thức rõ vấn đề đó của các doanh nghiệp. Chính vì
              vậy, cùng với sự đóng góp của các chuyên gia trong lĩnh vực nhân sự, GAP SOFT tạo ra sản phẩm{' '}
              <span className='font-bold text-blue-500'>"Nền tảng tuyển dụng 4.0"</span> hỗ trợ các doanh nghiệp tối ưu
              hóa quy trình tuyển dụng nhân sự.
            </Typography>
          </div>
          <div className='flex-1'>
            <img src={require('assets/images/landing.png')} />
          </div>
        </div>

        <Typography variant='h3' fontWeight={600} className='mb-6'>
          Cơ hội việc làm
        </Typography>
        <JobPublic/>

        <Typography variant='h6' color='textSecondary' className='mb-6'>
          Join companies that trust Gap Soft to hire software developers
        </Typography>
        <CarouselCompany/>

      </Container>

      <div
        style={{ background: `url(${require('assets/images/world.png')}) no-repeat center / cover` }}
        className='min-h-[600px] flex items-center text-white my-10'
      >
        <Container className='py-20'>
          <Typography variant='h3' fontWeight={700} className='text-center'>
            Những con số nổi bật
          </Typography>
          <Grid container gap={4} className='justify-center mt-20'>
            {[
              { icon: <PublicOutlined fontSize='large' />, content: '30+ Doanh nghiệp sử dụng nền tảng' },
              { icon: <HowToRegOutlined fontSize='large' />, content: '100+ Cộng tác viên tuyển dụng' },
              { icon: <GroupsOutlined fontSize='large' />, content: '2000+ Ứng viên tham gia ứng tuyển' },
            ].map((item, index) => (
              <Grid item md={3} key={index} className='bg-white/5 rounded-[20px] p-6'>
                <div className='flex items-center gap-3'>
                  <div className='bg-white/10 rounded-[12px] p-3'>{item.icon}</div>
                  <Typography variant='h6'>{item.content}</Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>

      <Container maxWidth='lg' className='py-20'>
        <div className='flex items-center gap-10'>
          <div className='flex-1'>
            <img src={require('assets/images/feature.png')} />
          </div>
          <div className='flex flex-col gap-6 w-[600px]'>
            <Typography variant='h4' fontWeight={700}>
              Một số tính năng vượt trội
            </Typography>
            {[
              { icon: <PublicOutlined fontSize='large' />, content: 'Tối ưu hóa quá trình tuyển dụng' },
              { icon: <HowToRegOutlined fontSize='large' />, content: 'Đăng tin tuyển dụng hiệu quả' },
              { icon: <GroupsOutlined fontSize='large' />, content: 'Tự động gợi ý hồ sơ ứng viên phù hợp' },
            ].map((item, index) => (
              <div key={index} className='flex items-center gap-3'>
                <div className='bg-blue-100 text-blue-500 rounded-[12px] p-2'>{item.icon}</div>
                <Typography variant='h6'>{item.content}</Typography>
              </div>
            ))}
          </div>
        </div>
      </Container>

      <Container className='py-20'>
        <Typography variant='h4' fontWeight={700} className='text-center'>
          Tại sao doanh nghiệp lựa chọn Gap Soft
        </Typography>
        <Grid container gap={6} className='justify-center mt-20'>
          <Grid item md={1} />
          {[
            {
              icon: <BoltOutlined fontSize='large' />,
              for: 'Speed',
              count: '5 Ngày',
              content: (
                <>
                  Hoàn thành hầu hết các <span className='text-primary-main'>vai trò</span> đôi khi trong cùng một ngày.
                </>
              ),
            },
            {
              icon: <TimerOutlined fontSize='large' />,
              for: 'Time Saved',
              count: '50+ Hours',
              content: (
                <>
                  Tiết kiệm thời gian cho <span className='text-primary-main'>mỗi lần tham gia</span> phỏng vấn.
                </>
              ),
            },
            {
              icon: <ThumbUpRounded fontSize='large' />,
              for: 'Retention',
              count: '97%',
              content: (
                <>
                  Tỷ lệ tương tác <span className='text-primary-main'>success</span>.
                </>
              ),
            },
          ].map((item, index) => (
            <Grid item md={3} key={index}>
              <div className='flex items-center gap-2'>
                <span className='bg-yellow-100 text-yellow-500 rounded-[12px] p-2'>{item.icon}</span>
                <Typography variant='h6' fontWeight={700}>
                  {item.for}
                </Typography>
              </div>
              <Typography variant='h4' color='primary' fontWeight={700} className='my-3'>
                {item.count}
              </Typography>
              <Typography variant='h6'>{item.content}</Typography>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Container maxWidth='lg' className='py-20'>
        <div className='flex items-center gap-10'>
          <div className='flex flex-col gap-6 w-[600px]'>
            <Typography variant='h4' fontWeight={700}>
              Tối ưu chi phí - Tăng cao chất lượng
            </Typography>
            {[
              {
                icon: <AttachMoneyRounded fontSize='large' />,
                title: 'Sự cộng tác của các headhunter giúp giảm thiểu chi phí',
                content: 'Giúp giảm thiểu lên đến 50% chi phí tuyển nhân sự chất lượng.',
              },
              {
                icon: <CodeRounded fontSize='large' />,
                title: '100+ ngôn ngữ hiện hành',
                content:
                  'Tuyển dụng React, Node, Python, Angular, Swift, React Native, Android, Java, Rails, Golang, DevOps, ML, Data Engineers, và hơn thế nữa.',
              },
              {
                icon: <VerifiedUserRounded fontSize='large' />,
                title: 'Giảm rủi ro',
                content: 'nếu bạn quyết định dừng lại trong vòng hai tuần, bạn sẽ không cần lo ngại.',
              },
            ].map((item, index) => (
              <div key={index} className='flex items-start gap-3'>
                <div className='bg-green-100 text-green-500 rounded-[12px] p-2'>{item.icon}</div>
                <div>
                  <Typography variant='h5' fontWeight={700}>
                    {item.title}
                  </Typography>
                  <Typography variant='h6'>{item.content}</Typography>
                </div>
              </div>
            ))}
          </div>
          <div className='flex-1'>
            <img src={require('assets/images/skills.png')} />
          </div>
        </div>
      </Container>

      <Container maxWidth='lg' className='py-20'>
        <div className='flex items-center gap-10'>
          <div className='flex-1'>
            <img src={require('assets/images/steps.png')} />
          </div>
          <div className='flex flex-col gap-6 w-[600px]'>
            <Typography variant='h4' fontWeight={700}>
              Tại sao những lập trình viên giỏi nhất tham gia Gap Soft?
            </Typography>
            {[
              { icon: <WorkOutline fontSize='large' />, content: 'Sở hữu mạng lưới quan hệ rộng lớn các nhân sự cao cấp' },
              { icon: <Stream fontSize='large' />, content: 'Tham gia vào cộng đồng Gap-er' },
              { icon: <Savings fontSize='large' />, content: 'Có nhiều “đơn đặt hàng” từ các nhà tuyển dụng và quản lý được số lượng lớn các ứng viên' },
            ].map((item, index) => (
              <div key={index} className='flex items-center gap-3'>
                <div className='bg-orange-100 text-orange-500 rounded-[12px] p-2'>{item.icon}</div>
                <Typography variant='h6'>{item.content}</Typography>
              </div>
            ))}
          </div>
        </div>
      </Container>

      <Container maxWidth='lg' className='py-20'>
        <div className='text-center'>
          <Typography variant='h4' fontWeight={700}>
            Nhận xét từ nhà tuyển dụng
          </Typography>
          <Typography variant='h6' color='textSecondary'>
            "Chúng tôi đánh giá cao Gap Soft"
          </Typography>
        </div>
        <Grid container gap={4} className='justify-center mt-10'>
          {[
            {
              avatar: require('assets/users/ntd1.png'),
              name: 'NGUYỄN THỊ NGỌC',
              position: 'Nhà tuyển dụng',
              comment:
                'Mình biết đến Gap Soft khi mới bắt đầu làm Headhunt. Thực sự, tuyển dụng kỹ sư công nghệ thông tin rất khó với một người mới vào nghề như mình. Nhờ Gap Soft hỗ trợ, mình đã biết cách tìm kiếm CV và tư vấn ứng viên. Đến nay, ngoài thu nhập tại công ty, mình có thêm thu nhập 25-30 triệu hàng tháng từ Gap Soft.',
            },
            {
              avatar: require('assets/users/ntd2.png'),
              name: 'LÊ HẢI LINH',
              position: 'Nhà tuyển dụng',
              comment:
                'Gap Soft là bên thứ 3 duy nhất , tôi tin tưởng để gửi CV ứng viên với số lượng lớn. Họ có đội ngũ tư vấn chuyên nghiệp hỗ trợ nhiệt tình. Công việc của mình chỉ là tìm kiếm ứng viên công việc còn lại đã có Gap Soft hỗ trợ.',
            },
            {
              avatar: require('assets/users/ntd3.png'),
              name: 'PHẠM HOÀNG HOA',
              position: 'Nhà tuyển dụng',
              comment:
                'Gap Soft là 1 trong những đơn vị cung cấp dịch vụ Headhunt ứng viên công nghệ thông tin chất lượng nhất. Họ có đội ngũ nhà tuyển dụng với "Hệ thống ứng viên" cực rộng ở Việt nam và Nhật Bản.',
            },
          ].map((item, index) => (
            <Grid item md={3.5} key={index} className='flex flex-col items-center'>
              <Avatar variant='circular' src={item.avatar} className='w-[120px] h-[120px] mb-2' />
              <Typography variant='h6'>{item.name}</Typography>
              <Typography fontWeight={700} color='textSecondary'>
                {item.position}
              </Typography>
              <Typography className='mt-2'>{item.comment}</Typography>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Container maxWidth='lg' className='py-20'>

        <div className='text-center'>
          <Typography variant='h4' fontWeight={700}>
            Các công ty đánh giá GAP SOFT
          </Typography>
        </div>

        <Grid container gap={4} className='mt-10'>
          {[
            {
              icon: require('assets/icons/crypto_exchange_platform.svg').default,
              name: 'Program Manager of one of the world\'s largest crypto exchange platforms',
              comment:
                '2023 là năm mà hàng loạt nền tảng tuyển dụng ra đời, nhưng chúng tôi lựa chọn Gap Soft bởi chất lượng của các sản phẩm và dịch vụ mà công ty đã cung cấp cho chúng tôi. Các sản phẩm và giải pháp công nghệ thông tin của công ty đều đạt đến sự chuyên nghiệp và đáp ứng các tiêu chuẩn cao nhất. Chúng đã giúp chúng tôi nâng cao hiệu suất và tối ưu hóa quy trình làm việc của chúng tôi.'
            },
            {
              icon: require('assets/icons/financial_services.svg').default,
              name: 'Director of engineering of a US-based, multimillion-dollar finance company',
              comment:
                'Tôi đề cao quy trình làm việc và sự tổ chức của Gap Soft. Công ty đã thiết lập các quy trình linh hoạt và hiệu quả để đảm bảo rằng các dự án được triển khai một cách suôn sẻ. Sự chuyên nghiệp trong việc quản lý dự án và cam kết về thời gian và chất lượng đã gây ấn tượng mạnh với chúng tôi. Chúng tôi tin tưởng rằng Gap có khả năng đáp ứng các dự án phức tạp.'
            },
            {
              icon: require('assets/icons/software_solutions.svg').default,
              name: 'HR Manager of a multimillion-dollar software solution provider',
              comment:
                'Tôi muốn đề cao tinh thần hướng tới sự phát triển của Gap Soft. Dù công ty còn non trẻ nhưng đã có một tầm nhìn rõ ràng và mục tiêu cụ thể để trở thành một nền tảng tuyển dụng hàng đầu trong ngành CNTT. Sự cam kết và nỗ lực không ngừng nghỉ của Gap Soft để đạt được mục tiêu này đã gây ấn tượng mạnh với chúng tôi. Tôi nghĩ sẽ thật thú vị nếu có startup này cùng đồng hành.'
            },
          ].map((item, index) => (
              <Paper className='flex-1 leading-8 border rounded-lg'>
                <div className='flex justify-end' style={{backgroundColor:'#fbc02d'}}>
                  <Button>
                    <img alt="quote" src={require('assets/icons/quote.svg').default} />
                  </Button>

                </div>

                <div className='flex-1 p-3'
                     style={{
                       whiteSpace: 'pre-wrap',
                       fontSize: 14,
                       marginLeft: 10,
                       marginTop:20,
                       marginBottom:20
                     }}
                >
                  {item.comment}
                </div>

                <div className='flex-1 flex justify-between p-3'>
                  <span>
                    <img src={item.icon} />
                  </span>
                  <div className='flex-1 ml-3'>{item.name}</div>
                </div>
              </Paper>
          ))}
        </Grid>
      </Container>

      <AppBar square position='static' elevation={0} className='bg-black/90 text-white py-10'>
        <Toolbar>
          <Container maxWidth='xl' className='flex justify-between'>
            <div className='flex items-center gap-3'>
              <Avatar src='/gap-logo.png' className='w-[80px] h-[80px]' />
              <div className='text-white/80'>
                <Typography variant='body2'>© 2022 Gap Soft.</Typography>
                <Typography variant='body2'>All Rights Reserved.</Typography>
              </div>
            </div>

            <div className='flex flex-col items-start gap-2'>
              <Typography>About Us</Typography>
              <Link className='flex items-center gap-2 text-white/80 hover:text-primary-main'
                    to={{
                      pathname: publicRoute.privacyPolicy.url
                  }}
              >Privacy policy
              </Link>
              <Link className='flex items-center gap-2 text-white/80 hover:text-primary-main'
                    to={{
                      pathname: publicRoute.termsOfService.url
                    }}
              >Terms of service
              </Link>
              <a
                  href={privateRoute.helpCenter.url}
                  target='_blank'
                  className='flex items-center gap-2 text-white/80 hover:text-primary-main'
              >Help center
              </a>

              <Link className='flex items-center gap-2 text-white/80 hover:text-primary-main'
                    to={{
                      pathname: publicRoute.termsOfService.url
                    }}
              >Blog
              </Link>
            </div>

            <div className='flex flex-col items-start gap-2'>
              <Typography>Contact</Typography>
              <a
                  href='https://www.facebook.com/gapsofts'
                  target='_blank'
                  className='flex items-center gap-2 text-white/80 hover:text-primary-main'
              >
                <Facebook /> Facebook
              </a>
              <a
                href='mailto:admin@gapsoftware.asia'
                target='_blank'
                className='flex items-center gap-2 text-white/80 hover:text-primary-main'
              >
                <Email /> admin@gapsoftware.asia
              </a>
              <a
                href='tel:0962304905'
                target='_blank'
                className='flex items-center gap-2 text-white/80 hover:text-primary-main'
              >
                <Phone /> 0962 304 905
              </a>
              <a
                href='tel:07090262032'
                target='_blank'
                className='flex items-center gap-2 text-white/80 hover:text-primary-main'
              >
                <Phone /> 070 9026 2032
              </a>
            </div>
          </Container>
        </Toolbar>
      </AppBar>

      <MessengerCustomerChat
          pageId="115750871439621"
          appId=""
          language="vi_VN"
          greetingDialogDisplay="hide"
      />
    </>
  );
};

export default LandingPage;
