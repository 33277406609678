import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useNotification } from 'hooks';

export const appTheme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1: 'div',
          body2: 'div',
          inherit: 'div',
        },
      },
      styleOverrides: {
        subtitle1: { fontSize: 16, fontWeight: 500, lineHeight: 1.5 },
        subtitle2: { fontSize: 14, fontWeight: 500, lineHeight: 1.43 },
        body1: { fontSize: 16 },
        body2: { fontSize: 14 },
        caption: { fontSize: 12 },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
      },
    },
    MuiAvatar: {
      defaultProps: {
        variant: 'rounded',
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiPagination: {
      defaultProps: {
        shape: 'rounded',
        variant: 'outlined',
        color: 'secondary',
        siblingCount: 0,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'medium',
        InputLabelProps: { shrink: true },
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },
    },
  },
  // typography: {
  //   fontFamily: `Mulish`,
  // },
  palette: {
    primary: {
      main: '#fbc02d',
    },
    secondary: {
      main: '#1890ff',
    },
  },
});

const Theme = ({ children }: any) => {
  useNotification();

  return (
    <ThemeProvider theme={responsiveFontSizes(appTheme)}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>{children}</LocalizationProvider>
    </ThemeProvider>
  );
};

export default Theme;
