import { CircularProgress, CircularProgressProps } from '@mui/material';

type LoadingIconProps = CircularProgressProps & {
  visible?: boolean;
  size?: number;
  icon?: React.ReactNode;
};

const LoadingIcon = ({ visible, size = 24, icon, ...props }: LoadingIconProps) => {
  return visible ? <CircularProgress {...props} size={size} color='inherit' /> : <>{icon}</>;
};

export default LoadingIcon;
