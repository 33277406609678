import {Typography, Container} from "@mui/material";
import React from "react";
import Footer from "./Footer";

const TermsOfService = () => {
  return (
      <>
          <Container  maxWidth='lg' className='terms-of-service flex items-center flex-wrap mb-10'>
              <p>
                  <i>Cập nhật mới nhất: ngày 01 tháng 6 năm 2023</i>
                  <br />
                  <i>Ngày có hiệu lực: Ngày 01 tháng 6 năm 2023</i>
              </p>
              <Typography variant='body1' className='mt-2'>
                  Website: http://hr.gapsoftware.asia/ (Sau đây gọi tắt là “Trang GAP HR”) được phát triển, sở hữu và vận hành bởi{' '}
                  <span className="text-danger">Công ty TNHH Phần mềm GAP</span> (Sau đây gọi tắt là “GAP”).
              </Typography>
              <Typography variant='body1'>
                  Các cá nhân, tổ chức sử dụng các dịch vụ trên Trang GAP HR (sau đây có thể gọi là “bạn” hoặc “Khách hàng” hoặc
                  “Người Dùng”) được yêu cầu phải đọc kỹ, hiểu, đồng ý và chịu sự ràng buộc với các quy định được mô tả trong Điều
                  khoản và Điều kiện sử dụng đối với người sử dụng dịch vụ của GAP (Sau đây gọi là “Điều Khoản và Điều Kiện”)
                  sau đây:
              </Typography>

              <Typography variant='body1' component="div">
                  <br/>
                  <strong>Phạm vi sử dụng</strong>
                  <br/>
                  Khách hàng đồng ý sử dụng dịch vụ của GAP HR để tìm và tuyển ứng viên. GAP HR đồng ý sẽ thực hiện việc tư vấn, hỗ trợ khách hàng kết nối và nhận hồ sơ từ các chuyên viên tuyển dụng.
              </Typography>
              <Typography variant='body1' component="div">
                  <br/>
                  <strong>Bản nâng cấp, chỉnh sửa</strong>
                  <br/>
                  Chúng tôi có thể cập nhật Điều Khoản và Điều Kiện này bất cứ lúc nào và sẽ tiến hành thông báo qua trang chủ
                  (hr.gapsoftware.asia), ứng dụng trên điện thoại di động của chúng tôi hoặc thông báo về việc cập nhật Điều Khoản và Điều
                  Kiện khi bạn đăng nhập hệ thống. Bạn có thể xem thời điểm Điều Khoản và Điều Kiện này được cập nhật mới nhất bằng
                  cách kiểm tra ngày “cập nhật mới nhất” được hiển thị ở đầu trang này.
                  <br/>
                  Những thay đổi, chỉnh sửa, bổ sung hoặc xóa bỏ sẽ có hiệu lực theo “ngày có hiệu lực” của Điều Khoản và Điều Kiện
                  được đăng tải trên trang chủ, hoặc các ứng dụng trên điện thoại của GAP, bằng việc chấp nhận thông báo và
                  tiếp tục sử dụng dịch vụ sau khi Điều Khoản và Điều Kiện có hiệu lực, bạn đã đồng ý với các thay đổi của chúng
                  tôi.
                  <br/>
              </Typography>
              <Typography variant='body1' component="div">
                  <br/>
                  <strong>VỀ ĐIỀU KHOẢN SỬ DỤNG DỊCH VỤ</strong>
                  <br/>
                  Trang GAP HR là một trang web toàn cầu trên Internet được thiết kế để cho phép người dùng đăng công việc và/hoặc xem các công việc được đăng bởi những người dùng khác hoặc tương tác với những người dùng đó.
                  Trang GAP HR chứa hoặc có thể chứa thông tin, liên lạc, ý kiến, văn bản, đồ họa, liên kết, ảnh, âm thanh, video, phần mềm, ảnh, nhạc, âm thanh và các tài liệu và dữ liệu khác (gọi chung là "Nội dung") được định dạng, tổ chức và được thu thập dưới nhiều hình thức mà Người dùng thường có thể truy cập, bao gồm các thư mục và cơ sở dữ liệu cũng như các khu vực của Trang GAP HR mà Người dùng có thể sửa đổi, chẳng hạn như đăng công việc, tải lên các tệp đa phương tiện, đăng ký hồ sơ người dùng.

                  Để sử dụng đầy đủ các dịch vụ được cung cấp trên Trang web, bạn cần đăng ký để tạo tài khoản và cung cấp cho Trang GAP HR một số thông tin cá nhân bao gồm nhưng không giới hạn ở địa chỉ email của bạn để liên lạc thêm giữa bạn và Trang GAP HR.
                  Bằng cách đăng ký, bạn đồng ý nhận bản tin, email và các thông tin liên lạc khác về các sản phẩm hoặc dịch vụ của chúng tôi. Bất cứ khi nào bạn không muốn nhận chúng, bạn có thể thông báo cho chúng tôi ngay lập tức bằng cách kiểm tra chức năng hủy đăng ký có sẵn trên Trang GAP HR hoặc ở cuối email của chúng tôi.
                  Mọi thông tin cá nhân của bạn mà bạn gửi sẽ được xử lý theo Chính sách bảo mật của Trang GAP HR.

                  Bạn có thể đặt hàng các dịch vụ hoặc sản phẩm theo báo giá được cung cấp trên Trang GAP HR và các đơn đặt hàng của bạn phải được Trang web chấp nhận toàn bộ hoặc một phần đơn đặt hàng của bạn.
                  Sự chấp nhận của Trang GAP HR sẽ có hiệu lực sau khi khoản thanh toán của bạn đã được thực hiện hoặc được xác định.
              </Typography>
              <Typography variant='body1' component="div">
                  <br/>
                  <strong>VỀ ĐIỀU KHOẢN THANH TOÁN</strong>
                  <br/>
                  <strong>Phí sử dụng nền tảng</strong>
                  <br/>
                  Khách hàng đồng ý sử dụng phí sử dụng nền tảng cho GAP HR thông qua việc nạp credit để sử dụng các dịch vụ cao cấp của GAP.
                  <br/>
                  <strong>Thời hạn thanh toán</strong>
                  <br/>
                  Sau khi khách hàng đã lựa chọn được các gói nạp Credit phù hợp, màn hình giao dịch hiện ra, khách hàng thực hiện các thao tác như trong phần hướng dẫn. Sau 5-10 phút, số coin khách hàng đã thanh toán sẽ hiển thị và khách hàng có thể mở để sử dụng thêm các dịch vụ tại GAP HR
                  <br/>
                  <strong>Phương thức thanh toán</strong>
                  <br/>
                  Phí sử dụng dịch vụ đồng ý trên hóa đơn sẽ được thanh toán cho GAP HR tới thông tin tài khoản được cung cấp bởi GAP HR.
                  <br/>
                  <strong>Sử dụng dịch vụ</strong>
                  <br/>
                  Sau khi bạn đã thanh toán Phí dịch vụ và/hoặc kích hoạt Dịch vụ đã mua, chúng tôi sẽ không chấp nhận bất kỳ yêu cầu chấm dứt hoặc hủy Dịch vụ đã mua, hoàn trả Phí dịch vụ hoặc thay đổi bất kỳ dịch vụ nào. Bạn sẽ tạo tài khoản của riêng mình với tên người dùng và mật khẩu để sử dụng các dịch vụ trên Website GAP HR. Bạn có quyền đăng quảng cáo tuyển dụng của mình hoặc đăng một liên kết trên Trang GAP HR đến quảng cáo tuyển dụng trên trang web của bạn. Bạn có trách nhiệm đảm bảo tính chính xác và hợp pháp của tất cả các nội dung này. Bạn phải kích hoạt Dịch vụ đã mua trước ngày hết hạn. Bạn không thể kích hoạt bất kỳ Dịch vụ không sử dụng nào khi chúng đã hết hạn.
              </Typography>
              <Typography variant='body1' component="div">
                  <br/>
                  <strong>GIỚI HẠN TRÁCH NHIỆM PHÁP LÝ</strong>
                  <br/>
                  Trong bất kỳ trường hợp nào, GAP HR, hoặc các chi nhánh, hoặc các giám đốc, nhân viên, những người làm thuê, các đại lý, các cộng tác viên, hoặc những người cấp phép không chịu trách nhiệm pháp lý với mọi thiệt hại ngẫu nhiên.
                  <br/>
                  Khách Hàng đồng ý và xác nhận rằng, bằng việc đồng ý với Hợp Đồng này, Khách Hàng miễn trừ GAP HR khỏi bất kỳ nghĩa vụ nào bao gồm nhưng không giới hạn (trách nhiệm hoàn trả các khoản phí mà GAP HR nhận được từ thông tin mà Khách Hàng yêu cầu GAP HR đăng tải lên hệ thống...) do Khách Hàng vi phạm các Điều khoản của Hợp Đồng này. Như vậy, đối với mọi yêu cầu từ bất kỳ bên thứ ba nào do hành vi vi phạm của Khách Hàng, Khách Hàng sẽ là người chịu trách nhiệm chính, GAP HR không phải chịu bất kỳ trách nhiệm nào đối với hành vi vi phạm của Khách Hàng.                  <br/>
              </Typography>
              <Typography variant='body1' component="div">
                  <br/>
                  <strong>BẢO MẬT</strong>
                  <br/>
                  GAP HR hiện tại chỉ tiếp nhận các yêu cầu tuyển dụng của các Khách Hàng không có nhu cầu bảo mật thông tin của vị trí tuyển dụng. Yêu cầu tuyển dụng, hình ảnh, thương hiệu (bao gồm logo, website) của Khách Hàng sẽ được sử dụng để thu hút đối tác tham gia giới thiệu Ứng viên cho doanh nghiệp.
                  <br/>
                  Khách Hàng có trách nhiệm bảo vệ thông tin của Ứng viên khi được giới thiệu thông qua GAP HR, và không được phép tiết lộ thông tin Ứng viên nếu không có sự đồng ý của Ứng viên.
                  <br/>
                  GAP HR cam đoan bảo mật các thông tin về Ứng viên mà GAP HR tiếp nhận hoặc có thể tiếp nhận từ Khách Hàng trong quá trình thực hiện Hợp Đồng này, bảo đảm và tránh cho Khách Hàng không bị ảnh hưởng, thiệt hại từ bất kỳ khiếu nại, tổn thất, hoặc trách nhiệm phát sinh từ việc tiết lộ thông tin bởi GAP HR mà không được sự đồng ý của Khách Hàng.
                  <br/>
                  Khách Hàng có trách nhiệm bảo mật tài khoản và mật khẩu, và chỉ sử dụng tài khoản cho mục đích tuyển dụng của mình theo quy định.
              </Typography>

              <Typography variant='body1' component="div">
                  <br/>
                  <strong>BẢN NÂNG CẤP, CHỈNH SỬA</strong>
                  <br/>
                  Chúng tôi có thể cập nhật Điều Khoản và Điều Kiện này bất cứ lúc nào và sẽ tiến hành thông báo qua trang chủ (hr.gapsoftware.asia), ứng dụng trên điện thoại di động của chúng tôi hoặc thông báo về việc cập nhật Điều Khoản và Điều Kiện khi bạn đăng nhập hệ thống. Bạn có thể xem thời điểm Điều Khoản và Điều Kiện này được cập nhật mới nhất bằng cách kiểm tra ngày “cập nhật mới nhất” được hiển thị ở đầu trang này.
                  <br/>
                  Những thay đổi, chỉnh sửa, bổ sung hoặc xóa bỏ sẽ có hiệu lực theo “ngày có hiệu lực” của Điều Khoản và Điều Kiện được đăng tải trên trang chủ, hoặc các ứng dụng trên điện thoại của GAP, bằng việc chấp nhận thông báo và tiếp tục sử dụng dịch vụ sau khi Điều Khoản và Điều Kiện có hiệu lực, bạn đã đồng ý với các thay đổi của chúng tôi.
              </Typography>

              <Typography variant='body1' component="div">
                  <br/>
                  <strong>Bản quyền và thương hiệu</strong>
                  <br/>
                  GAP là chủ sở hữu của các thương hiệu, biểu tượng và các nhãn mác sản phẩm/dịch vụ đăng tải trên Trang HR GAP. Những người truy cập vào Trang HR GAP không được phép sử dụng các tài sản nêu trên nếu không được sự cho phép trước của GAP bằng văn bản.                  <br/>
              </Typography>

              <Typography variant='body1' component="div">
                  <br/>
                  <strong>Thông tin hỗ trợ Khách hàng</strong>
                  <br />
                  Mọi thắc mắc Khách hàng có thể liên hệ với GAP để được tư vấn và giải đáp qua:
                  <br />
                  - Địa chỉ email: admin@gapsoftware.asia
                  <br />
                  - Số điện thoại: <span className="text-danger">0962304905</span>
                  <br />
                  Trân trọng cảm ơn bạn đã sử dụng sản phẩm dịch vụ của chúng tôi.
              </Typography>

          </Container>
          <Footer/>
      </>
  );
};

export default TermsOfService;
