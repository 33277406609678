import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import React, { useState } from 'react';
import { mailService } from 'services';
import { mapSourceTemp } from './utils';

interface ISourceOption {
  label: string;
  value: string;
}
const SelectTemplate = ({ ...props }) => {
  const [tempEmailList, settempEmailList] = useState<ISourceOption[]>([]);
  const { isFetching } = useQuery(['getListEmail'], () => mailService.getListEmail(), {
    keepPreviousData: true,
    onSuccess: (res) => {
      settempEmailList(mapSourceTemp(res.data));
    },
  });
 
  return <Select 
  loading={isFetching} 
  style={{ width: '100%' }} 
  placeholder='template email' 
  options={tempEmailList} 
  { ...props }
  onChange={(value: any) => props.onChange(value)}
  />;
};

export default SelectTemplate;
