import React from 'react';
import Typography from '@mui/material/Typography';
import {Container} from '@mui/material';
import Footer from './Footer';

const PrivacyPolicy = () => {
  return (
      <>
          <Container maxWidth='lg' className='privacy-policy flex items-center flex-wrap mb-10'>
              <Typography variant='subtitle1'>
                  <strong>Giới thiệu</strong>
              </Typography>
              <Typography variant='body1' className='mt-2'>
                  Website: http://hr.gapsoftware.asia/ (Sau đây gọi tắt là “Trang GAP HR”) được phát triển, sở hữu và vận hành bởi{' '}
                  <span className="text-danger">Công ty TNHH Phần mềm GAP</span> (Sau đây gọi tắt là “GAP”).
                  Vui lòng đọc chính sách sau để hiểu thông tin cá nhân của bạn sẽ được xử lý như thế nào khi bạn sử dụng đầy đủ Trang GAP HR.
                  Bằng cách sử dụng Trang GAP HR, bạn đồng ý với các chính sách và thông lệ được mô tả trong Chính sách này.
                  Chính sách này có thể thay đổi theo thời gian, vì vậy vui lòng kiểm tra lại định kỳ.
              </Typography>
              <Typography variant='body1'>
                  Các cá nhân, tổ chức sử dụng các dịch vụ trên Trang GAP HR (sau đây có thể gọi là “bạn” hoặc “Khách hàng” hoặc
                  “Người Dùng”) được yêu cầu phải đọc kỹ, hiểu, đồng ý và chịu sự ràng buộc với các quy định được mô tả trong Điều
                  khoản và Điều kiện sử dụng đối với người sử dụng dịch vụ của GAP (Sau đây gọi là “Điều Khoản và Điều Kiện”)
                  sau đây:
              </Typography>
              <br/>
              <Typography variant='body1' component="div">
                  <strong>Điều 1. Thu thập thông tin</strong>
                  <br/>
                  Trang GAP HR thu thập thông tin theo nhiều cách từ các phần khác nhau trên trang web của chúng tôi.
                  Một số thông tin cá nhân được thu thập khi bạn đăng ký. Trong quá trình đăng ký, chúng tôi hỏi tên và địa chỉ email của bạn.
                  Hệ thống cũng có thể hỏi địa chỉ, thành phố, số điện thoại, thông tin thanh toán và URL của trang web của bạn, mặc dù chỉ những trường được đánh dấu (*) trên trường đăng ký là bắt buộc.
                  Trang GAP HR cũng thu thập hoặc có thể thu thập thông tin nhân khẩu học như tuổi, sở thích, giới tính, mối quan tâm và sở thích của bạn.
                  Sau khi đăng ký, bạn không còn ẩn danh đối với Trang GAP HR - bạn có tên người dùng và có thể tận dụng tối đa nhiều ưu đãi của trang web.
                  Ngoài việc đăng ký, chúng tôi có thể yêu cầu bạn cung cấp thông tin cá nhân vào những thời điểm khác, bao gồm (nhưng không giới hạn) khi bạn đặt việc làm hoặc tận dụng các tính năng khác của trang web.

              </Typography>
              <br/>
              <Typography variant='body1' component="div">
                  <strong>Điều 2. Cách chúng tôi sử dụng thông tin cá nhân của bạn</strong>
                  <br/>
                  Bằng cách gửi thông tin của mình, Bạn đồng ý rằng Trang GAP HR, các chi nhánh, công ty con và các tổ chức liên quan khác có thể sử dụng thông tin của bạn mà chúng tôi thu thập cho dù là thông tin cá nhân, nhân khẩu học, tập thể hay kỹ thuật,
                  nhằm mục đích tạo thuận lợi cho quá trình đào tạo, tuyển dụng, chúng tôi kết hợp thông tin của bạn với các cơ hội việc làm IT.
                  Cho phép thông tin chi tiết về kỹ năng và kinh nghiệm của bạn được xem bởi những công ty mà chúng tôi cảm thấy có thể quan tâm đến bạn.
                  Cho phép chúng tôi gửi cho bạn email dù có sự đồng ý hay không có sự đồng ý từ bạn. Dịch vụ này sẽ gửi email cho các bạn thông tin về các lĩnh vực mới nhất có liên quan đến lĩnh vực và kinh nghiệm chuyên môn của bạn.
                  Thông báo cho bạn về các cơ hội việc làm IT mà chúng tôi cho rằng bạn quan tâm.
                  Cập nhật cho bạn về hàng hoá và dịch vụ do đối tác kinh doanh thứ 3 của chúng tôi cung cấp liên quan đến ngành của bạn. Bạn có thể chọn không tham gia bất cứ lúc nào từ các email như vậy.
                  Thông báo cho bạn về những thay đổi dịch vụ của chúng tôi.
              </Typography>
              <br/>
              <Typography variant='body1' component="div">
                  <strong>Điều 3. Ai đang thu thập thông tin của bạn?</strong>
                  <br/>
                  Thông tin cá nhân của bạn có thể được công bố/sử dụng bởi các bên thứ 3 sau đây nhằm kết nối các dịch vụ của Gap và bạn đến các cơ hội phù hợp.
                  <li>Nhà tuyển dụng/ doanh nghiệp đang có nhu cầu tuyển dụng nhân sự</li>
                  <li>Các đối tác chiến lược làm việc với Gap để cung cấp một trong các Dịch vụ của Gap hoặc để hỗ trợ tiếp thị và giới thiệu tới người dùng Gap.</li>
                  <li>Các tư vấn chuyên nghiệp của Gap khi có nhu cầu tìm hiểu cơ bản với mục đích tư vấn cho Gap.</li>
                  <li>Chúng tôi có thể tiết lộ thông tin đó nếu pháp luật yêu cầu làm như vậy, nếu được yêu cầu bởi một tổ chức chính phủ hoặc nếu chúng tôi tin tưởng một cách thiện chí rằng hành động đó là cần thiết để: (a) tuân thủ các yêu cầu pháp lý hoặc tuân thủ quy trình pháp lý; (b) bảo vệ các quyền hoặc tài sản hoặc các công ty đối tác của mình; (c) ngăn chặn tội phạm hoặc bảo vệ an ninh quốc gia; hoặc (d) bảo vệ sự an toàn cá nhân của người dùng hoặc cộng đồng.</li>
                  <br/>
                  Ngoài những trường hợp ở trên, bạn sẽ được thông báo khi thông tin cá nhân của bạn có thể đi đến các bên thứ ba, và bạn sẽ có cơ hội lựa chọn không chia sẻ thông tin này.
                  Gap HR không cho phép bất kỳ bên thứ ba nào được phép chia sẻ lại thông tin đã được cung cấp cho một bên khác hoặc sử dụng không đúng mục đích đã được ký kết trong hợp đồng với Gap HR.
                  <br/>
              </Typography>

              <Typography variant='body1' component="div">
                  <br/>
                  <strong>Điều 4. Quy định về bảo mật thông tin</strong>
                  <br/>
                  GAP đảm bảo rằng các thông tin cá nhân của Bạn sẽ được bảo mật theo quy định pháp luật trong phạm vi quản lý
                  của GAP.
                  <br/>
                  GAP có thể lưu trữ và sử dụng thông tin của Bạn cho mục đích:
                  <li>Hỗ trợ Bạn sử dụng sản phẩm, dịch vụ của GAP</li>

                  <li>Thực hiện các hoạt động quảng bá, tiếp thị có liên quan đến dịch vụ của GAP</li>

                  <li>Thực hiện các bản khảo sát khách hàng và nghiên cứu thị trường</li>

                  <li>Cải thiện và nâng cao chất lượng dịch vụ của GAP</li>

                  <li>Quản trị, lập các thống kê về tình hình sử dụng dịch vụ</li>
              </Typography>

              <Typography variant='body1' component="div">
                  <br/>
                  <strong>Điều 5. Cảnh báo đối với khách hàng sử dụng Trang HR GAP</strong>
                  <br/>
                  Khách Hàng phải tự bảo mật thông tin tài khoản của cá nhân, tuyệt đối không để lộ hoặc cung cấp thông tin về
                  user/password của mình trên Trang HR GAP hoặc cung cấp thông tin liên quan (mật khẩu, mã OTP….) đến tài khoản
                  ngân hàng, thẻ tín dụng… cho bất kì bên thứ ba nào khác để tránh rủi ro.

              </Typography>

              <Typography variant='body1' component="div">
                  <br/>
                  <strong>Điều 6. Thông tin hỗ trợ Khách hàng</strong>
                  <br />
                  Mọi thắc mắc Khách hàng có thể liên hệ với GAP để được tư vấn và giải đáp qua:
                  <br />
                  - Địa chỉ email: admin@gapsoftware.asia
                  <br />
                  - Số điện thoại: <span className="text-danger">0962304905</span>
                  <br />
                  Trân trọng cảm ơn bạn đã sử dụng sản phẩm dịch vụ của chúng tôi.
              </Typography>

          </Container>
          <Footer/>
      </>
  );
};

export default PrivacyPolicy;
