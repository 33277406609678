import { configureStore } from '@reduxjs/toolkit';
import profile from './profile';
import storage from './storage';
import notification from './notification';

export const store = configureStore({
  reducer: {
    profile,
    storage,
    notification,
  },
});

export type RootState = ReturnType<typeof store.getState>;
