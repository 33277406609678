
import {Link, Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import {authRoute, privateRoute, publicRoute} from 'routes';
import {AppBar, Avatar, Button, Container, Dialog, Toolbar} from "@mui/material";
import {LoginForm, RegisterForm} from "../views/Auth";
import {useEffect, useState} from "react";
import MessengerCustomerChat from "react-messenger-customer-chat";
import {useSelector} from "react-redux";
import {profileSelector} from "../reducers/profile";

const PublicLayout = () => {
    const [isOpenLogin, setOpenLogin] = useState(false);
    const [isOpenRegister, setOpenRegister] = useState(false);
    const navigate = useNavigate();
    const { isLoggedIn } = useSelector(profileSelector);

    useEffect(() => {
        if (isLoggedIn) {
            navigate(privateRoute.home.path, { replace: true });
        }
    }, [isLoggedIn, navigate]);

    return (
    <>
        <AppBar square position='sticky' elevation={0} className='bg-white/90 py-2'>
            <Toolbar>
            <Container maxWidth='lg' className='flex justify-between'>
                <Link
                    to={{
                        pathname: '/gap'
                    }}
                >
                    <Avatar src='/gap-logo.png' className='w-[90px] h-[90px]' />
                </Link>

                <div className='flex items-center gap-4'>
                    <Button variant='text' size='large' color='inherit' onClick={() => setOpenLogin(true)}>
                        Login
                    </Button>
                    <Button variant='contained' size='large' color='info' onClick={() => setOpenRegister(true)}>
                        Sign Up
                    </Button>
                </div>
            </Container>
        </Toolbar>
        <Dialog fullWidth maxWidth='xs' open={isOpenLogin} onClose={() => setOpenLogin(false)}>
            <LoginForm onClose={() => setOpenLogin(false)} />
        </Dialog>
        <Dialog fullWidth maxWidth='xs' open={isOpenRegister} onClose={() => setOpenRegister(false)}>
            <RegisterForm onClose={() => setOpenRegister(false)} />
        </Dialog>
        </AppBar>

        <div className='p-6 mt-10'>
            <Routes>
              {Object.values(publicRoute).map((item) => (
                  <Route key={item.path} path={item.path} element={item.element}  />
              ))}
                <Route path='*' element={<Navigate to={authRoute.landing.url} replace />} />
            </Routes>
        </div>

        <MessengerCustomerChat
            pageId="115750871439621"
            appId=""
            language="vi_VN"
            greetingDialogDisplay="hide"
        />
    </>
  );
};

export default PublicLayout;
