import { LoadingButton } from '@mui/lab';
import { Paper, Tab, Tabs, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Alert } from 'components';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { signIn } from 'reducers/profile';
import { userService } from 'services';
import { USER_TYPES } from 'utils/constants';
import { isValidEmail, isValidPhone } from 'utils/validator';

const RegisterForm = ({ onClose }: PopupController) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm();

  const [activeTab, setActiveTab] = useState(0);

  const { mutate: login, isLoading: isLoadingSignIn } = useMutation(userService.login, {
    onSuccess: (data) => {
      dispatch(signIn({ ...data, accessToken: data.token?.substr(7) }));
    },
  });

  const { mutate: signUp, isLoading: isLoadingSignUp } = useMutation(userService.signUp, {
    onSuccess: (data, body) => {
      Alert.success({ message: t('Sign up successfully') });

      login(body);
    },
  });

  const handleClickSignUp = () => {
    handleSubmit(({ username, password, email, phone }) => {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('userType', activeTab + '');
      signUp(formData);
    })();
  };

  return (
    <Paper className='flex flex-col gap-8 p-10'>
      <Tabs variant='fullWidth' value={activeTab} onChange={(_, value) => setActiveTab(value)}>
        {USER_TYPES.filter(filtered => filtered.code !== 6).map((item) => (
          <Tab key={item.id} label={item.name} value={item.code} />
        ))}
      </Tabs>

      <div className='flex flex-col gap-2 text-center'>
        <Typography variant='h5'>{t('Sign up')}</Typography>
        <Typography color='textSecondary'>
          {activeTab ? t('Sign up as employer') : t('Sign up as freelance hunter')}
        </Typography>
      </div>

      <Controller
        name='username'
        defaultValue=''
        control={control}
        rules={{ required: t`Username is required` }}
        render={({ field, fieldState: { error } }) => (
          <TextField {...field} label={t('Username')} error={!!error} helperText={error?.message} />
        )}
      />

      <Controller
        name='password'
        defaultValue=''
        control={control}
        rules={{ required: t`Password is required` }}
        render={({ field, fieldState: { error } }) => (
          <TextField {...field} type='password' label={t('Password')} error={!!error} helperText={error?.message} />
        )}
      />

      <Controller
        name='email'
        defaultValue=''
        control={control}
        rules={{
          required: t`Email is required`,
          validate: {
            isEmail: (value) => isValidEmail(value) || t`Email is not valid`,
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField {...field} label={t('Email')} error={!!error} helperText={error?.message} />
        )}
      />

      <Controller
        name='phone'
        defaultValue=''
        control={control}
        rules={{
          validate: {
            isEmail: (value) => !value || isValidPhone(value) || t`Phone number is not valid`,
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField {...field} type='number' label={t('Phone number')} error={!!error} helperText={error?.message} />
        )}
      />

      <LoadingButton
        variant='contained'
        size='large'
        loading={isLoadingSignIn || isLoadingSignUp}
        onClick={handleClickSignUp}
      >
        {t('Sign up')}
      </LoadingButton>

      {/* <Typography>
          {t('Already have an account?')} <Link to={authRoute.signIn.url}>{t('Sign in')}</Link>
        </Typography> */}
    </Paper>
  );
};

export default RegisterForm;
