import { client, clientDetail } from './axios';

const api = '/api/v1/credit';

const createCredit = (body: any): Promise<CreditType> => clientDetail.post(api, { constructor: 'createCredit', params_request: body });

const getListInfoCredit = (body: any): Promise<GetListCreditType> =>
    client.post(api, { constructor: 'getListInfoCredit', params_request: body });

const updateCredit = (body: any): Promise<any> => client.post(api, { constructor: 'updateCredit', params_request: body });

const getMarketPlaceInfo = (): Promise<CreditType> => clientDetail.post(api, { constructor: 'getMarketPlaceInfo'});

export default {
  createCredit,
  getListInfoCredit,
  updateCredit,
  getMarketPlaceInfo
};
