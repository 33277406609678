import { ChatSticky } from 'components';
import { AppDrawer } from 'containers';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { authRoute, privateRoute } from 'routes';
const PrivateLayout = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(profileSelector);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(authRoute.landing.url, { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return (
    <>
      <AppDrawer>
        <div className='p-6 mt-10'>
          <Routes>
            {Object.values(privateRoute).map((item) => (
              <Route key={item.path} path={item.path} element={item.element} />
            ))}
            <Route path='*' element={<Navigate to={privateRoute.notFound.path} replace />} />
          </Routes>
        </div>
        <ChatSticky />
      </AppDrawer>
    </>
  );
};

export default PrivateLayout;
