import {
  AssignmentIndOutlined,
  DirectionsOutlined,
  MoreVert,
} from '@mui/icons-material';
import {
    Avatar,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Pagination,
    Paper,
    Typography,
} from '@mui/material';
import {useMutation, useQuery} from '@tanstack/react-query';
import {Dropdown, Modal, Table, Tag} from 'antd';
import { useSearch, useTable } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { privateRoute } from 'routes';
import {candidateService, marketService, queryClient} from 'services';
import { formatLevel } from 'utils/converter';
import {TalentPoolSearch} from "./components";
import {Alert} from "../../components";
import {CANDIDATE_STATUS_TYPES} from "../../utils/constants";

const TalentPoolList = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const {isCandide } = useSelector(profileSelector);

  const [dataSearch, onSearchChange] = useSearch();
  const [dataSort, onTableChange] = useTable();

  const {
    data: { data: items = [], total = 0 } = {},
    isFetching,
  } = useQuery(
    ['getListTalentPool', dataSearch, dataSort],
    () => candidateService.getListTalentPool({ search: dataSearch, sort: dataSort }),
    {
      keepPreviousData: true,
      enabled: !isCandide,
    },
  );

  const { mutate: unlockTalent } = useMutation(marketService.unlockTalent, {
        onSuccess: (data, body) => {
            if (data.status === 1){
                navigate(privateRoute.talentView.url(body.cvId), { replace: true });
                queryClient.invalidateQueries(['getMarketPlaceInfo']);
                Alert.success({ message: t('Unlock candidate successfully') });
            }else {
                Modal.confirm({
                    title: t('Confirmation'),
                    content: `${data.message}`.concat('. ', 'Do you want to go to the credit purchase page?'),
                    onOk: () => navigate(privateRoute.package.path, { replace: true }),
                    okType: 'primary',
                });
            }
        },
  });

  const TablePagination = () => (
    <Pagination
      count={Math.ceil(total / 10)}
      page={dataSearch.page + 1}
      onChange={(event, nextPage) => {
        onSearchChange({ page: nextPage - 1 });
      }}
    />
  );

  return (
    <>
      <div className='flex items-center flex-wrap mb-6'>
        <IconButton>
          <AssignmentIndOutlined />
        </IconButton>
        <Typography variant='h6'>{t('Talent pool')}</Typography>

        <div className='flex-1' />

      </div>
      <TalentPoolSearch onSearch={onSearchChange} />

      <Paper className='flex justify-between items-center flex-wrap p-4 mb-6'>
        <Typography variant='subtitle1' color='green'>
          {total} {t('candidates matched')}
        </Typography>
        <TablePagination />
      </Paper>

      <Paper className='mb-6'>
        <Table
          scroll={{ x: 800 }}
          bordered={false}
          loading={isFetching}
          rowKey={(record) => record.id}
          dataSource={items}
          pagination={false}
          onChange={onTableChange}
          columns={[
            {
              title: 'Candidate',
              dataIndex: 'candidate',
              width: 20,
              render: (_, record) => (
                  <div className='flex items-center'>
                      <Avatar src={record.avatar} />
                      <span style={{marginLeft: 15}}>
                          {record.candidateName}
                      </span>
                  </div>
              )
            },
            {
              title: 'Location',
              dataIndex: 'location',
              width: 120,
              sorter: true,
              render: (_, record) => record.address,
            },
            {
              title: 'Level',
              dataIndex: 'level',
              width: 100,
              sorter: true,
              render: (_, record) => <Tag color={formatLevel(record.level)}>{record.level}</Tag>,
            },
            {
              title: 'Skill',
              dataIndex: 'skill',
              width: 180,
              render: (_, record) =>
                record.skill?.map((item, index) => (
                  <Tag key={index} className='mb-1'>
                    {item}
                  </Tag>
                )),
            },
            {
              title: 'Status',
              dataIndex: 'status',
              width: 100,
              sorter: true,
              render: (_, record) => {
                  const status = CANDIDATE_STATUS_TYPES.find((item) => item.code === record.status);
                  return (
                      <Tag color={status?.color}>{status?.name}</Tag>
                  );
              },
            },
            {
              title: 'Price',
              dataIndex: 'price',
              width: 30, render: (_, record) =>
                    <div className='flex'>
                        <strong>{record.price??0}</strong>
                        <span style={{
                            display: 'flex',
                            background: '#f6b300',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color:'#ff0',
                            borderRadius: '100rem',
                            border: '2px solid #e78b03',
                            width: 20,
                            height: 20,
                            marginLeft: 5
                        }}>G
                        </span>
                    </div>
            },
            {
              dataIndex: '',
              align: 'right',
              width: 40,
              render: (_, record) => (
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <List component={Paper}>
                      {/*<Link to={privateRoute.candidateUpdate.url(record.id)}>*/}
                        <ListItemButton
                            onClick={() => {
                                Modal.confirm({
                                    title: t('Confirmation'),
                                    content: 'Are you sure to show profile CV with'.concat(' ', `${record?.price??0}`).concat(' ', ' G coin?'),
                                    onOk: () => unlockTalent({ cvId: record.id }),
                                    okText: 'Show',
                                    okType: 'primary',
                                });
                            }}
                        >
                          <ListItemIcon>
                            <DirectionsOutlined color='secondary' />
                          </ListItemIcon>
                          <ListItemText primary={t('View Profile')} className='text-black/80' />
                        </ListItemButton>
                      {/*</Link>*/}
                    </List>
                  }
                >
                  <IconButton size='small'>
                    <MoreVert />
                  </IconButton>
                </Dropdown>
              ),
            },
          ]}
        />
      </Paper>

      {total > 0 && (
        <div className='flex justify-center'>
          <TablePagination />
        </div>
      )}
    </>
  );
};

export default TalentPoolList;
