import { Divider } from 'antd'
import { CupSvg, EmployeeSvg, HealthSvg } from 'assets/icons'
import React from 'react'
const RecruiterAbout = ({...props}) => {
  return (
    <>
      <div className='flex gap-5'>
        <div className='recruiter-avatar my-3'>
          <img className='w-[100px] h-[100px]' src={props.avatar?props.avatar:require('assets/images/ava-female.jpeg')} alt="avatar" />
        </div>
        <div className='recruiter-detail my-3'>
          <h1 className='font-bold lg:text-4xl text-sm my-3'>{props.username}</h1>
          <div className='flex gap-2 items-center my-3'>
            <p>Headhunter </p>
            <p>|</p>
            <p>Recruiter </p>
          </div>
          <div className='job-info flex gap-5'>
            <div>
              <div className='flex gap-2 items-center'>
                <EmployeeSvg />
                <p className='font-bold'>{props.totalUser}</p>
              </div>
              <p>Ứng viên</p>
            </div>
            <div>
              <div className='flex gap-2 items-center'>
                <CupSvg />
                <p className='font-bold'>{props.totalJob}</p>
              </div>
              <p>Công việc</p>
            </div>
            <div>
              <div className='flex gap-2 items-center'>
                <HealthSvg />
                <p className='font-bold'>{props.totalCv}</p>
              </div>
              <p>Tổng CV</p>
            </div>
          </div>
        </div>
      </div>
      <Divider dashed style={{ borderWidth: '2px 0 0' }} />
      <div className='my-5'>
        <p>{props.intro}
        </p>
      </div>
    </>
  )
}

export default RecruiterAbout