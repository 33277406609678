import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { mailService } from 'services';
import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import { IconButton, Paper, Typography } from '@mui/material';
import {decode } from 'html-entities';
import {Alert} from 'components'
import { useTranslation } from 'react-i18next';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { typeSendMailOption } from 'const/options';
import privateRoute from 'routes/privateRoute';
const CampaignView = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tempId,setTempId] = useState<number>()
  const [content, setContent] = useState<string>()
  const [recipientTest, setRecipientTest] = useState<string>()
  const [typeSendMail, setTypeSendMail] = useState<boolean>(true)
  const { data: { data: item } = {} } =  useQuery(['getDetailCampaign', id], () => mailService.getDetailCampaign(id!), {
    onSuccess: (res) => {
      const { data } = res;
      setTempId(data.idMailTemplate)
    },
    enabled: !!id,
  });
  useQuery(['getInfoEmailDetail', tempId], () => mailService.getInfoEmailDetail(String(tempId!)), {
    onSuccess: (res) => {
      const { data } = res;
      setContent(data.content);
    },
    enabled: !!tempId,
  });
  useEffect(
    () => {
      if(!!tempId){
        mailService.getInfoEmailDetail(String(tempId!)).then(res => {
          const { data } = res;
          setContent(data.content);
        })
      }
    },[tempId]
  )
  const { mutate: updateCampaign, isLoading: isLoadingUpdate } = useMutation(
    mailService.updateCampaign,
    {
      onSuccess: (res) => {
        if (id) {
          sendCampaign(id)
        }
      },
    },
  );
  const { mutate: sendCampaign, isLoading: isLoadingSend } = useMutation(
    mailService.sendMailCampaign,
    {
      onSuccess: (res) => {
        if (id) {
          Alert.success({ message: t('Send campaign successfully') });
          navigate(privateRoute.EmailCampaignList.path);
        }
      },
    },
  );
  const { mutate: sendMailTest, isLoading: isLoadingSendTest } = useMutation(
    mailService.sendMailTest,
    {
      onSuccess: (res) => {
        if (res.code === 200) {
          Alert.success({ message: t('Send test successfully') });
        }
      },
    },
  );
  const handleClickTestMail = () => {
    if(recipientTest && item?.subject && content){
      sendMailTest({
        recipient: recipientTest,
        title: item?.subject,
        content: encodeURIComponent(content)
      })
    }
  }
  const handleClickSendCampaign = () => {
    if(typeSendMail && item && id){
      sendCampaign(id)
      // updateCampaign({
      //   name: item.name,
      //   idCvs: item.idCvs,
      //   idJob: item.idJob,
      //   idMailTemplate: item.idMailTemplate,
      //   subject: item.subject,
      //   active: true
      // })
    }
    else{
      navigate(privateRoute.EmailCampaignList.path);
    }
  }
  const onChangeMailTest = (event:any) => {
    setRecipientTest(event.target.value)
  }
  const onChangeTypeSendMail = (value: any) => {
    console.log(value)
    setTypeSendMail(value)
  }
  return (
    <>
      <Paper className='p-4 flex items-center flex-wrap mb-6 my-8'>
        <IconButton>
          <AlternateEmailIcon />
        </IconButton>
        <Typography variant='h6'>{t('Preview mail')}</Typography>
      </Paper>
      <Row gutter={24}>
        <Col span={16}>
          <Card title='Content'>
            <Row gutter={24}>
            <Col lg={4}>
                <Typography className='strong text-base font-bold'>From:</Typography>
              </Col>
              <Col lg={20} span={20}>
                <Typography className='strong text-base'>hr@gapsoftware.asia</Typography>
              </Col>
              <Col span={4} lg={4} className='my-5'>
                <Typography className='strong text-base font-bold'>Subject:</Typography>
              </Col>
              <Col  className='my-5' lg={20} span={20}>
                <Typography className='strong text-base'>{item?.subject}</Typography>
              </Col>
              <Col span = {24}>
                {content && <div className='border p-4 max-h-[500px] overflow-auto' dangerouslySetInnerHTML={{ __html:  decode(content) }}></div>}    
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={8}>
          <Card title='Test Email'>
                <Input style={{width:'100%'}} onChange={onChangeMailTest} />
                <Button
                type ='primary'
                className='my-4 text-white'
                disabled= {!recipientTest}
                loading ={isLoadingSendTest}
                onClick={handleClickTestMail}
                >
                  Send Test
                </Button>
          </Card>
          <Card title='Sending Options' className='my-5'>
                <Select
                  style={{width:'100%'}}
                  defaultValue={true}
                  options={typeSendMailOption}
                  onChange={onChangeTypeSendMail}
                />
          </Card>
          <Button
            className='mr-4'
            onClick={() => navigate(privateRoute.EmailCampaignList.path)}
          >
            Cancel
          </Button>
          <Button
            type ='primary'
            className='my-4 text-white'
            loading ={isLoadingUpdate || isLoadingSend}
            onClick={handleClickSendCampaign}
          >
            Send Campaign
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default CampaignView;
