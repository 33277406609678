import { Close, FilterListOutlined, SearchOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Col, Row } from 'antd';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CREATE_LAST_TYPES, REFERRAL_STATUS_TYPES, SKILLS } from 'utils/constants';
import { CompanyTextField } from 'views/Company/components';
import {useSelector} from "react-redux";
import {profileSelector} from "../../../reducers/profile";

const ReferSearch = ({ onSearch }: SearchController) => {
  const { t } = useTranslation();
  const { isCompany } = useSelector(profileSelector);

  const [keyword, setKeyword] = useState('');
  const [company, setCompany] = useState('');
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState('');
  const [createLast, setCreateLast] = useState('');

  const handleClickSearch = () => {
    const createTime = DateTime.now().minus({ day: +createLast });
    onSearch({
      keyword,
      company,
      title,
      status: status === '' ? null : status,
      createTime: createLast === '' ? 0 : createTime.startOf('day').toSeconds(),
    });
  };

  const handleClickReset = () => {
    setKeyword('');
    setCompany('');
    setTitle('');
    setStatus('');
    setCreateLast('');
  };

  return (
    <>
      <div className='flex gap-3 mb-6'>
        <TextField
          fullWidth
          label={t('Find your refer by keyword')}
          value={keyword}
          onChange={(event) => setKeyword(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
        <Button className='md:w-[420px]' onClick={handleClickSearch}>
          {t('Search')}
        </Button>
      </div>

      <Paper className='flex flex-col p-4 mb-6'>
        <div className='flex gap-2 mb-4'>
          <FilterListOutlined />
          <Typography>{t('Filter by')}</Typography>

          <div className='ml-auto'>
            <Button variant='outlined' color='inherit' size='small' startIcon={<Close />} onClick={handleClickReset}>
              {t('Reset')}
            </Button>
          </div>
        </div>

        <Row gutter={[24, 24]} style={{ flex: 1 }}>
          {!isCompany && (
              <Col xl={6} md={12} span={24}>
                <CompanyTextField initValue={company} onChange={(value) => setCompany(value)} />
              </Col>
          )}
          <Col xl={6} md={12} span={24}>
            <Autocomplete
              freeSolo
              value={title}
              onChange={(event, value) => setTitle(value ?? '')}
              options={SKILLS}
              renderInput={(params) => <TextField {...params} label={t('Job')} InputLabelProps={{ shrink: true }} />}
            />
          </Col>
          <Col xl={6} md={12} span={24}>
            <TextField
              fullWidth
              select
              label={t('Status')}
              value={status}
              onChange={(event) => setStatus(event.target.value)}
              InputProps={{
                endAdornment: status && (
                  <InputAdornment position='end'>
                    <IconButton className='p-1 mr-4' onClick={() => setStatus('')}>
                      <Close fontSize='small' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              {REFERRAL_STATUS_TYPES.map((item) => (
                <MenuItem key={item.id} value={item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col xl={6} md={12} span={24}>
            <TextField
              fullWidth
              select
              label={t('Created')}
              value={createLast}
              onChange={(event) => setCreateLast(event.target.value)}
              InputProps={{
                endAdornment: createLast && (
                  <InputAdornment position='end'>
                    <IconButton className='p-1 mr-4' onClick={() => setCreateLast('')}>
                      <Close fontSize='small' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              {CREATE_LAST_TYPES.map((item) => (
                <MenuItem key={item.id} value={item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>
      </Paper>
    </>
  );
};

export default ReferSearch;
