import { Group } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Avatar, Paper, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { PerfectScrollbar } from 'components';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import {chatService, companyService} from 'services';
import {useTranslation} from "react-i18next";
import {privateRoute} from "../../../routes";
import {Link} from "react-router-dom";

const TopCompanySuggest = () => {
    const { t } = useTranslation();
    const { userId } = useSelector(profileSelector);

    const { data: { data: companies } = {} } = useQuery(['fetchCompanySuggestion'], () => companyService.fetchCompanySuggestion());

    const [followed, setFollowed] = useState(['']);
    const { mutate: createFollow } = useMutation(chatService.createFollow, {
        onSuccess: (data, body) => {
            setFollowed((array) => array.concat(body.followId));
        },
    });

    return (
        <Paper
            style={{
                width: 400,
                marginLeft: 80,
                position: 'sticky',
                top: 64 + 24,
                maxHeight: 'calc(100vh - 180px)',
            }}
            className='flex flex-col'
        >
            <div className='flex items-center gap-3 py-3 px-4 border-b'>
                <Group />
                <Typography variant='h6'>Top công ty IT</Typography>
            </div>
            <PerfectScrollbar>
                <div className='flex flex-col gap-5 py-3 px-4'>
                    {companies?.map((company) => {
                        const isFollowed = followed.includes(company.id+'');
                        return (
                            <div key={company.id} className='flex gap-3'>
                                <Avatar src={company.image} style={{ width: 52, height: 52 }}>
                                    <Avatar src='/gap_logo.png' style={{ width: 52, height: 52 }} />
                                </Avatar>
                                <div>
                                    <div className='flex items-center gap-1'>
                                        <Link to={privateRoute.companyView.url(company.id)}>
                                            <Typography fontWeight={600}>{company.name}</Typography>
                                        </Link>
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <Typography color='textSecondary'>
                                            {company.totalJob ?? 0} {t('Jobs')}
                                        </Typography>
                                    </div>
                                    <LoadingButton
                                        variant='outlined'
                                        color='secondary'
                                        size='small'
                                        className='rounded-full mt-2'
                                        disabled={isFollowed}
                                        onClick={() => createFollow({ userId: userId!, followId: company.id+'', type: 1, status: 1 })}
                                    >
                                        {isFollowed ? 'Following' : 'Follow'}
                                    </LoadingButton>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </PerfectScrollbar>
        </Paper>
    );
};

export default TopCompanySuggest;
