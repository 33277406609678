import { client } from './axios';

const api = '/api/v1/report';

const getDashboard = (): Promise<GetDashobardType> => client.get(`${api}/dashboard`);

const getReportStatusRefer = (): Promise<GetReportStatusReferType> => client.get(`${api}/statusOfRefer`);

const getReportBonus = (): Promise<GetReportBonusType> => client.get(`${api}/bonusPerMonth`);

const getLeaderBoardCv = (params: FetchLeaderBoardTypeParams): Promise<GetLeaderBoardListType> => client.get(`${api}/getLeaderBoard`, { params });
const getLeaderBoardBonus = (params: FetchLeaderBoardTypeParams): Promise<GetLeaderBoardListType> => client.get(`${api}/getLeaderBoard`, { params });

export default {
  getDashboard,
  getReportStatusRefer,
  getReportBonus,
  getLeaderBoardCv,
  getLeaderBoardBonus
};
