export const mapSourceJobs = (input) =>{
    var output = []
    input && input.map((item) => {
        return(
            output.push({
                label: item.title,
                value: Number(item.id)
            })
        )
    })
    console.log(output)
    return output
}

export const mapSourceCVs = (input) =>{
    var output = []
    input && input.map((item) => {
        return (
            output.push({
                label: item.candidateName,
                value: String(item.id)
            })
        )
    })
    console.log(output)
    return output
}

export const mapSourceTemp = (input) =>{
    var output = []
    input && input.map((item) => {
        return(
            output.push({
                label: item.name,
                value: item.id
            })
        )
    })
    console.log(output)
    return output
}