import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import {reportService} from "../../services";
import {Grid, Paper, Typography} from "@mui/material";
import {Paid, PaidRounded} from "@mui/icons-material";
import {useSelector} from "react-redux";
import {profileSelector} from "../../reducers/profile";
import {REPORT_STATUS_TYPES} from "../../utils/constants";
import {Board, ProgressBarComponent, BonusBarChart, BonusHistory} from "./components";
import {useState} from "react";
import {formatBonus} from "../../utils/converter";

const ReportView = () => {

    const { t } = useTranslation();
    const { isSuper, isAdmin, isRecruit, isCompany } = useSelector(profileSelector);

    const [maxCounter, setMaxCounter] = useState(1);

    const {data: {data:report} = {},} = useQuery(['getDashboard'], () => reportService.getDashboard());

    const { data } = useQuery(['getReportStatusRefer'],
        () => reportService.getReportStatusRefer(),
        {
            onSuccess: (data) => {
                Object.values(data?.data).forEach((value:any) => {
                    if (value > maxCounter) {
                        setMaxCounter(value);
                    }
                });
            }
        }
        );

    const {data: {data:reportBonus} = {},} = useQuery(['getReportBonus'],
        () => reportService.getReportBonus());

    return (
        <div className='flex items-start'>
            <div className='flex-1'>

                <div className='flex items-center mb-6'>
                    <Typography variant='h6' className='flex-1'>
                        {t('Report Overview')}
                    </Typography>
                </div>

                <div className='flex items-center mb-6'>
                    <Grid container spacing={2}>
                        <Board
                            visible={isSuper || isAdmin || isRecruit || isCompany}
                            name={t('Total Done Bonus')}
                            color='#4D8E5F'
                            value={formatBonus(report?.totalDoneBonus)}
                            icon={<Paid />}
                        />
                        <Board
                            visible={isSuper || isAdmin || isRecruit || isCompany}
                            name={t('Total Wait Bonus')}
                            color='#FF9933'
                            value={formatBonus(report?.totalWaitBonus) ?? 0}
                            icon={<PaidRounded />}
                        />
                    </Grid>
                </div>

                <Paper className='flex-direction-column justify-content-space-between pl-4 mb-6'>
                    <div className='flex-1'>
                        <Typography variant='h5' sx={{ textTransform: 'uppercase'}}>
                            {t('Refer status')}
                        </Typography>

                        <Typography variant='body1' style={{marginBottom:15}}>
                            <span style={{color:'#A9A9A9'}}>Tổng hợp số lượng ứng viên trong từng trạng thái của quá trình ứng tuyển</span>
                        </Typography>

                    </div>

                    <div className='flex-1'>
                        <ul>
                            {REPORT_STATUS_TYPES.map((item) => {
                                const isKeyExists = data?.data[item.code];
                                if(isKeyExists){
                                    return <li className= 'flex items-center mr-3 mb-3' key={item.id}>
                                        <div style={{width:'15%'}}>
                                            <span style={{ color: item.color, fontWeight: "bold" }}>
                                            {item.name}
                                            </span>
                                            <span style={{backgroundColor: `${item.color}80`,
                                                color: item.color,
                                                fontWeight: "bold",
                                                borderRadius: 10,
                                                marginLeft: 10,
                                                marginRight: 10,
                                                padding: 1
                                            }}>{data?.data[item.code]}
                                            </span>
                                        </div>
                                        <div style={{width:'85%'}}>
                                            <ProgressBarComponent
                                                visible={true}
                                                value={data?.data[item.code]}
                                                maxValue={maxCounter}
                                                color={item.color}
                                            />
                                        </div>
                                    </li>
                                }
                                return <li className= 'flex items-center mr-3 mb-3' key={item.id}>
                                    <span style={{ color: item.color, fontWeight: "bold" }}>
                                        {item.name}
                                    </span>
                                    <span style={{backgroundColor: `${item.color}80`,
                                        color: item.color,
                                        fontWeight: "bold",
                                        borderRadius: 10,
                                        marginLeft: 10,
                                        padding: 1
                                    }}>0
                                </span>
                                </li>
                            })}
                        </ul>

                    </div>
                </Paper>

                <Paper className='flex pl-4'>

                    <div className='flex-1 justify-content mr-8' style={{width:'30%'}}>
                        <Typography variant='h5' sx={{ textTransform: 'uppercase'}}>
                            {t('Bonus Analysis')}
                        </Typography>
                        <Typography variant='body1'>
                                <span style={{color:'#A9A9A9'}}>
                                    Ghi nhận số tiền thưởng đã nhận/đã trả và số tiền thưởng đang chờ/nhận trả theo từng tháng.
                                </span>
                        </Typography>
                    </div>

                    <div className='flex' style={{width:'70%'}}>
                        <BonusBarChart
                            reportBonus={reportBonus}
                        />
                    </div>
                </Paper>

                <div className='flex-1 mt-6'>
                    <Typography variant='h5' sx={{ textTransform: 'uppercase'}}>
                        {t('Bonus History')}
                    </Typography>

                </div>

                <div className='mb-12'>
                    <BonusHistory/>
                </div>

            </div>
        </div>

    );
};

export default ReportView;