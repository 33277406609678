import { ICreateCampaignRequest, ICreateCampaignResponse, ICreateEmailRequest, ICreateEmailResponse, IGetCampaignDetailResponse, IGetInfoEmailDetailReponse, IGetListCampaignRequest, IGetListCampaignResponse, IGetListEmailResponse, ISendCampaignRequest, ISendTestMailRequest, ISendTestMailResponse, IUpdateTempEmailReponse, IUpdateTempEmailRequest } from 'models/Email';
import {client} from './axios';

const apiTemp = '/api/v1/template/mail';
const apiCampaign = '/api/v1/campaign'
const getListEmail = (): Promise<IGetListEmailResponse> =>
  client.get(apiTemp+ '/list',);
const createTempEmail = (body: ICreateEmailRequest): Promise<ICreateEmailResponse> =>
  client.post(apiTemp+ '/create', body);
const getInfoEmailDetail = (id: string): Promise<IGetInfoEmailDetailReponse> =>
  client.get(apiTemp+ '/detail/'+ id,);
const updateTempEmail = (body: IUpdateTempEmailRequest): Promise<IUpdateTempEmailReponse> =>
  client.put(apiTemp + '/update', body);
const deleteTempEmail = (id: string): Promise<any> =>
  client.delete(apiTemp + '/delete/' + id,);
const sendMailTest = (body: ISendTestMailRequest): Promise<ISendTestMailResponse> =>
  client.post(`${apiTemp}/test/send?recipient=${body.recipient}&title=${body.title}&content=${String(body.content)}`);

const createCampaign = (body: ICreateCampaignRequest): Promise<ICreateCampaignResponse> =>
  client.post(apiCampaign+ '/create', body);
const getListCampaign = (body:IGetListCampaignRequest): Promise<IGetListCampaignResponse> =>
  client.get(`${apiCampaign}/list?pageNumber=${String(body.pageNumber)}&pageSize=${String(body.pageSize)}`,);
const getDetailCampaign = (id: string): Promise<IGetCampaignDetailResponse> =>
  client.get(apiCampaign+ '/'+ id,);
const updateCampaign = (body: ICreateCampaignRequest): Promise<ICreateCampaignResponse> =>
  client.put(apiCampaign + '/update', body);
const deleteCampaign = (id: string): Promise<any> =>
  client.delete(apiCampaign + '/delete/' + id,);
const sendMailCampaign = (id_campaign: string): Promise<ICreateEmailResponse> =>
  client.post(`${apiCampaign}/sendMail?id_campaign=${id_campaign}`);
export default {
    getListEmail,
    createTempEmail,
    getInfoEmailDetail,
    updateTempEmail,
    deleteTempEmail,
    createCampaign,
    getListCampaign,
    getDetailCampaign,
    updateCampaign,
    deleteCampaign,
    sendMailCampaign,
    sendMailTest
};
