import { Button } from '@mui/material';
import React from 'react';

interface DynamicFieldButtonProps {
  shortcode: string;
  onClick: () => void;
}

const DynamicFieldButton: React.FC<DynamicFieldButtonProps> = ({ shortcode, onClick }) => {
  return (
    <Button
    className='lowercase'
    color = 'secondary'
    onClick={onClick}>
      {`{{${shortcode}}}`}
    </Button>
  );
};

export default DynamicFieldButton;
