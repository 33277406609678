import { Editor, IAllProps } from '@tinymce/tinymce-react';
// 5d69hqudzyhmoa0v09uej10bp1zghpplvbbciuekof9c15xw
const TINYMCE_KEY = 'y7tymr5dga8dmnpn2yeo06x8au466lhtrf3u3bt2p2q2e7gl';

type EditorProps = IAllProps & {
  height?: number;
};

const RichTextEditor = ({ height = 200, ...props }: EditorProps) => (
  <Editor
    init={{
      height,
      menubar: false,
      branding: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
      ],
      toolbar: [
        'fullscreen | undo redo | image | code',
        'formatselect | bold italic backcolor link',
        'alignleft aligncenter alignright alignjustify',
        'bullist numlist outdent indent | help',
      ].join(' | '),
    }}
    apiKey={TINYMCE_KEY}
    {...props}
  />
);

export default RichTextEditor;
