import { Breadcrumb } from 'antd'
import React from 'react'

interface ConfigBreadCrumb {
    text: string;
    isLink: boolean;
    url: string
}
interface IBreadCrumbProps {
    config: ConfigBreadCrumb[]
}
const BreadCrumb = (props: IBreadCrumbProps) => {
    const { config } = props;
    return (
        <div className='mb-5'>
            <Breadcrumb>
                {
                    config && config.map((item: ConfigBreadCrumb, index: number) => {
                        return (
                            <Breadcrumb.Item key={index}>
                                {
                                    item.isLink ? <a href={item.url}>{item.text}</a> : item.text
                                }
                            </Breadcrumb.Item>
                        )
                    })
                }
            </Breadcrumb>
        </div>
    )
}

export default BreadCrumb