import {Avatar, Grid, Paper, styled, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {privateRoute} from "../../../routes";
import {Col, Row, Tag} from "antd";
import {JOB_STATUS_TYPES} from "../../../utils/constants";
import {formatBonus, formatListToString} from "../../../utils/converter";
import {useQuery} from "@tanstack/react-query";
import {jobService} from "../../../services";
import {useTranslation} from "react-i18next";

const JobUrgently = () => {
    const { t } = useTranslation();

    const { data: { data: items = [] } = {} } = useQuery(
        ['getUrgentJob'], () => jobService.getUrgentJob()
    );

    const StyledPaper = styled(Paper)`
            background-color: #f5f4f8;
            transition: background-color 0.3s;

            &:hover {
                background-color: #ffffff;
            }
    `;

    return(
        <>

            <Grid item xs={12} md={6}>
                <Typography variant='h6' style={{marginBottom:10}}>
                    {t('Urgently hiring')}
                </Typography>
                {items.map((job) => {
                    const status = JOB_STATUS_TYPES.find((item) => item.code === job.status);
                    return (

                        <StyledPaper className='flex p-4 mb-6' key={job.id}>
                            <Avatar src={job.company?.image} style={{ width: 120, height: 120, margin: '0px 24px 12px 0px' }}>
                                <Avatar src='/gap_logo.png' style={{ width: 120, height: 120 }} />
                            </Avatar>

                            <div className='flex-1'>
                                <Link to={privateRoute.jobView.url(job.id)} className='inline-flex items-center flex-wrap mb-3'>
                                    <Typography component='span' variant='h6' color='primary' className='mr-2'>
                                        {job.title}
                                    </Typography>
                                </Link>

                                <Tag color={status?.color}>{status?.name}</Tag>

                                {job.bonus > 0 && (
                                    <Typography variant='h6' color='textSecondary'>
                                        {t('Bonus')}:{' '}
                                        <span style={{ color: job.bonus > 0 ? 'green' : 'silver' }}>{formatBonus(job.bonus)}</span>
                                    </Typography>
                                )}
                                <Row gutter={12}>
                                    <Col flex={1}>
                                        <Typography>
                                            {t('Workplace')}: {formatListToString(job.workplace)}
                                        </Typography>
                                    </Col>
                                </Row>
                                <Row gutter={12}>
                                    <Col flex={1}>
                                        <Typography color='textSecondary'>
                                            {t('Level')}: <span style={{ color: 'black' }}>{job.level}</span>
                                        </Typography>
                                    </Col>
                                </Row>
                            </div>
                        </StyledPaper>
                    );
                })}
            </Grid>

        </>
    )
};

export default JobUrgently