import {
  ClearAll,
  DeleteOutlined,
  DirectionsOutlined,
  GroupOutlined,
  MoreVert,
  RefreshOutlined,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Pagination,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Dropdown, Modal, Select, Table, Tag } from 'antd';
import { Alert } from 'components';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { privateRoute } from 'routes';
import { queryClient, userService } from 'services';
import { USER_RANKS, USER_ROLES, USER_TYPES } from 'utils/constants';
import { toFormat } from 'utils/converter';
import { UserSearch } from './components';

const UserList = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const type = new URLSearchParams(location.search).get('type') ?? 'user';
  const isRanking = type === 'bxh';

  const [dataSearch, onSearchChange] = useSearch();
  const [dataSelect, setDataSelect] = useState<UserType[]>([]);

  const {
    data: { data: items = [],  total = 0 } = {},
    isFetching,
    refetch,
  } = useQuery(['getListUser', dataSearch, type], () => userService.getListUser({ ...dataSearch, type }), {
    keepPreviousData: true,
  });

  const { mutate: updateRole } = useMutation(userService.updateRole, {
    onSuccess: (data, body) => {
      Alert.success({ message: t('update user successfully') });

      const { memberId, roleId }: { memberId: string; roleId: number } = body;
      queryClient.setQueryData(['getListUser', dataSearch, type], {
        data: items.map((item) => {
          if (item.userId === memberId) {
            item.roleId = roleId;
          }
          return item;
        }),
        total,
      });
    },
  });

  const { mutate: deleteUser } = useMutation(userService.deleteUser, {
    onSuccess: () => {
      Alert.success({ message: t('Delete user successfully') });

      refetch();
    },
  });

  const TablePagination = () => (
    <Pagination
      count={Math.ceil(total / 10)}
      page={dataSearch.page + 1}
      onChange={(event, nextPage) => {
        onSearchChange({ page: nextPage - 1 });
      }}
    />
  );

  return (
    <>
      <div className='flex items-center flex-wrap mb-6'>
        <IconButton>
          <GroupOutlined />
        </IconButton>
        <Typography variant='h6'>{t('Users list')}</Typography>

        <Tag color='orange' className='ml-3'>
          {USER_RANKS.find((item) => item.code === type)?.name}
        </Tag>

        <div className='flex-1' />
        <LoadingButton color='inherit' startIcon={<RefreshOutlined />} loading={isFetching} onClick={() => refetch()}>
          {t('Refresh')}
        </LoadingButton>
      </div>

      <UserSearch onSearch={onSearchChange} />
      <Paper className='flex justify-between items-center flex-wrap p-4 mb-6'>
        <Typography variant='subtitle1' color='green'>
          {total} {t('users matched')}
        </Typography>
        <TablePagination />
      </Paper>

      <Paper className='flex justify-between items-center flex-wrap p-4 mb-3'>
        <Typography variant='subtitle1' color='secondary'>
          {t('Select')}: {dataSelect.length} record{dataSelect.length > 1 ? 's' : ''}
        </Typography>
        <Tooltip title={t('Clear all')}>
          <IconButton size='small' onClick={() => setDataSelect([])}>
            <ClearAll />
          </IconButton>
        </Tooltip>
      </Paper>

      <Paper className='mb-6'>
        <Table
          scroll={{ y: 800, x: 800 }}
          bordered={false}
          loading={isFetching}
          rowKey={(record) => record.userId}
          dataSource={items}
          pagination={false}
          rowSelection={{
            selectedRowKeys: dataSelect.map((record) => record.userId),
            preserveSelectedRowKeys: true,
            onChange: (selectedRowKeys: React.Key[], selectedRows: UserType[]) => {
              setDataSelect(selectedRows);
            },
          }}
          columns={[
            {
              title: 'Username',
              dataIndex: 'username',
              width: 240,
              render: (_, record) => (
                <div className='flex items-center gap-3'>
                  <Avatar src={record.avatarUrl} />
                  <Typography>{record.username}</Typography>
                </div>
              ),
            },
            {
              title: 'Role',
              dataIndex: 'roleId',
              width: 160,
              render: (_, record) =>
                isRanking ? (
                  USER_ROLES.find((item) => item.code === record.roleId)?.name
                ) : (
                  <Select
                    value={record.roleId}
                    onChange={(value) => {
                      updateRole({ memberId: record.userId, roleId: value });
                    }}
                    style={{ width: 180 }}
                  >
                    {USER_ROLES.map((item) => (
                      <Select.Option key={item.id} value={item.code}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                ),
            },
            {
              title: 'Type',
              dataIndex: 'userType',
              width: 120,
              render: (_, record) => USER_TYPES.find((item) => item.code === record.userType)?.name,
            },
            {
              title: isRanking ? 'Total Refer' : 'Created at',
              dataIndex: 'totalRefer',
              width: 120,
              render: (_, record) => (isRanking ? record.totalRefer : toFormat(record.createdAt)),
            },
            {
              dataIndex: '',
              align: 'right',
              width: 40,
              render: (_, record) => (
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <List component={Paper}>
                      <Link to={privateRoute.userView.url(record.userId)}>
                        <ListItemButton>
                          <ListItemIcon>
                            <DirectionsOutlined color='secondary' />
                          </ListItemIcon>
                          <ListItemText primary={t('View detail')} className='text-black/80' />
                        </ListItemButton>
                      </Link>

                      {!isRanking && (
                        <ListItemButton
                          onClick={() => {
                            Modal.confirm({
                              title: t('Confirmation'),
                              content: t('Are you sure you want to delete this user?'),
                              onOk: () => deleteUser({ memberId: record.userId, memberName: record.username }),
                              okText: 'Delete',
                              okType: 'danger',
                            });
                          }}
                        >
                          <ListItemIcon>
                            <DeleteOutlined color='error' />
                          </ListItemIcon>
                          <ListItemText primary={t('Delete')} />
                        </ListItemButton>
                      )}
                    </List>
                  }
                >
                  <IconButton size='small'>
                    <MoreVert />
                  </IconButton>
                </Dropdown>
              ),
            },
          ]}
        />
      </Paper>

      {total > 0 && (
        <div className='flex justify-center'>
          <TablePagination />
        </div>
      )}
    </>
  );
};

export default UserList;
