import {
  AddOutlined,
  CloudUpload,
  NavigateBeforeOutlined
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { IconButton, Paper, TextField, Typography} from '@mui/material';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, Form, Input, Row, Select, Tabs, Upload, Checkbox } from 'antd';
import { Alert, InputNumber, RichTextEditor} from 'components';
import { DateTime } from 'luxon';
import { useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { privateRoute } from 'routes';
import { candidateService, fileService } from 'services';
import {CANDIDATE_LEVELS, CANDIDATE_STATUS_TYPES, LANGUAGES, SKILLS} from 'utils/constants';
import {useSelector} from "react-redux";
import {profileSelector} from "../../reducers/profile";
import {decode} from "html-entities";
import SystemSuggestionJob from "../Refer/SystemSuggestionJob";

const { TabPane } = Tabs;

const CandidateUpdate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { isSuper , isAdmin } = useSelector(profileSelector);

  const [form] = Form.useForm();
  const { urlCv } = form.getFieldsValue();

  const [urlCvUpload, setUrlCvUpload] = useState('');

  const [isUpload, setUpload] = useState(!id);
  const [dayOfBirth, setDayOfBirth] = useState<number>();
  const [calendarReminder, setCalendarReminder] = useState<number>();
  const [note, setNote] = useState('');
  const [isNegotiate, setIsNegotiate] = useState<boolean>();


  const { data } = useQuery(['getInfoCv', id], () => candidateService.getInfoCv({ id }), {
    onSuccess: (data) => {
      const { dayOfBirth, calendarReminder, note, salaryExpectMin, salaryExpectMax } = data;
      if (dayOfBirth) setDayOfBirth(dayOfBirth);
      if (calendarReminder) setCalendarReminder(calendarReminder);
      if (note) setNote(decode(note));
      if (salaryExpectMin !== 0 && salaryExpectMax !== 0){
        setIsNegotiate(true)
      }else {
        setIsNegotiate(false)
      }

      form.setFieldsValue({ ...data });
    },
    enabled: !!id,
  });

//   const { mutate: resumeParser, isLoading: isLoadingParser } = useMutation(fileService.resumeParser);

  const { mutate: uploadFile, isLoading: isLoadingUpload } = useMutation(fileService.uploadFile, {
    onSuccess: (data) => {
      const { url, resume } = data;
      if(url) setUrlCvUpload(url)
      form.setFieldsValue({
        urlCv: [url],
        candidateName: resume?.candidateName,
        phone: resume?.phone,
        email: resume?.email,
        address: resume?.address
      });

    },
    onSettled: () => {
      setUpload(false);
    },
  });


  const { mutate: updateCv, isLoading: isLoadingUpdate } = useMutation(
    id ? candidateService.updateCv : candidateService.createCv,
    {
      onSuccess: (data) => {
        if (id) {
          Alert.success({ message: t('Update candidate successfully') });
          navigate(privateRoute.candidateUpdate.url(id));
        } else {
          if (data.status === 1){
            Alert.success({ message: t('Create candidate successfully') });
            navigate(privateRoute.candidateList.path);
          }else {
            Alert.error({ message: data.message });
          }
        }
      },
    },
  );

  const handleClickCheckBoxSalary = () => {
    setIsNegotiate(!isNegotiate)
    form.resetFields(["salaryExpectMin", "salaryExpectMax"])
  }

  const handleClickUpdate = () => {
    form.validateFields().then((values) => {
      updateCv({
        id,
        isNegotiate: isNegotiate,
        ...values,
        ...{ dayOfBirth, calendarReminder, note },
      });
    });
  };

  return (
    <>
      <div className='flex items-center mb-6'>
        <Link to={privateRoute.candidateList.path}>
          <IconButton>
            <NavigateBeforeOutlined />
          </IconButton>
        </Link>
        <Typography variant='h6'>{id ? t('Update candidate') : t('Create candidate')}</Typography>
      </div>

      {isUpload ? (
        <Upload.Dragger
          multiple
          accept='application/pdf'
          showUploadList={false}
          customRequest={({ file }) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileType', 'cv');
            uploadFile(formData);

//             resumeParser(formData, {
//               onSettled: (data = {}) => {
//                 const { name, phone, address, email, website, ...others } = data?.persional_details;
//                 form.setFieldsValue({
//                   ...others,
//                   candidateName: name,
//                 });
//
//                 const formDataUpload = new FormData();
//                 formDataUpload.append('file', file);
//                 formDataUpload.append('fileType', 'cv');
//                 formDataUpload.append('email', email[0]);
//                 formDataUpload.append('phone', phone[0]);
//                 formDataUpload.append('website', website[0]);
//
//                 uploadFile(formDataUpload);
//               },
//             });
          }}
        >
          <Typography variant='h4' color='textSecondary'>
            {t('Upload candidate profile here')}
          </Typography>
          <CloudUpload color='action' fontSize='large' />
          <Typography variant='body2' color='textSecondary'>
            {t('After the file is uploaded, the system will get some basic information')}.
          </Typography>
          <Typography variant='body2' color='textSecondary' className='mb-12'>
            {t('Please complete the missing information')}.
          </Typography>
          <LoadingButton
            disableElevation
            variant='contained'
            color='secondary'
            loading={isLoadingUpload}
            startIcon={<CloudUpload />}
          >
            {t('Upload FIle (.PDF only)')}
          </LoadingButton>
        </Upload.Dragger>
      ) : (
          <div>
            <Paper className='p-4'>
              <Form form={form} layout='vertical'>
                <Row gutter={24}>
                  <Col lg={12} span={24}>
                    <Row gutter={24}>
                      <Col md={12} span={24}>
                        <Form.Item
                            name='candidateName'
                            label={t('Name')}
                            rules={[{ required: true, message: t('Name is required') }]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item name='address' label={t('Address')}>
                          <Input />
                        </Form.Item>
                        <Form.Item name='level' label={t('Level')}>
                          <Select>
                            {CANDIDATE_LEVELS.map((item) => (
                                <Select.Option key={item.id} value={item.code}>
                                  {item.name}
                                </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item name='email'
                                   label={t('Email')}
                                   rules={[{ required: true, message: t('email is required') }]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item name='phone'
                                   label={t('Phone')}
                                   rules={[{ required: true, message: t('phone is required') }]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item name='skill'
                                   label={t('Skill')}
                                   rules={[{ required: true, message: t('skill is required') }]}
                        >
                          <Select mode='tags'>
                            {SKILLS.map((skill) => (
                                <Select.Option key={skill} value={skill}>
                                  {skill}
                                </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <div>
                          <Typography>{t('Salary Expect')}</Typography>
                          <Checkbox checked={isNegotiate} onChange={handleClickCheckBoxSalary}>
                            {t('Negotiate') }
                          </Checkbox>
                        </div>

                        {isNegotiate ? (
                           <Row gutter={24}>
                              <Col md={12} span={24}>
                                <Form.Item name='salaryExpectMin' style={{backgroundColor: 'transparent', border: '1px solid #c8cbcd'}}>
                                  <InputNumber
                                      readOnly
                                      customInput={Input}
                                      thousandSeparator
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={12} span={24}>
                                <Form.Item name='salaryExpectMax' style={{backgroundColor: 'transparent', border: '1px solid #c8cbcd'}}>
                                  <InputNumber
                                      readOnly
                                      customInput={Input}
                                      thousandSeparator
                                  />
                                </Form.Item>
                              </Col>
                           </Row>
                        ):(
                            <Row gutter={24}>
                              <Col md={12} span={24}>
                                <Form.Item name='salaryExpectMin'>
                                  <InputNumber
                                      customInput={Input}
                                      thousandSeparator
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={12} span={24}>
                                <Form.Item name='salaryExpectMax'>
                                  <InputNumber
                                      customInput={Input}
                                      thousandSeparator
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                        )}

                        { (isSuper || isAdmin) && (
                            <Form.Item name='price' label={t('Price')}>
                              <InputNumber
                                  customInput={Input}
                                  thousandSeparator
                                  addonAfter={
                                    <div style={{display: 'inline-flex',
                                      flexDirection: 'row',
                                      alignItems: 'center'
                                    }}>
                              <span style={{
                                display: 'flex',
                                background: '#f6b300',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color:'#ff0',
                                borderRadius: '100rem',
                                border: '2px solid #e78b03',
                                width: 20,
                                height: 20,

                              }}>G</span>
                                    </div>
                                  }
                              />
                            </Form.Item>
                        )
                        }
                      </Col>
                      <Col md={12} span={24}>
                        <Form.Item label={t('Date of Birth')}>
                          <DesktopDatePicker
                              value={dayOfBirth ? DateTime.fromMillis(dayOfBirth) : null}
                              onChange={(value) => value?.isValid && setDayOfBirth(value.toMillis())}
                              renderInput={(params) => <TextField size='small' style={{ marginTop: -3 }} {...params} />}
                              inputFormat='dd/MM/yyyy'
                          />
                        </Form.Item>
                        <Form.Item name='language' label={t('Language')}>
                          <Select>
                            {LANGUAGES.map((item) => (
                                <Select.Option key={item.id} value={item.code}>
                                  {item.name}
                                </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item name='position' label={t('Position')}>
                          <Input />
                        </Form.Item>
                        <Form.Item name='status' label={t('Status')} initialValue={CANDIDATE_STATUS_TYPES[0].code}>
                          <Select>
                            {CANDIDATE_STATUS_TYPES.map((item) => (
                                <Select.Option key={item.id} value={item.code}>
                                  {item.name}
                                </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item label={t('Calendar Reminder')}>
                          <DesktopDateTimePicker
                              value={calendarReminder ? DateTime.fromMillis(calendarReminder) : null}
                              onChange={(value) => value?.isValid && setCalendarReminder(value.toMillis())}
                              renderInput={(params) => <TextField size='small' style={{ marginTop: -3 }} {...params} />}
                              inputFormat='dd/MM/yyyy HH:mm'
                              minDate={DateTime.now()}
                          />
                        </Form.Item>
                        <Form.Item name='note' label={t('Note')}>
                          {/*<Input.TextArea />*/}
                          <RichTextEditor value={note} onEditorChange={setNote} />
                        </Form.Item>
                        <Form.Item name='urlCv' hidden>
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Col lg={12} span={24} style={{ minHeight: 600, marginBottom: 12 }}>
                    <Tabs animated type='card' className='CV-Preview'>
                      {(id ? (urlCv as string[]) ?? [] : [urlCvUpload]).map((url, index) => (
                          <TabPane style={{ minHeight: 600, width: '100%' }} key={index} tab={`CV ${index + 1}`}>
                            <embed src={url} style={{ width: '100%', height: '100%' }} />
                          </TabPane>
                      ))}
                    </Tabs>
                  </Col>
                </Row>
              </Form>

              <LoadingButton
                  variant='contained'
                  color='success'
                  startIcon={<AddOutlined />}
                  loading={isLoadingUpdate}
                  onClick={handleClickUpdate}
              >
                {id ? t('Update candidate') : t('Create candidate')}
              </LoadingButton>
            </Paper>
            {data && (
                <SystemSuggestionJob cv={data!} />
            )}
          </div>
      )
      }
    </>
  );
};

export default CandidateUpdate;
