import {
  AssignmentOutlined,
  ClearAll,
  DateRangeOutlined,
  DeleteOutlined,
  DirectionsOutlined,
  MoreVert,
  RefreshOutlined,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Pagination,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { useMutation, useQuery } from '@tanstack/react-query';
import {Dropdown, Modal, Select, Table, Tag} from 'antd';
import { Alert } from 'components';
import { useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { privateRoute } from 'routes';
import { jobService, queryClient, referService } from 'services';
import { REFERRAL_STATUS_TYPES } from 'utils/constants';
import { toRelative } from 'utils/converter';
import { ReferRow, ReferSearch } from './components';

type ReferListProps = {
  showTitle?: boolean;
  showSearch?: boolean;
  searchParams?: any;
};

const ReferList = ({ showTitle = true, showSearch = true, searchParams }: ReferListProps) => {
  const { t } = useTranslation();
  const { isSuper, isAdmin, isCompany } = useSelector(profileSelector);

  const [dataSearch, onSearchChange] = useSearch(searchParams);
  const [dataSelect, setDataSelect] = useState<CvApplyType[]>([]);

  const {
    data: { data: items = [], total = 0 } = {},
    isFetching,
    refetch,
  } = useQuery(['getListJobCvApplied', dataSearch], () => referService.getListJobCvApplied(dataSearch), {
    keepPreviousData: true,
  });

  const { mutate: applyCvToJob } = useMutation(jobService.applyCvToJob, {
    onSuccess: (data, body) => {
      const { id, status, interviewDate }: { id: number; status: number; interviewDate: number } = body;
      queryClient.setQueryData(['getListJobCvApplied', dataSearch], {
        data: items.map((item) => {
          if (item.id === id) {
            item.status = status;
            item.interviewDate = interviewDate;
          }
          return item;
        }),
        total,
      });
    },
  });

  const { mutate: deleteApplyCvToJob } = useMutation(jobService.deleteApplyCvToJob, {
    onSuccess: () => {
      Alert.success({ message: t('Delete referral successfully') });

      refetch();
    },
  });

  const TablePagination = () => (
    <Pagination
      count={Math.ceil(total / 10)}
      page={dataSearch.page + 1}
      onChange={(event, nextPage) => {
        onSearchChange({ page: nextPage - 1 });
      }}
    />
  );

  return (
    <>
      {showTitle && (
        <div className='flex items-center mb-6'>
          <IconButton>
            <DateRangeOutlined />
          </IconButton>
          <Typography variant='h6'>{t('Referral list')}</Typography>

          <div className='flex-1' />
          <LoadingButton color='inherit' startIcon={<RefreshOutlined />} loading={isFetching} onClick={() => refetch()}>
            {t('Refresh')}
          </LoadingButton>
        </div>
      )}

      {showSearch && (
        <>
          <ReferSearch onSearch={onSearchChange} />
          <Paper className='flex justify-between items-center flex-wrap p-4 mb-6'>
            <Typography variant='subtitle1' color='green'>
              {total} {t('refers matched')}
            </Typography>
            <TablePagination />
          </Paper>
        </>
      )}

      <Paper className='flex justify-between items-center flex-wrap p-4 mb-3'>
        <Typography variant='subtitle1' color='secondary'>
          {t('Select')}: {dataSelect.length} record{dataSelect.length > 1 ? 's' : ''}
        </Typography>
        <Tooltip title={t('Clear all')}>
          <IconButton size='small' onClick={() => setDataSelect([])}>
            <ClearAll />
          </IconButton>
        </Tooltip>
      </Paper>

      <Paper className='mb-6'>
        <Table
          scroll={{ x: 800 }}
          bordered={false}
          loading={isFetching}
          rowKey={(record) => record.id}
          dataSource={items}
          pagination={false}
          rowSelection={{
            selectedRowKeys: dataSelect.map((record) => record.id),
            preserveSelectedRowKeys: true,
            onChange: (selectedRowKeys: React.Key[], selectedRows: CvApplyType[]) => {
              setDataSelect(selectedRows);
            },
          }}
          columns={[
            {
              title: 'Candidate',
              dataIndex: 'name',
              width: 180,
              render: (_, record) => record.candidateName,
            },
            { title: 'Manager Candidate', dataIndex: 'nameUserCreate', width: 180 },
            { title: 'Company', dataIndex: 'company', width: 120 },
            { title: 'Job', dataIndex: 'title', width: 240,
              render: (_, record) =>(
                  <Link to={privateRoute.jobView.url(record.idJob)}>
                    <Typography >
                      {record.title}
                    </Typography>
                  </Link>
              )
            },
            {
              title: 'Interview Date',
              dataIndex: 'interviewDate',
              width: 240,
              render: (_, record) => (
                <DesktopDateTimePicker
                  disabled={!isSuper && !isAdmin && !isCompany}
                  value={record.interviewDate ? DateTime.fromMillis(record.interviewDate) : null}
                  onChange={(value) => {
                    if (value?.isValid) {
                      applyCvToJob({
                        id: record.id,
                        idJob: record.idJob,
                        idCv: record.idCv,
                        status: record.status,
                        interviewDate: value.toMillis(),
                      });
                    }
                  }}
                  renderInput={(params) => <TextField size='small' {...params} />}
                  inputFormat='dd/MM/yyyy HH:mm'
                  minDate={DateTime.now()}
                />
              ),
            },
            {
              title: 'Status',
              dataIndex: 'status',
              width: 200,
              render: (_, record) => {
                const status = REFERRAL_STATUS_TYPES.find((item) => item.code === record.status);
                return isSuper || isAdmin || isCompany ?
                        (<Select
                          disabled={!isSuper && !isAdmin && !isCompany}
                          value={record.status}
                          onChange={(value) => {
                          applyCvToJob({
                          id: record.id,
                          idJob: record.idJob,
                          idCv: record.idCv,
                          status: value,
                          interviewDate: record.interviewDate,
                        });
                        }}
                        style={{ width: 180, color: status?.color, fontWeight: 'bold' }}
                        >
                        {REFERRAL_STATUS_TYPES.map((item) => (
                          <Select.Option key={item.id} value={item.code} style={{ color: item.color, fontWeight: 'bold' }}>
                            {item.name}
                          </Select.Option>
                        ))}
                        </Select>) : (
                            <Tag color={status?.color}>{status?.name}</Tag>
                        )
              },
            },
            {
              title: 'Created',
              dataIndex: 'createTime',
              width: 120,
              sorter: true,
              render: (_, record) => toRelative(record.createTime),
            },
            {
              dataIndex: '',
              align: 'right',
              width: 40,
              render: (_, record) => (
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <List component={Paper}>
                      <a hidden={!record.urlCv[0]} href={record.urlCv[0]} target='_blank'>
                        <ListItemButton>
                          <ListItemIcon>
                            <AssignmentOutlined />
                          </ListItemIcon>
                          <ListItemText primary={t('View CV')} className='text-black/80' />
                        </ListItemButton>
                      </a>
                      <Link to={privateRoute.referView.url(record.id)}>
                        <ListItemButton>
                          <ListItemIcon>
                            <DirectionsOutlined color='secondary' />
                          </ListItemIcon>
                          <ListItemText primary={t('View detail')} className='text-black/80' />
                        </ListItemButton>
                      </Link>
                      <ListItemButton
                        onClick={() => {
                          Modal.confirm({
                            title: t('Confirmation'),
                            content: t('Are you sure you want to delete this record?'),
                            onOk: () => deleteApplyCvToJob({ idJob: record.idJob, idCv: record.idCv }),
                            okText: 'Delete',
                            okType: 'danger',
                          });
                        }}
                      >
                        <ListItemIcon>
                          <DeleteOutlined color='error' />
                        </ListItemIcon>
                        <ListItemText primary={t('Delete')} />
                      </ListItemButton>
                    </List>
                  }
                >
                  <IconButton size='small'>
                    <MoreVert />
                  </IconButton>
                </Dropdown>
              ),
            },
          ]}
          expandable={{
            expandedRowRender: (record) => <ReferRow refer={record} />,
          }}
        />
      </Paper>

      {total > 0 && (
        <div className='flex justify-center'>
          <TablePagination />
        </div>
      )}
    </>
  );
};

export default ReferList;
