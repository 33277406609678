import {
  AssignmentIndOutlined,
  DashboardOutlined,
  DateRangeOutlined,
  GroupOutlined,
  LocationCityOutlined,
  WorkOutlineOutlined,
} from '@mui/icons-material';
import { Hidden, List, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { privateRoute } from 'routes';
import { JOB_TYPIES, USER_RANKS, CANDIDATE_TYPIES } from 'utils/constants';

type MenuItemProps = {
  visible?: boolean;
  path: string;
  name: string;
  icon: JSX.Element;
};

const MenuItem = ({ visible = true, path, name, icon }: MenuItemProps) => {
  const location = useLocation();
  return visible ? (
    <ListItemButton
      component={Link}
      to={path}
      classes={{
        root: 'rounded',
        selected: 'bg-black/5',
      }}
      selected={location.pathname.startsWith(path)}
    >
      <ListItemIcon className='min-w-[unset] mr-2'>{icon}</ListItemIcon>
      <ListItemText className='text-black' primary={name} />
    </ListItemButton>
  ) : (
    <></>
  );
};

const Menu = () => {
  const { t } = useTranslation();
  const { isSuper, isAdmin, isCompany, isCandide } = useSelector(profileSelector);

  const { home, jobList, companyList, candidateList, referList, userList, marketPlace, unlockCV } = privateRoute;

  return (
    <>
      <MenuItem path={home.path} name={t('Home')} icon={<DashboardOutlined />} />

      <Hidden mdUp>
        <MenuItem {...jobList} name={t('Job')} icon={<WorkOutlineOutlined />} />
      </Hidden>
      <Hidden smDown>
        <Dropdown
          trigger={['hover']}
          placement='bottomLeft'
          overlay={
            <List disablePadding component={Paper} style={{ marginTop: 8 }}>
              {JOB_TYPIES.map((item) => (
                <ListItemButton
                  key={item.id}
                  component={Link}
                  to={{
                    pathname: jobList.path,
                    search: new URLSearchParams({ type: item.code }).toString(),
                  }}
                >
                  <Typography variant='button'>{item.name}</Typography>
                </ListItemButton>
              ))}
            </List>
          }
        >
          <span>
            <MenuItem {...jobList} name={t('Job')} icon={<WorkOutlineOutlined />} />
          </span>
        </Dropdown>
      </Hidden>

      <MenuItem
        {...companyList}
        name={t('Company')}
        icon={<LocationCityOutlined />}
        visible={isSuper || isAdmin || isCompany}
      />

      <Hidden mdUp>
          <MenuItem {...marketPlace} name={t('Talent Pool')} icon={<AssignmentIndOutlined />} visible={!isCompany} />
       </Hidden>
      <Hidden smDown>
            <Dropdown
                trigger={['hover']}
                placement='bottomLeft'
                overlay={
                    <List disablePadding component={Paper} style={{ marginTop: 8 }}>
                        {CANDIDATE_TYPIES.map((item) => (
                            <ListItemButton
                                key={item.id}
                                component={Link}
                                to={{
                                    pathname: candidateList.path,
                                }}
                            >
                                <Typography variant='button'>{item.name}</Typography>
                            </ListItemButton>
                        ))}
                        <ListItemButton
                            key='2'
                            component={Link}
                            to={{
                                pathname: unlockCV.path,
                            }}
                        >
                            <Typography variant='button'>unlock candidates</Typography>
                        </ListItemButton>
                    </List>
                }
            >
            <span>
                <MenuItem {...marketPlace} name={t('Talent Pool')} icon={<GroupOutlined />} visible={!isCompany} />
            </span>
            </Dropdown>
        </Hidden>

      <MenuItem {...referList} name={t('Refer history')} icon={<DateRangeOutlined />} visible={!isCandide} />

      <Hidden mdUp>
        <MenuItem {...userList} name={t('User')} icon={<GroupOutlined />} visible={isSuper || isAdmin} />
      </Hidden>
      <Hidden smDown>
        <Dropdown
          trigger={['hover']}
          placement='bottomLeft'
          overlay={
            <List disablePadding component={Paper} style={{ marginTop: 8 }}>
              {USER_RANKS.map((item) => (
                <ListItemButton
                  key={item.id}
                  component={Link}
                  to={{
                    pathname: userList.path,
                    search: new URLSearchParams({ type: item.code }).toString(),
                  }}
                >
                  <Typography variant='button'>{item.name}</Typography>
                </ListItemButton>
              ))}
            </List>
          }
        >
          <span>
            <MenuItem {...userList} name={t('User')} icon={<GroupOutlined />} visible={isSuper || isAdmin} />
          </span>
        </Dropdown>
      </Hidden>
    </>
  );
};

export default Menu;
