import {
  AnalyticsOutlined, AssignmentIndOutlined, CreditScoreOutlined,
  HelpCenterOutlined, HistoryRounded,
  Logout,
  MenuOutlined
} from '@mui/icons-material';
import {
  Avatar,
  AppBar,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Toolbar, ListItemIcon,
} from '@mui/material';
import { Dropdown } from 'antd';
import { Darkmode, LanguageBar } from 'components';
import { AppMenu } from 'containers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { profileSelector, signOut } from 'reducers/profile';
import { privateRoute } from 'routes';
import { NotificationPopover } from 'views/Notification';
import {CurrentCoin} from "views/Package/components";
import SettingsIcon from '@mui/icons-material/Settings';

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { t } = useTranslation();
  const { avatarUrl } = useSelector(profileSelector);

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  useEffect(() => {
    setIsOpenMenu(false);
  }, [location]);

  return (
    <AppBar square position='sticky' elevation={1}>
      <Toolbar>
        <Hidden smDown>
          <List className='flex flex-row gap-2'>
            <AppMenu />
          </List>
        </Hidden>
        <Hidden mdUp>
          <IconButton onClick={() => setIsOpenMenu(true)}>
            <MenuOutlined />
          </IconButton>
          <Drawer open={isOpenMenu} onClose={() => setIsOpenMenu(false)}>
            <List style={{ width: 240 }}>
              <AppMenu />
            </List>
          </Drawer>
        </Hidden>

        <div className='flex-1 flex justify-end gap-2 mr-2'>
          <LanguageBar init />
          <Darkmode />
          <NotificationPopover />
          <CurrentCoin />
        </div>
        <Dropdown
          trigger={['click']}
          placement='bottomRight'
          overlay={
            <List disablePadding component={Paper} style={{ width: 200, marginTop: 8 }}>
              <ListItem button component={Link} to={privateRoute.profile.path}>
                <ListItemIcon style={{minWidth: 30}}>
                  <AssignmentIndOutlined />
                </ListItemIcon>
                <ListItemText>{t('Profile')}</ListItemText>
              </ListItem>
              {/* <ListItem button component={Link} to={privateRoute.settingsAccount.path}>
                <ListItemIcon style={{minWidth: 30}}>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText>{t('Setting Account')}</ListItemText>
              </ListItem> */}
              <ListItem button component={Link} to={privateRoute.package.path}>
                <ListItemIcon style={{minWidth: 30}}>
                  <CreditScoreOutlined />
                </ListItemIcon>
                <ListItemText>{t('Top up credit')}</ListItemText>
              </ListItem>
              <Divider />
              <ListItem button component={Link} to={privateRoute.leaderBoardView.path}>
                <ListItemIcon style={{minWidth: 30}}>
                  <AnalyticsOutlined />
                </ListItemIcon>
                <ListItemText>{t('LeaderBoard')}</ListItemText>
              </ListItem>
              <ListItem button component={Link} to={privateRoute.helpCenter.url}>
                <ListItemIcon style={{minWidth: 30}}>
                  <HelpCenterOutlined />
                </ListItemIcon>
                <ListItemText>{t('Help center')}</ListItemText>
              </ListItem>
              <ListItem button component={Link} to={privateRoute.history.path}>
                <ListItemIcon style={{minWidth: 30}}>
                  <HistoryRounded />
                </ListItemIcon>
                <ListItemText>{t('History')}</ListItemText>
              </ListItem>
              <Divider />
              <ListItem
                button
                onClick={() => {
                  dispatch(signOut());
                }}
              >
                <ListItemIcon style={{minWidth: 30}}>
                  <Logout />
                </ListItemIcon>
                <ListItemText>{t('Logout')}</ListItemText>
              </ListItem>
            </List>
          }
        >
          <IconButton style={{ padding: 8 }}>
            <Avatar variant='circular' src={avatarUrl} style={{ width: 36, height: 36 }} />
          </IconButton>
        </Dropdown>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
