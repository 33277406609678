import { QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import { AppTheme } from 'containers';
import { SnackbarProvider } from 'notistack';
import { queryClient } from 'services';

const Container = ({ children }: any) => {
  return (
    <ConfigProvider componentSize='large'>
      <SnackbarProvider variant='success' anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <QueryClientProvider client={queryClient}>
          <AppTheme>{children}</AppTheme>
        </QueryClientProvider>
      </SnackbarProvider>
    </ConfigProvider>
  );
};

export default Container;
