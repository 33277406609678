import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselCompany = () => {

    const settings = {
        className: 'js-carousel u-carousel-v12',
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        centerMode: true,
        responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 5
            }
        }, {
            breakpoint: 992,
            settings: {
                slidesToShow: 4
            }
        }, {
            breakpoint: 768,
            settings: {
                slidesToShow: 3
            }
        }, {
            breakpoint: 576,
            settings: {
                slidesToShow: 3
            }
        }, {
            breakpoint: 446,
            settings: {
                slidesToShow: 2
            }
        }]
    };

    return (
        <Slider
            {...settings}
        >
            {['savycom.jpeg', 'ITS_GLOBAL.png', 'ITSOL.png']
                .concat(['javis.jpg', 'SOTA_TEK.png', 'VAIS.png', 'JFC.png'])
                .concat(['raikus.png', 'rikai.png'])
                .map((item, index) => (
                    <div className='js-slide u-block-hover'>
                        <img key={index} src={require(`assets/companies/${item}`)} className='h-[60px]' />
                    </div>
                ))}

        </Slider>
    );
};

export default CarouselCompany;