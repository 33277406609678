import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import React, { useState } from 'react';
import { jobService } from 'services';
import { mapSourceJobs } from './utils';

interface ISourceOption {
  label: string;
  value: number;
}

const SelectJob = ({...props}) => {
  const [jobsList, setJobsList] = useState<ISourceOption[]>([]);
  const { isFetching } = useQuery(['getListInfoJob'], () => jobService.getListInfoJob({ search: {} }), {
    keepPreviousData: true,
    onSuccess: (res) => {
      setJobsList(mapSourceJobs(res.data));
    },
  });
  return (
    <Select
      loading={isFetching}
      style={{ width: '100%' }}
      placeholder='Job'
      options={jobsList}
      onChange={(value: any) => props.onChange(value)}
      { ...props }
    />
  );
};

export default SelectJob;
