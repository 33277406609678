import {Avatar, Grid, Paper, styled, Typography} from "@mui/material";
import {Col, Row, Tag} from "antd";
import {JOB_STATUS_TYPES} from "../../../utils/constants";
import {formatBonus, formatListToString, toFormat} from "../../../utils/converter";
import {useQuery} from "@tanstack/react-query";
import {jobService} from "../../../services";
import {useTranslation} from "react-i18next";

const JobPublic = () => {
    const { t } = useTranslation();

    const { data: { data: items = [] } = {} } = useQuery(
        ['getPublicJob'], () => jobService.getPublicJob({ page: 0 })
    );


    const StyledPaper = styled(Paper)`
            background-color: #f5f4f8;
            transition: background-color 0.3s;

            &:hover {
                background-color: #ffffff;
            }
    `;

    return(
        <>
            <Grid container spacing={2} className='mb-6 mt-6'>
                {items.map((job) => {
                    const status = JOB_STATUS_TYPES.find((item) => item.code === job.status);
                    return (
                        <Grid item xs={6} md={6}>
                            <StyledPaper className='flex p-4' key={job.id}>
                                <Avatar src={job.company?.image} style={{ width: 120, height: 120, margin: '0px 24px 12px 0px' }}>
                                    <Avatar src='/gap_logo.png' style={{ width: 120, height: 120 }} />
                                </Avatar>

                                <div className='flex-1'>
                                    <Typography component='span' variant='h6' color='primary' className='mr-2'>
                                        {job.title}
                                    </Typography>

                                    <Tag color={status?.color}>{status?.name}</Tag>

                                    {job.bonus > 0 && (
                                        <Typography variant='h6' color='textSecondary'>
                                            {t('Bonus')}:{' '}
                                            <span style={{ color: job.bonus > 0 ? 'green' : 'silver' }}>{formatBonus(job.bonus)}</span>
                                        </Typography>
                                    )}
                                    <Row gutter={12}>
                                        <Col flex={1}>
                                            <Typography>
                                                {t('Workplace')}: {formatListToString(job.workplace)}
                                            </Typography>
                                        </Col>
                                    </Row>
                                    <Row gutter={12}>
                                        <Col flex={1}>
                                            <Typography>
                                                {t('Deadline')}: {toFormat(job.deadline)}
                                            </Typography>
                                        </Col>
                                    </Row>
                                    <Row gutter={12}>
                                        <Col flex={1}>
                                            <Typography color='textSecondary'>
                                                {t('Level')}: <span style={{ color: 'black' }}>{job.level}</span>
                                            </Typography>
                                        </Col>
                                    </Row>
                                </div>
                            </StyledPaper>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    )
};

export default JobPublic