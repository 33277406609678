import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { authRoute, privateRoute } from 'routes';

const AuthLayout = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(profileSelector);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(privateRoute.home.path, { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return (
    <div className='min-h-screen'>
      <Routes>
        {Object.values(authRoute).map((item) => (
          <Route key={item.path} path={item.path} element={item.element} />
        ))}
        <Route path='*' element={<Navigate to={authRoute.landing.url} replace />} />
      </Routes>
    </div>
  );
};

export default AuthLayout;
