import { Select } from 'antd';
import React, { useState, useEffect } from 'react'
import { mapSourceCVs } from './utils';
import {referService} from 'services'
import { useQuery } from '@tanstack/react-query';

interface ISourceOption {
    label: string;
    value: string;
  }
const SelectCV = ({...props}) => {
    const [cvsList, setCvsList] = useState<ISourceOption[]>([]);
    const {
        isFetching,refetch
      } = useQuery(['getListJobCvApplied'], () => referService.getListJobCvApplied({"idJob": props.jobId}), {
        keepPreviousData: true,
        onSuccess: (res) => {
            setCvsList(mapSourceCVs(res.data));
          },
      });
      useEffect(() =>{
        refetch()
      },[refetch,props.jobId])
    return <Select 
      loading={isFetching} 
      style={{ width: '100%' }} 
      placeholder='Candidates' 
      mode="tags"
      value={props.value} 
      options={cvsList} 
      onChange={(value: any) => props.onChange(value)}
      { ...props }
      />    
}

export default SelectCV