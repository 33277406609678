import { useQuery } from '@tanstack/react-query';
import { Alert } from 'antd';
import { IListBreakNewsData } from 'models/Ads';
import React, { useState } from 'react';
import Marquee from 'react-fast-marquee';
import { adsService } from 'services';

const HorizontalTextScroll = () => {
  const [newsItems, setNewsItems] = useState<string[]>([])
  useQuery(
    ['getListBreakNewsPublic'],
    () =>
        adsService.getListBreakNewsPublic(),
    {
      keepPreviousData: true,
      onSuccess: (res) => {
        const filtered = res.data.filter((item:IListBreakNewsData) => item.active === true)
        setNewsItems(mapView(filtered));
      },
    },
  );
  const mapView = (input: any) => {
    var output:string[] = []
    for(let i = 0; i < input.length; i ++){
      output.push(input[i].content)
    }
    return output
  }
  return (
    <Alert
      banner
      type="info"
      message={
        <Marquee pauseOnHover gradient={false}>
          <ul className="news-list flex items-center gap-5">
            {newsItems.map((item, index) => (
              <li key={index} className='flex items-center'>
                <div className="news-dot">&#8226;</div>
                <p className='bold'>{item}</p>
              </li>
            ))}
          </ul>
        </Marquee>
      }
    />
  );
};

export default HorizontalTextScroll;
