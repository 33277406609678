import {
  AccessTimeOutlined,
  Check,
  DeleteOutline,
  MoreHorizOutlined,
  NotificationsActiveOutlined,
} from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  Paper,
  Typography,
} from '@mui/material';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { InfiniteScroll } from 'components';
import { useAnchor } from 'hooks';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { noticeService, queryClient } from 'services';
import { noticeRoute, toRelative } from 'utils/converter';

const NotificationList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [anchorItem, openItem, onOpenItem, onCloseItem] = useAnchor();

  const [chosenItem, setChosenItem] = useState<NoticeType>();

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['getListNotificationByUser'],
    ({ pageParam: page }) => noticeService.getListNotificationByUser({ page }),
    {
      getNextPageParam: (data, pages) => {
        if (pages.length * 10 >= data.total) return undefined;
        return pages.length + 1;
      },
      keepPreviousData: true,
    },
  );

  const { mutate: updateNotification } = useMutation(noticeService.updateNotification, {
    onSuccess: (data, body) => {
      const { id, status }: { id: number; status: number } = body;
      queryClient.setQueryData(['getListNotificationByUser'], ({ pages, pageParams }: any) => ({
        pageParams,
        pages: pages.map((page: GetListNoticeType) => ({
          ...page,
          notifications: page.data.map((notice) => (notice.id === id ? { ...notice, status } : notice)),
        })),
      }));
      queryClient.invalidateQueries(['getListNotificationUnread']);
    },
  });

  const onRead = (item: NoticeType, status: number) => {
    if (item.isSystem) return;
    updateNotification({
      id: item.id,
      idJob: item.job?.id,
      idCv: item.resume?.id,
      type: item.type,
      status,
    });
  };

  const { mutate: removeNotification } = useMutation(noticeService.removeNotification, {
    onSuccess: (data, body) => {
      const { ids }: { ids: number[] } = body;
      queryClient.setQueryData(['getListNotificationByUser'], ({ pages, pageParams }: any) => ({
        pageParams,
        pages: pages.map((page: GetListNoticeType) => ({
          ...page,
          notifications: page.data.filter((notice) => !ids.includes(notice.id)),
        })),
      }));
    },
  });

  return (
    <>
      <div className='flex items-center mb-6'>
        <IconButton>
          <NotificationsActiveOutlined />
        </IconButton>
        <Typography variant='h6'>{t('Notifications')}</Typography>
      </div>

      <Paper>
        <List disablePadding>
          <ListItem divider></ListItem>
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()}>
            {data?.pages.map(({ data: items }, index) => (
              <Fragment key={index}>
                {items.map((item) => (
                  <ListItem
                    key={item.id}
                    dense
                    button
                    divider
                    selected={item.status === 0}
                    className='hover:bg-black/10'
                    onClick={() => {
                      navigate(noticeRoute(item));
                      onRead(item, 1);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar src={item.job?.avatar}>
                        <Avatar src='/gap_logo.png' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      // primary={noticeFormat(item)}
                      primary={item.message}
                      secondary={
                        <div className='flex items-center gap-1'>
                          <AccessTimeOutlined fontSize='small' />
                          {toRelative(item.updateTime)}
                        </div>
                      }
                    />

                    {item.isSystem === 0 && (
                      <ListItemSecondaryAction>
                        <IconButton
                          edge='end'
                          size='small'
                          onClick={(event) => {
                            onOpenItem(event);
                            setChosenItem(item);
                          }}
                        >
                          <MoreHorizOutlined fontSize='small' />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))}
              </Fragment>
            ))}
            {data?.pages.length === 0 && (
              <ListItem>
                <ListItemText primary={t("You don't have any notifications")} />
              </ListItem>
            )}
          </InfiniteScroll>
        </List>

        <Menu anchorEl={anchorItem} open={openItem} onClose={onCloseItem}>
          <ListItemButton
            dense
            onClick={() => {
              onRead(chosenItem!, chosenItem?.status ^ 1);
              onCloseItem();
            }}
          >
            <Check className='mr-2' />
            <ListItemText primary={chosenItem?.status ? t('Mark as unread') : t('Mark as read')} />
          </ListItemButton>
          <ListItemButton
            dense
            onClick={() => {
              removeNotification({ ids: [chosenItem?.id] });
              onCloseItem();
            }}
          >
            <DeleteOutline className='mr-2' />
            <ListItemText primary={t('Remove notification')} />
          </ListItemButton>
        </Menu>
        <ListItem divider></ListItem>
      </Paper>
    </>
  );
};

export default NotificationList;
