import {EditOutlined, Favorite, FavoriteBorder} from '@mui/icons-material';
import { Avatar, Button, Divider, Paper, Typography } from '@mui/material';
import {useMutation, useQuery} from '@tanstack/react-query';
import { decode } from 'html-entities';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { privateRoute } from 'routes';
import {chatService, companyService} from 'services';
import {useState} from "react";

type CompanyPaperProps = {
  id: number;
  extra?: boolean;
  readOnly?: boolean;
};

const CompanyPaper = ({ id, extra = true, readOnly = false }: CompanyPaperProps) => {
  const { t } = useTranslation();
  const { userId, isSuper, isAdmin } = useSelector(profileSelector);

  const [isFollowed, setFollowed] = useState(false);

  const { data: company } = useQuery(['getInfoCompany', id], () => companyService.getInfoCompany({ id }),{
    onSuccess: (data) => {
      const checkFollow = data.isFollowed ?? 0;
      if (checkFollow === 1){
        setFollowed(true);
      }else {
        setFollowed(false);
      }
    },
  });
  const isOwner = userId === company?.idUserCreate;

  const { mutate: createFollow } = useMutation(chatService.createFollow, {
        onSuccess: (data, body) => {
          if (body.status === 1){
            setFollowed(true);
          }else {
            setFollowed(false);
          }
        },
      }
  );

  if (!company) return null;
  return (
    <Paper className='p-4'>
      <div className='flex flex-wrap'>
        <Avatar src={company.image} style={{ width: 120, height: 120, margin: '0px 24px 12px 0px' }} />

        <div className='flex-1 mb-3'>
          <Link to={privateRoute.companyView.url(company.id)}>
            <Typography variant='h6' component='span'>
              {company.name}
            </Typography>
          </Link>
          <Typography color='textSecondary'>{t('Tax code')}: {company.code}</Typography>

          <Typography color='textSecondary'>{t('Address')}: {company.address}</Typography>

          <Typography color='textSecondary'>{t('Hotline')}: {company.hotline}</Typography>

          <Typography color='textSecondary'>{t('Email')}: {company.email}</Typography>

          <Typography color='textSecondary'>
            {t('Website')}: <a href={company.link}>{company.link}</a>
          </Typography>
          <Typography color='textSecondary'>
            {t('Company size')}: {company.companySize} {t('members')}
          </Typography>
        </div>
        {!isOwner &&(
            <div className='follow flex-end mb-3'>
              {isFollowed?(
                  <Button
                      variant='outlined'
                      color='secondary'
                      startIcon={<Favorite />}
                      onClick={() => createFollow({ userId: userId!, followId: company.id+'', type: 1, status: 0 })}
                  > {isFollowed ? 'Followed' : 'Follow'}
                  </Button>
              ):(
                  <Button
                      variant='outlined'
                      color='secondary'
                      startIcon={<FavoriteBorder />}
                      onClick={() => createFollow({ userId: userId!, followId: company.id+'', type: 1, status: 1 })}
                  >{isFollowed ? 'Followed' : 'Follow'}
                  </Button>
              )}

            </div>
        )}
      </div>

      {extra && (
        <>
          <Divider />
          <Typography variant='h6' color='primary'>
            {t('Description')}:
          </Typography>
          <Typography paragraph dangerouslySetInnerHTML={{ __html: decode(company.description) }} />

          <div className='flex gap-4'>
            {(isSuper || isAdmin || isOwner) && (
              <Link to={privateRoute.companyUpdate.url(company.id)}>
                <Button color='success' startIcon={<EditOutlined />}>
                  {t('Edit company')}
                </Button>
              </Link>
            )}
          </div>
        </>
      )}
    </Paper>
  );
};

export default CompanyPaper;
