import { Close, KeyboardArrowDown, KeyboardArrowUp, MoreHoriz, People } from '@mui/icons-material';
import { Avatar, Badge, Collapse, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { PerfectScrollbar } from 'components';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { queryClient, stompClient, userService } from 'services';
import { ChatLastest, ChatUser } from '.';

const ChatSticky = () => {
  const { isLoggedIn, ...profile } = useSelector(profileSelector);

  const [friends, setFriends] = useState<UserType[]>([]);
  useQuery(['getListFriend'], () => userService.getListFriend({ keyword: '' }), {
    onSuccess: (data) => {
      setFriends(data.data);
    },
  });

  const [keyword, setKeyword] = useState('');
  const friendly = useMemo(
    () =>
      friends
        .filter((user) => user.username.includes(keyword.trim()))
        .sort((a, b) => (b.timestamp ?? 0) - (a.timestamp ?? 0)),
    [friends, keyword],
  );

  const [isOpenFriend, setOpenFriend] = useState(false);
  const [openingUsers, setOpeningUsers] = useState<UserType[]>([]);

  useEffect(() => {
    if (isLoggedIn) {
      stompClient.connect(
        {},
        () => {
          stompClient.subscribe(`/user/${profile.userId}/queue/messages`, ({ body }) => {
            const message = JSON.parse(body) as ChatMessageType;
            queryClient.invalidateQueries(['chatService.fetchMessage', profile.userId, message.senderId]);
          });
        },
        console.warn,
      );
    } else {
      if (stompClient.connected) {
        stompClient.disconnect(() => {});
      }
    }
  }, [isLoggedIn, profile.userId]);

  return (
    <div className='fixed bottom-0 right-4 flex flex-row-reverse items-end gap-3'>
      <Paper className='w-[360px] rounded-t-lg'>
        <div className='flex items-center gap-3 p-2 border-b'>
          <Badge
            variant='dot'
            color='secondary'
            overlap='circular'
            classes={{ dot: 'w-[12px] h-[12px] rounded-full' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Avatar variant='circular' src={profile.avatarUrl} style={{ width: 36, height: 36 }} />
          </Badge>
          <Typography variant='subtitle2'>Messaging</Typography>

          <div className='flex-1 flex justify-end'>
            <IconButton size='small'>
              <MoreHoriz />
            </IconButton>
            <IconButton size='small' onClick={() => setOpenFriend((open) => !open)}>
              {isOpenFriend ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </IconButton>
          </div>
        </div>

        <Collapse in={isOpenFriend}>
          <div className='px-3 py-2 border-b'>
            <TextField
              fullWidth
              size='small'
              placeholder='Search people...'
              value={keyword}
              onChange={(event) => setKeyword(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <People />
                  </InputAdornment>
                ),
                endAdornment: keyword && (
                  <InputAdornment position='end'>
                    <IconButton size='small' onClick={() => setKeyword('')}>
                      <Close fontSize='small' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <PerfectScrollbar style={{ height: 'calc(100vh - 240px)' }}>
            {friendly.map((user) => (
              <ChatLastest
                key={user.userId}
                user={user}
                onClick={() => {
                  setOpeningUsers((items) => items.filter((item) => item.userId !== user.userId).concat(user));
                }}
                onTimestamp={(timestamp: number) => {
                  Object.assign(user, { timestamp });
                  setFriends([...friends]);
                }}
              />
            ))}
          </PerfectScrollbar>
        </Collapse>
      </Paper>

      {openingUsers.map((user) => (
        <ChatUser
          key={user.userId}
          user={user}
          onClose={() => {
            setOpeningUsers((items) => items.filter((item) => item.userId !== user.userId));
          }}
        />
      ))}
    </div>
  );
};

export default ChatSticky;
