import { Alert, ChatSticky, LoadingIcon, RecruiterAbout, RecruiterAboutContact, RichTextEditor } from 'components';
import { AppHeader } from 'containers';
import { Avatar, TextField } from '@mui/material';
import { useState } from 'react'
import { Col, Form, Input, Row, Select, Tabs, Upload } from 'antd';
import { LoadingButton } from '@mui/lab';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { companyService, fileService, queryClient, reportService, userService } from 'services';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { BANK_INFO, COMPANY_SIZE, JOB_COUNTRIES } from 'utils/constants';
import { CheckOutlined, AddOutlined } from '@mui/icons-material';
import { UpdatePassword } from 'views/Profile/components';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { decode } from 'html-entities';

const SettingLayout = () => {
  const { t } = useTranslation();
  const { isSuper, isAdmin, userId, isCompany, isRecruit } = useSelector(profileSelector);
  const [form] = Form.useForm();
  const [formCompany] = Form.useForm();
  // const { avatarUrl } = form.getFieldsValue();
  const [avatarUrl, setAvatarUrl] = useState<string>(form.getFieldsValue())
  const [image, setImage] = useState<string>(formCompany.getFieldsValue());
  const [idCompany, setIdCompany] = useState<number>(0)
  const [description, setDescription] = useState('');
  const [dayOfBirth, setDayOfBirth] = useState<number>();
  const { data: user } = useQuery(['getUserInfo', userId], () => userService.getUserInfo({ userId }), {
    onSuccess: (user) => {
      const { dayOfBirth } = user;
      if (dayOfBirth) setDayOfBirth(dayOfBirth);
      // console.log(user)
      setAvatarUrl(user.avatarUrl)
      form.setFieldsValue({ ...user });
    },
  });

  const { mutate: uploadAvatar, isLoading: isLoadingAvatar } = useMutation(fileService.uploadFile, {
    onSuccess: (data) => {
      const { url } = data;
      // console.log(data)
      setAvatarUrl(url)
      form.setFieldsValue({ avatarUrl: url });
    },
  });

  const { mutate: updateUserInfo, isLoading: isLoadingUpdate } = useMutation(userService.updateUserInfo, {
    onSuccess: () => {
      Alert.success({ message: t('Update profile successfully') });
      queryClient.invalidateQueries(['getUserInfo']);
    },
  });

  const handleClickUpdate = () => {
    form.validateFields().then((values) => {
      updateUserInfo({
        userId: userId,
        ...values,
        ...{ dayOfBirth },
      });
    });
  };
  const uploadButton = (
    <div>
      {isLoadingAvatar ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const { mutate: updateJob } = useMutation(
    companyService.updateCompany,
    {
      onSuccess: () => {
        Alert.success({ message: t('Update company successfully') });
      }
    }
  );
  const handleClickUpdateCompany = () => {
    formCompany.validateFields().then((values) => {
      updateJob({
        id: idCompany!,
        ...values,
        ...{ description },
      });
    });
  };
  useQuery(['getInfoCompany'], () => companyService.getInfoCompany({}), {
    enabled: isCompany,
    onSuccess: (company) => {
      if (company.id) {
        setIdCompany(company.id)
        setImage(company.image)
        if (company.description) setDescription(decode(company.description));
        formCompany.setFieldsValue({ ...company });
      }
    },
  });
  const {
    data: {data} = {},
  } = useQuery(['getDashboard'], () => reportService.getDashboard());

  return (
    <>
      <AppHeader />
      <div className='p-6'>
        <p className='text-3xl font-bold my-3'>Cài đặt</p>
        <Tabs tabPosition="left" type="card" className='tabs-settings'>
          {
            (isSuper || isAdmin || isRecruit) &&
            <Tabs.TabPane tab={t('Giới thiệu')} key='intro'>
              {/* <BreadCrumb config={config} /> */}
              <Row gutter={24}>
                <Col span={16} sm={24} xs={24} md={16} lg={16}>
                  <RecruiterAbout 
                    username={user?.username} 
                    type={user?.type} 
                    avatar={user?.avatarUrl} 
                    intro ={user?.introduce}
                    totalUser = {data?.totalUser}
                    totalJob = {data?.totalJob}
                    totalCv ={data?.totalCv}
                   />
                </Col>
                <Col span={8} sm={24} xs={24} md={8} lg={8}>
                  <RecruiterAboutContact />
                </Col>
              </Row>
              {/* <Divider dashed style={{ borderWidth: '2px 0 0' }} />
              <RecruiterJAboutJoin /> */}
            </Tabs.TabPane>
          }
          <Tabs.TabPane tab={t('Đổi mật khẩu')} key='change-password'>
            <Row>
              <Col xl={12} lg={24} span={24}>
                <UpdatePassword />
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('Thông tin cá nhân')} key='personal-info'>
            <Row>
              <Col xl={12} lg={24} span={24}>
                <Form form={form} layout='vertical'>
                  <Form.Item name='avatarUrl' hidden>
                    <Input />
                  </Form.Item>
                  <div>
                    {/* <Avatar src={avatarUrl} className='mr-8' /> */}
                    <Form.Item className='mb-0'>
                      <Upload
                        accept='image/*'
                        listType="picture-card"
                        className='my-3'
                        showUploadList={false}
                        customRequest={({ file }) => {
                          const formData = new FormData();
                          formData.append('file', file);
                          formData.append('fileType', 'avatar_user');
                          uploadAvatar(formData);
                        }}
                      >
                        {avatarUrl ?
                          <img src={avatarUrl} alt="avatar" style={{ width: '100%' }} />
                          : uploadButton}
                      </Upload>
                    </Form.Item>
                    <p style={{ color: 'red' }} className='mb-3'>(*) Click to change avatar</p>
                  </div>

                  <Form.Item name='fullName' label={t('Name')}>
                    <Input />
                  </Form.Item>
                  <Form.Item name='address' label={t('Address')}>
                    <Input />
                  </Form.Item>
                  <Form.Item name='phone' label={t('Phone')}>
                    <Input />
                  </Form.Item>
                  <Form.Item label={t('Date of Birth')}>
                    <DesktopDatePicker
                      value={dayOfBirth ? DateTime.fromMillis(dayOfBirth) : null}
                      onChange={(value) => value?.isValid && setDayOfBirth(value.toMillis())}
                      renderInput={(params) => <TextField size='small' style={{ marginTop: -3 }} {...params} />}
                      inputFormat='dd/MM/yyyy'
                    />
                  </Form.Item>
                  <Form.Item name='idCard' label={t('CMT / CCCD')}>
                    <Input />
                  </Form.Item>

                  <Form.Item name='introduce' label={t('Introduction')}>
                    <Input />
                  </Form.Item>
                  {
                    (isAdmin || isSuper || isRecruit) && (
                      <><Form.Item name='nameBank' label={t('Bank name')}>
                        <Select value={user?.nameBank}>
                          {BANK_INFO.map((item) => (
                            <Select.Option key={item.id} value={item.code}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                        <Form.Item name='branchBank' label={t('Bank branch')}>
                          <Input />
                        </Form.Item>
                        <Form.Item name='numberBank' label={t('Bank account number')}>
                          <Input />
                        </Form.Item>
                        <Form.Item name='accountOwner' label={t('Bank account owner')}>
                          <Input />
                        </Form.Item>
                      </>
                    )
                  }


                  <LoadingButton
                    variant='contained'
                    color='success'
                    startIcon={<CheckOutlined />}
                    loading={isLoadingUpdate}
                    onClick={handleClickUpdate}
                    className='mb-5'
                  >
                    {t('Update user')}
                  </LoadingButton>
                </Form>
              </Col>
            </Row>
          </Tabs.TabPane>
          {
            (isSuper || isAdmin || isCompany) &&
            <Tabs.TabPane tab={t('Thông tin công ty')} key='company-info'>
              <Form form={formCompany} layout='vertical'>
                <Row gutter={24}>
                  <Col lg={12} span={24}>
                    <Row gutter={24}>
                      <Col>
                        <Form.Item name='image' hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item label={t('Picture')} className='Picture-Large'>
                          <Upload
                            accept='image/*'
                            listType='picture-card'
                            showUploadList={false}
                            customRequest={({ file }) => {
                              const formData = new FormData();
                              formData.append('file', file);
                              formData.append('fileType', 'company');
                              uploadAvatar(formData);
                            }}
                          >
                            {image ? (
                              <Avatar variant='square' src={image} />
                            ) : (
                              <LoadingIcon visible={isLoadingAvatar} icon={<AddOutlined />} />
                            )}
                          </Upload>
                        </Form.Item>
                      </Col>
                      <Col flex={1}>
                        <Form.Item name='name' label={t('Name')} rules={[{ required: true, message: t('Name is required') }]}>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name='link'
                          label={t('URL')}
                          rules={[
                            {
                              pattern: /https?:\/\/([\w-]+\.)*([\w-]+)(\.[\w-]+)+(\/.*)?/,
                              message: 'URL is invalid',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col flex={1}>
                        <Form.Item
                          name='code'
                          label={t('Tax code')}
                          rules={[{ required: true, message: t('Tax code is required') }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col flex={1}>
                        <Form.Item
                          name='address'
                          label={t('Address')}
                          rules={[{ required: true, message: t('Address is required') }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col flex={1}>
                        <Form.Item
                          name='hotline'
                          label={t('Hotline')}
                          rules={[{ required: true, message: t('Hotline is required') }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col flex={1}>
                        <Form.Item
                          name='email'
                          label={t('Email')}
                          rules={[{ required: true, message: t('Email is required') }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={12} span={24}>
                        <Form.Item name='companySize' label={t('Company Size')} required initialValue={COMPANY_SIZE[0].code}>
                          <Select>
                            {COMPANY_SIZE.map((item) => (
                              <Select.Option key={item.id} value={item.code}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={12} span={24}>
                        <Form.Item name='country' label={t('Country')} required initialValue={JOB_COUNTRIES[0].code}>
                          <Select>
                            {JOB_COUNTRIES.map((item) => (
                              <Select.Option key={item.id} value={item.code}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={12} span={24}>
                    <Form.Item label={t('Description')}>
                      <RichTextEditor height={320} value={description} onEditorChange={setDescription} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              <LoadingButton
                variant='contained'
                color='success'
                className='mb-3'
                startIcon={<CheckOutlined />}
                loading={isLoadingUpdate}
                onClick={handleClickUpdateCompany}
              >
                {t('Update company')}
              </LoadingButton>
            </Tabs.TabPane>
          }
        </Tabs>
      </div>
      <ChatSticky />
    </>
  )
}

export default SettingLayout