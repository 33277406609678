import {clientDetail, clientFile} from './axios';

const resumeParser = (form: FormData): Promise<any> => clientFile.post(`/lightning/resume-parser/`, form);

const uploadFile = (form: FormData): Promise<UploadResponseType> =>
    clientDetail.post(`/api/v1/storage/upload`, form);

export default {
  resumeParser,
  uploadFile,
};
