import { CheckOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@tanstack/react-query';
import {Form, Input} from 'antd';
import { Alert } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { userService} from 'services';

const UpdatePassword = () => {
  const { t } = useTranslation();
  const { email } = useSelector(profileSelector);

  const [form] = Form.useForm();

  const { mutate: changePassword, isLoading: isLoadingUpdate } = useMutation(userService.changePassword, {
    onSuccess: (data) => {
        if (data.status === 1){
            Alert.success({ message: t('Change password successfully') });
        }else {
            Alert.error({ message: data.message});
        }

      form.resetFields();
    },
  });

  const handleClickUpdate = () => {
    form.validateFields().then((values) => {
      const { newPassword, rePassword } = values;
      if (newPassword !== rePassword) {
        Alert.error({ message: t('Password does not match') });
        return;
      }
      changePassword({ ...values, email });
    });
  };

  return (
    <Form form={form} layout='vertical'>
      <Form.Item
        name='oldPassword'
        label={t('Old Password')}
        rules={[{ required: true, message: t('Old Password is required') }]}
      >
        <Input type='password' />
      </Form.Item>
      <Form.Item
        name='newPassword'
        label={t('New Password')}
        rules={[{ required: true, message: t('New Password is required') }]}
      >
        <Input type='password' />
      </Form.Item>
      <Form.Item
        name='rePassword'
        label={t('Confirm Password')}
        rules={[{ required: true, message: t('Confirm Password is required') }]}
      >
        <Input type='password' />
      </Form.Item>

      <LoadingButton
        variant='contained'
        color='success'
        startIcon={<CheckOutlined />}
        loading={isLoadingUpdate}
        onClick={handleClickUpdate}
        className='mb-5'
      >
        {t('Change password')}
      </LoadingButton>
    </Form>
  );
};

export default UpdatePassword;
