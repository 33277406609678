import { Button, IconButton, Pagination, Paper, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { mailService } from 'services';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import privateRoute from 'routes/privateRoute';
import { AddOutlined } from '@mui/icons-material';
import { Space, Table, Popconfirm, Divider, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { Alert } from 'components';
import { timestampToDdMmYyyy } from 'utils/converter';

interface DataType {
  id: number;
  key: string;
  Name: string;
  Created: number;
  Status: boolean;
  Action: string;
}
const CampaignList = () => {
  const { t } = useTranslation();
  const { isSuper, isAdmin, isCompany } = useSelector(profileSelector);
  const PAGE_SIZE = 10;
  const [pageNumber, setPageNumber] = useState(0);
  const [dataTable, setDataTable] = useState<any>([]);
  const { data: { data = [], total = 0, page = 0, } = {}, refetch, isFetching } = useQuery(
    ['getListCampaign'],
    () =>
      mailService.getListCampaign({
        pageNumber: pageNumber,
        pageSize: PAGE_SIZE,
      }),
    {
      keepPreviousData: true,
      onSuccess: (res) => {
        setDataTable(res.data);
      },
    },
  );
  useEffect(() => {
    refetch();
  },[pageNumber, refetch])
  const { mutate: deleteCampaign } = useMutation(mailService.deleteCampaign, {
    onSuccess: () => {
        Alert.success({ message: t('Delete campaign successfully') });
        refetch();
    },
});
  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Created',
      dataIndex: 'createTime',
      key: 'createTime',
      width:120,
      align: 'center',
      render: (item) => <p>{item && timestampToDdMmYyyy(item)}</p>
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      width:100,
      align: 'center',
      render: (item) => (
        !item ? <Tag color="#FF0000">Draft</Tag>:<Tag color="#108ee9">Sent</Tag>
      )
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      align: 'center',
      dataIndex: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Link to={{
                pathname: privateRoute.EmailCampaignUpdate.url(record.id),
            }}>
                <FormOutlined
                key = 'edit'
                style={{ fontSize: '14px', color: '#0960bd' }}
                
          />
            </Link>
          <Divider type="vertical" />
          <Popconfirm
            key ='delete'
            placement="left"
            title="Bạn có chắc chắn muốn xoá?"
            onConfirm={() => deleteCampaign(String(record.id))}
            okText="Có"
            cancelText="Không">
            <DeleteOutlined style={{ fontSize: '16px', color: '#ed6f6f' }} />
          </Popconfirm>
        </Space>
        )
    }
  ];
  const TablePagination = () => (
    <Pagination
      className='my-5'
      count={Math.ceil(total / PAGE_SIZE)}
      page={page + 1}
      onChange={(event, nextPage) => {
        setPageNumber(nextPage - 1);      
      }}
    />
  );
  return (
    <>
      <Paper className='p-4 flex items-center flex-wrap mb-6'>
        <IconButton>
          <AlternateEmailIcon />
        </IconButton>
        <Typography variant='h6'>{t('Campaign List')}</Typography>
        <div className='flex-1' />
        <div className='flex gap-4'>
          {(isSuper || isAdmin || isCompany) && (
            <Link
              to={{
                pathname: privateRoute.EmailCampaignCreate.path,
              }}
            >
              <Button color='secondary' startIcon={<AddOutlined />}>
                Thêm mới
              </Button>
            </Link>
          )}
        </div>
      </Paper>
      <Paper className='p-6'>
        <Table 
          columns={columns} 
          dataSource={dataTable}
          loading = {isFetching}
          pagination={false}
        />
        <TablePagination />
      </Paper>
    </>
  );
};

export default CampaignList;
