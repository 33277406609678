import { CheckOutlined, PersonOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, IconButton, Paper, TextField, Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, Form, Input, Row, Select, Tabs, Upload } from 'antd';
import { Alert } from 'components';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import {fileService, queryClient, userService} from 'services';
import { BANK_INFO, USER_ROLES, USER_TYPES } from 'utils/constants';
import { UpdatePassword } from './components';

const Profile = () => {
  const { t } = useTranslation();
  const { userId } = useSelector(profileSelector);

  const [form] = Form.useForm();
  const { avatarUrl } = form.getFieldsValue();

  const [dayOfBirth, setDayOfBirth] = useState<number>();

  const { data: user } = useQuery(['getUserInfo', userId], () => userService.getUserInfo({ userId }), {
    onSuccess: ( user ) => {
      const { dayOfBirth } = user;
      if (dayOfBirth) setDayOfBirth(dayOfBirth);

      form.setFieldsValue({ ...user });
    },
  });

  const { mutate: uploadAvatar, isLoading: isLoadingAvatar } = useMutation(fileService.uploadFile, {
    onSuccess: (data) => {
      const { url } = data;
      form.setFieldsValue({ avatarUrl: url });
    },
  });

  const { mutate: updateUserInfo, isLoading: isLoadingUpdate } = useMutation(userService.updateUserInfo, {
    onSuccess: () => {
      Alert.success({ message: t('Update profile successfully') });
      queryClient.invalidateQueries(['getUserInfo']);
    },
  });

  const handleClickUpdate = () => {
    form.validateFields().then((values) => {
      updateUserInfo({
        userId: userId,
        ...values,
        ...{ dayOfBirth },
      });
    });
  };

  return (
    <>
      <div className='flex items-center mb-6'>
        <IconButton>
          <PersonOutlined />
        </IconButton>
        <Typography variant='h6'>{t('Profile')}</Typography>
      </div>

      <Row gutter={[24, 24]}>
        <Col xl={8} lg={12} span={24}>
          <Paper className='p-4'>
            <Box className='Picture-Large flex flex-col justify-center items-center mb-6'>
              <Avatar src={avatarUrl} />
              <Typography variant='h6' color='textSecondary'>
                {user?.fullName}
              </Typography>
            </Box>
            {[
              { label: t('Username'), value: user?.username },
              { label: t('Email'), value: user?.email },
              { label: t('Type'), value: USER_TYPES.find((item) => item.code === user?.userType)?.name },
              { label: t('Role'), value: USER_ROLES.find((item) => item.code === user?.roleId)?.name },
              { label: t('Address'), value: user?.address },
              { label: t('CMT / CCCD'), value: user?.idCard },
              { label: t('Introduction'), value: user?.introduce },
              { label: t('Bank name'), value: user?.nameBank },
              { label: t('Bank branch'), value: user?.branchBank },
              { label: t('Bank account number'), value: user?.numberBank },
              { label: t('Bank account owner'), value: user?.accountOwner },

            ].map(({ label, value }, index) => (
              <Row gutter={24} key={index} className='mb-3'>
                <Col xl={8} lg={24} md={8} span={24}>
                  <Typography color='textSecondary'>{label}</Typography>
                </Col>
                <Col xl={16} lg={24} md={16} span={24}>
                  <Typography style={{ wordBreak: 'break-word' }}>{value}</Typography>
                </Col>
              </Row>
            ))}
          </Paper>
        </Col>
        <Col xl={16} lg={12} span={24}>
          <Paper className='px-4 pb-4'>
            <Tabs animated >
              <Tabs.TabPane tab={t('Account Info')} key='account-info'>
                <Row>
                  <Col xl={12} lg={24} span={24}>
                    <Form form={form} layout='vertical'>
                      <Form.Item name='avatarUrl' hidden>
                        <Input />
                      </Form.Item>
                      <Box display='flex'>
                        <Avatar src={avatarUrl} className='mr-8' />
                        <Form.Item>
                          <Upload
                            accept='image/*'
                            showUploadList={false}
                            customRequest={({ file }) => {
                              const formData = new FormData();
                              formData.append('file', file);
                              formData.append('fileType', 'avatar_user');
                              uploadAvatar(formData);
                            }}
                          >
                            <LoadingButton variant='outlined' color='inherit' loading={isLoadingAvatar}>
                              {t('Change avatar')}
                            </LoadingButton>
                          </Upload>
                        </Form.Item>
                      </Box>

                      <Form.Item name='fullName' label={t('Name')}>
                        <Input />
                      </Form.Item>
                      <Form.Item name='address' label={t('Address')}>
                        <Input />
                      </Form.Item>
                      <Form.Item name='phone' label={t('Phone')}>
                        <Input />
                      </Form.Item>
                      <Form.Item label={t('Date of Birth')}>
                        <DesktopDatePicker
                          value={dayOfBirth ? DateTime.fromMillis(dayOfBirth) : null}
                          onChange={(value) => value?.isValid && setDayOfBirth(value.toMillis())}
                          renderInput={(params) => <TextField size='small' style={{ marginTop: -3 }} {...params} />}
                          inputFormat='dd/MM/yyyy'
                        />
                      </Form.Item>
                      <Form.Item name='idCard' label={t('CMT / CCCD')}>
                        <Input />
                      </Form.Item>

                      <Form.Item name='introduce' label={t('Introduction')}>
                        <Input />
                      </Form.Item>
                      <Form.Item name='nameBank' label={t('Bank name')}>
                        <Select value={user?.nameBank}>
                          {BANK_INFO.map((item) => (
                            <Select.Option key={item.id} value={item.code}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item name='branchBank' label={t('Bank branch')}>
                        <Input />
                      </Form.Item>
                      <Form.Item name='numberBank' label={t('Bank account number')}>
                        <Input />
                      </Form.Item>
                      <Form.Item name='accountOwner' label={t('Bank account owner')}>
                        <Input />
                      </Form.Item>

                      <LoadingButton
                        variant='contained'
                        color='success'
                        startIcon={<CheckOutlined />}
                        loading={isLoadingUpdate}
                        onClick={handleClickUpdate}
                      >
                        {t('Update user')}
                      </LoadingButton>
                    </Form>
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab={t('Change Password')} key='change-password'>
                <Row>
                  <Col xl={12} lg={24} span={24}>
                    <UpdatePassword />
                  </Col>
                </Row>
              </Tabs.TabPane>
            </Tabs>
          </Paper>
        </Col>
      </Row>
    </>
  );
};
export default Profile;
