import { AddOutlined, CheckOutlined, NavigateBeforeOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Avatar, IconButton, Paper, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, Form, Input, Row, Select, Upload } from 'antd';
import { Alert, LoadingIcon, RichTextEditor } from 'components';
import { decode } from 'html-entities';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { privateRoute } from 'routes';
import { companyService, fileService } from 'services';
import { JOB_COUNTRIES, COMPANY_SIZE } from 'utils/constants';

const CompanyUpdate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  const [form] = Form.useForm();
  const { image } = form.getFieldsValue();

  const [description, setDescription] = useState('');

  useQuery(['getInfoCompany', id], () => companyService.getInfoCompany({ id: +id! }), {
    onSuccess: (data) => {
      const { description, ...company } = data;
      if (description) setDescription(decode(description));

      form.setFieldsValue({ ...company });
    },
    enabled: !!id,
  });

  const { mutate: uploadAvatar, isLoading: isLoadingAvatar } = useMutation(fileService.uploadFile, {
    onSuccess: (data) => {
      const { url } = data;
      form.setFieldsValue({ image: url });
    },
  });

  const { mutate: updateJob, isLoading: isLoadingUpdate } = useMutation(
    id ? companyService.updateCompany : companyService.createCompany,
    {
      onSuccess: () => {
        if (id) {
          Alert.success({ message: t('Update company successfully') });
          navigate(privateRoute.companyView.url(id));
        } else {
          Alert.success({ message: t('Create company successfully') });
          navigate(privateRoute.companyList.path);
        }
      },
    },
  );

  const handleClickUpdate = () => {
    form.validateFields().then((values) => {
      updateJob({
        id: +id!,
        ...values,
        ...{ description },
      });
    });
  };

  return (
    <>
      <div className='flex items-center flex-wrap mb-6'>
        <Link to={privateRoute.companyList.path}>
          <IconButton>
            <NavigateBeforeOutlined />
          </IconButton>
        </Link>
        <Typography variant='h6'>{id ? t('Update company') : t('Create company')}</Typography>
      </div>

      <Paper className='p-4'>
        <Form form={form} layout='vertical'>
          <Row gutter={24}>
            <Col lg={12} span={24}>
              <Row gutter={24}>
                <Col>
                  <Form.Item name='image' hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item label={t('Picture')} className='Picture-Large'>
                    <Upload
                      accept='image/*'
                      listType='picture-card'
                      showUploadList={false}
                      customRequest={({ file }) => {
                        const formData = new FormData();
                        formData.append('file', file);
                        formData.append('fileType', 'company');
                        uploadAvatar(formData);
                      }}
                    >
                      {image ? (
                        <Avatar variant='square' src={image} />
                      ) : (
                        <LoadingIcon visible={isLoadingAvatar} icon={<AddOutlined />} />
                      )}
                    </Upload>
                  </Form.Item>
                </Col>
                <Col flex={1}>
                  <Form.Item name='name' label={t('Name')} rules={[{ required: true, message: t('Name is required') }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name='link'
                    label={t('URL')}
                    rules={[
                      {
                        pattern: /https?:\/\/([\w-]+\.)*([\w-]+)(\.[\w-]+)+(\/.*)?/,
                        message: 'URL is invalid',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                  <Form.Item
                      name='code'
                      label={t('Tax code')}
                      rules={[{ required: true, message: t('Tax code is required') }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                  <Form.Item
                    name='address'
                    label={t('Address')}
                    rules={[{ required: true, message: t('Address is required') }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                  <Form.Item
                    name='hotline'
                    label={t('Hotline')}
                    rules={[{ required: true, message: t('Hotline is required') }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                  <Form.Item
                    name='email'
                    label={t('Email')}
                    rules={[{ required: true, message: t('Email is required') }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xl={12} span={24}>
                  <Form.Item name='companySize' label={t('Company Size')} required initialValue={COMPANY_SIZE[0].code}>
                    <Select>
                      {COMPANY_SIZE.map((item) => (
                          <Select.Option key={item.id} value={item.code}>
                            {item.name}
                          </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xl={12} span={24}>
                  <Form.Item name='country' label={t('Country')} required initialValue={JOB_COUNTRIES[0].code}>
                    <Select>
                      {JOB_COUNTRIES.map((item) => (
                        <Select.Option key={item.id} value={item.code}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col lg={12} span={24}>
              <Form.Item label={t('Description')}>
                <RichTextEditor height={320} value={description} onEditorChange={setDescription} />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <LoadingButton
          variant='contained'
          color='success'
          startIcon={id ? <CheckOutlined /> : <AddOutlined />}
          loading={isLoadingUpdate}
          onClick={handleClickUpdate}
        >
          {id ? t('Update company') : t('Create company')}
        </LoadingButton>
      </Paper>
    </>
  );
};

export default CompanyUpdate;
