import { LoadingButton } from '@mui/lab';
import { AddOutlined, CheckOutlined, NavigateBeforeOutlined } from '@mui/icons-material';
import { IconButton, Paper, Typography } from '@mui/material';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import privateRoute from 'routes/privateRoute';
import { useTranslation } from 'react-i18next';
import { mailService } from 'services';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Alert } from 'components';
import SelectJob from './components/SelectJob';
import SelectCV from './components/SelectCV';
import SelectTemplate from './components/SelectTemplate';

const CampaignUpdate = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const [jobIdSelected, setJobIdSelected] = useState<number>(11110);
  const { t } = useTranslation();
  useQuery(['getDetailCampaign', id], () => mailService.getDetailCampaign(id!), {
    onSuccess: (res) => {
      const { data } = res;
      console.log(data);
      form.setFieldsValue({ ...data });
      setJobIdSelected(data.idJob)
    },
    enabled: !!id,
  });
  const { mutate: updateCampaign, isLoading: isLoadingUpdate } = useMutation(
    id ? mailService.updateCampaign : mailService.createCampaign,
    {
      onSuccess: (res) => {
        if (id) {
          Alert.success({ message: t('Update campaign successfully') });
          navigate(privateRoute.EmailCampaignView.url(id));
        } else {
          Alert.success({ message: t('Create campaign successfully') });
          navigate(privateRoute.EmailCampaignView.url(res.data.id));
        }
      },
    },
  );
  
  const handleClickUpdate = () => {
    form.validateFields().then((values) => {
      updateCampaign({
        id: id!,
        ...values,
        active: true
      });
    });
  };
  const onChangeSelectedJob = (value: number) => {
    setJobIdSelected(Number(value));
  }
  const onChangeSelectedCV = (value: any) => {}
  return (
    <>
      <div className='flex items-center mb-6'>
        <Link to={privateRoute.EmailCampaignList.path}>
          <IconButton>
            <NavigateBeforeOutlined />
          </IconButton>
        </Link>
        <Typography variant='h6'>{id ? t('Update campaign') : t('Create campaign')}</Typography>
      </div>
      <Row gutter={24}>
        <Col lg={18}>
          <Paper className='p-4'>
            <Form form={form} layout='vertical'>
              <Row gutter={24}>
                <Col lg={4}>
                  <Typography className='strong text-base font-bold'>Name</Typography>
                </Col>
                <Col lg={20} span={20}>
                  <Form.Item name='name' label={''} rules={[{ required: true, message: 'Campaign Name is required' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col lg={4}>
                  <Typography className='strong text-base font-bold'>Subject</Typography>
                </Col>
                <Col lg={20} span={20}>
                  <Form.Item name='subject' label={''} rules={[{ required: true, message: 'Subject is required' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col lg={4}>
                  <Typography className='strong text-base font-bold'>Job</Typography>
                </Col>
                <Col lg={20} span={20}>
                  <Form.Item 
                    name='idJob' 
                    label={''}
                    rules={[{ required: true, message: 'Job is required' }]}>
                    <SelectJob onChange = {onChangeSelectedJob}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col lg={4}>
                  <Typography className='strong text-base font-bold'>Candidate</Typography>
                </Col>
                <Col lg={20} span={20}>
                  <Form.Item name='idCvs' label={''} rules={[{ required: true, message: 'Candidate is required' }]}>
                    <SelectCV jobId={jobIdSelected} onChange={onChangeSelectedCV}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col lg={4}>
                  <Typography className='strong text-base font-bold'>Templates</Typography>
                </Col>
                <Col lg={20} span={20}>
                  <Form.Item 
                  name='idMailTemplate' label={''}
                  rules={[{ required: true, message: 'Template email is required' }]}>
                    <SelectTemplate />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col lg={4} span={4}></Col>
                <Col lg={20} span={20}>
                <Button
                  className='mr-4'
                  onClick={() => navigate(privateRoute.EmailCampaignList.path)}
                >
                  Cancel
                </Button>
                  <LoadingButton
                    variant='contained'
                    color='success'
                    startIcon={id ? <CheckOutlined /> : <AddOutlined />}
                    loading={isLoadingUpdate}
                    onClick={handleClickUpdate}
                  >
                    {id ? t('Update campaign') : t('Create campaign')}
                  </LoadingButton>
                </Col>
              </Row>
            </Form>
          </Paper>
        </Col>
      </Row>
    </>
  );
};

export default CampaignUpdate;
