import {
  AddOutlined,
  AssignmentIndOutlined,
  ClearAll,
  DeleteOutlined,
  DirectionsOutlined,
  MoreVert,
} from '@mui/icons-material';
import {
  Avatar,
  Button,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Pagination,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Dropdown, Modal, Table } from 'antd';
import { Alert } from 'components';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { privateRoute } from 'routes';
import { companyService } from 'services';
import { CompanySearch } from './components';

const CompanyList = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { isCompany } = useSelector(profileSelector);

  const [dataSearch, onSearchChange] = useSearch();
  const [dataSelect, setDataSelect] = useState<CompanyType[]>([]);

  const {
    data: { data: items = [], total = 0 } = {},
    isFetching,
    refetch,
  } = useQuery(['getListInfoCompany', dataSearch], () => companyService.getListInfoCompany(dataSearch), {
    keepPreviousData: true,
    enabled: !isCompany,
  });

  useQuery(['getInfoCompany'], () => companyService.getInfoCompany({}), {
    enabled: isCompany,
    onSuccess: (company) => {
      if (company.id) {
        navigate(privateRoute.companyView.url(company.id), { replace: true });
      } else {
        navigate(privateRoute.companyCreate.path, { replace: true });
      }
    },
  });

  const { mutate: deleteCompany } = useMutation(companyService.deleteCompany, {
    onSuccess: () => {
      Alert.success({ message: t('Delete company successfully') });

      refetch();
    },
  });

  const TablePagination = () => (
    <Pagination
      count={Math.ceil(total / 10)}
      page={dataSearch.page + 1}
      onChange={(event, nextPage) => {
        onSearchChange({ page: nextPage - 1 });
      }}
    />
  );

  return (
    <>
      <div className='flex items-center flex-wrap mb-6'>
        <IconButton>
          <AssignmentIndOutlined />
        </IconButton>
        <Typography variant='h6'>{t('Company list')}</Typography>

        <div className='flex-1' />
        <Link to={privateRoute.companyCreate.path}>
          <Button color='secondary' startIcon={<AddOutlined />}>
            {t('Create company')}
          </Button>
        </Link>
      </div>
      <CompanySearch onSearch={onSearchChange} />

      <Paper className='flex justify-between items-center flex-wrap p-4 mb-6'>
        <Typography variant='subtitle1' color='green'>
          {total} {t('companies matched')}
        </Typography>
        <TablePagination />
      </Paper>

      <Paper className='flex justify-between items-center flex-wrap p-4 mb-3'>
        <Typography variant='subtitle1' color='secondary'>
          {t('Select')}: {dataSelect.length} record{dataSelect.length > 1 ? 's' : ''}
        </Typography>
        <Tooltip title={t('Clear all')}>
          <IconButton size='small' onClick={() => setDataSelect([])}>
            <ClearAll />
          </IconButton>
        </Tooltip>
      </Paper>

      <Paper className='mb-6'>
        <Table
          scroll={{ x: 800 }}
          bordered={false}
          loading={isFetching}
          rowKey={(record) => record.id}
          dataSource={items}
          pagination={false}
          rowSelection={{
            selectedRowKeys: dataSelect.map((record) => record.id),
            preserveSelectedRowKeys: true,
            onChange: (selectedRowKeys: React.Key[], selectedRows: CompanyType[]) => {
              setDataSelect(selectedRows);
            },
          }}
          columns={[
            {
              title: 'Company',
              dataIndex: 'name',
              width: 240,
              render: (_, record) => (
                <div className='flex items-center gap-3'>
                  <Avatar src={record.image} />
                  <Typography component={Link} to={privateRoute.companyView.url(record.id)}>
                    {record.name}
                  </Typography>
                </div>
              ),
            },
            { title: 'Address', dataIndex: 'address' },
            { title: 'Hotline', dataIndex: 'hotline' },
            { title: 'Email', dataIndex: 'email' },
            { title: 'URL', dataIndex: 'link', render: (_, record) => <a href={record.link}>{record.link}</a> },
            {
              dataIndex: '',
              align: 'right',
              width: 40,
              render: (_, record) => (
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <List component={Paper}>
                      <Link to={privateRoute.companyView.url(record.id)}>
                        <ListItemButton>
                          <ListItemIcon>
                            <DirectionsOutlined color='secondary' />
                          </ListItemIcon>
                          <ListItemText primary={t('View detail')} className='text-black/80' />
                        </ListItemButton>
                      </Link>
                      <ListItemButton
                        onClick={() => {
                          Modal.confirm({
                            title: t('Confirmation'),
                            content: t('Are you sure you want to delete this company?'),
                            onOk: () => deleteCompany({ id: record.id }),
                            okText: 'Delete',
                            okType: 'danger',
                          });
                        }}
                      >
                        <ListItemIcon>
                          <DeleteOutlined color='error' />
                        </ListItemIcon>
                        <ListItemText primary={t('Delete')} />
                      </ListItemButton>
                    </List>
                  }
                >
                  <IconButton size='small'>
                    <MoreVert />
                  </IconButton>
                </Dropdown>
              ),
            },
          ]}
        />
      </Paper>

      {total > 0 && (
        <div className='flex justify-center'>
          <TablePagination />
        </div>
      )}
    </>
  );
};

export default CompanyList;
