import { SearchOutlined } from '@mui/icons-material';
import { Button, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const CompanySearch = ({ onSearch }: SearchController) => {
  const { t } = useTranslation();

  const [keyword, setKeyword] = useState('');

  const handleClickSearch = () => {
    onSearch({ keyword });
  };

  return (
    <>
      <div className='flex gap-3 mb-6'>
        <TextField
          fullWidth
          label={t('Find campany by keyword')}
          value={keyword}
          onChange={(event) => setKeyword(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
        <Button className='md:w-[420px]' onClick={handleClickSearch}>
          {t('Search')}
        </Button>
      </div>
    </>
  );
};

export default CompanySearch;
