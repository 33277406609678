import { NavigateBeforeOutlined } from '@mui/icons-material';
import { Hidden, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { privateRoute } from 'routes';
import { JobList } from 'views/Job';
import { CompanyPaper } from './components';

const CompanyView = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <div className='flex items-start'>
      <div style={{ position: 'sticky', top: 64 + 24, flex: 1 }}>
        <div className='flex items-center mb-6'>
          <Link to={privateRoute.companyList.path}>
            <IconButton>
              <NavigateBeforeOutlined />
            </IconButton>
          </Link>
          <Typography variant='h6'>{t('Company info')}</Typography>
        </div>
        <CompanyPaper id={+id!} />
      </div>

      <Hidden mdDown>
        <div style={{ width: 600, marginLeft: 24 }}>
          <JobList showSearch={false} showBanner={false} searchParams={{ idCompany: +id! }} />
        </div>
      </Hidden>
    </div>
  );
};

export default CompanyView;
