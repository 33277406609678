import { client } from './axios';

const api = '/api/v1/comment';

const getJobComment = ({ ...params }): Promise<GetListCommentType> =>
    client.get(`${api}/get-comment`, { params });

const createJobComment = ({ ...body }) => client.post(`${api}/create-comment`, { ...body });

export const commentService ={
    getJobComment,
    createJobComment
}