import { NavigateBeforeOutlined } from '@mui/icons-material';
import { Avatar, Box, IconButton, Paper, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Col, Form, Input, Row, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { privateRoute } from 'routes';
import { userService } from 'services';
import { USER_ROLES, USER_TYPES } from 'utils/constants';
import { toFormat } from 'utils/converter';

const UserView = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [form] = Form.useForm();

  const { data: user } = useQuery(['getUserInfo', id], () => userService.getUserInfo({ userId: id }), {
    onSuccess: ({ user }) => {
      form.setFieldsValue({ ...user });
    },
  });

  return (
    <>
      <div className='flex items-center flex-wrap mb-6'>
        <Link to={privateRoute.userList.path}>
          <IconButton>
            <NavigateBeforeOutlined />
          </IconButton>
        </Link>
        <Typography variant='h6'>{t('User information')}</Typography>
      </div>

      <Row gutter={[24, 24]}>
        <Col xl={8} lg={12} span={24}>
          <Paper className='p-4'>
            <Box className='Picture-Large flex flex-col justify-center items-center mb-6'>
              <Avatar src={user?.avatarUrl} />
              <Typography variant='h6' color='textSecondary'>
                {user?.fullName}
              </Typography>
            </Box>
            {[
              { label: t('Username'), value: user?.username },
              { label: t('Email'), value: user?.email },
              { label: t('Type'), value: USER_TYPES.find((item) => item.code === user?.userType)?.name },
              { label: t('Role'), value: USER_ROLES.find((item) => item.code === user?.roleId)?.name },
            ].map(({ label, value }, index) => (
              <Row gutter={24} key={index} className='mb-3'>
                <Col xl={8} lg={24} md={8} span={24}>
                  <Typography color='textSecondary'>{label}</Typography>
                </Col>
                <Col xl={16} lg={24} md={16} span={24}>
                  <Typography style={{ wordBreak: 'break-word' }}>{value}</Typography>
                </Col>
              </Row>
            ))}
          </Paper>
        </Col>
        <Col xl={16} lg={12} span={24}>
          <Paper className='px-4 pb-4'>
            <Tabs animated>
              <Tabs.TabPane tab={t('Account Info')} key='account-info'>
                <Row>
                  <Col xl={12} lg={24} span={24}>
                    <Form form={form} layout='vertical'>
                      <Form.Item name='fullName' label={t('Name')}>
                        <Input disabled />
                      </Form.Item>
                      <Form.Item name='phone' label={t('Phone')}>
                        <Input disabled />
                      </Form.Item>
                      <Form.Item label={t('Date of Birth')}>
                        <Input disabled value={toFormat(user?.dayOfBirth)} />
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Tabs.TabPane>
            </Tabs>
          </Paper>
        </Col>
      </Row>
    </>
  );
};

export default UserView;
