import {
  AccessTimeOutlined,
  HistoryRounded,
  MoreHorizOutlined,
} from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { InfiniteScroll } from 'components';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {historyService} from 'services';
import {getImageHistory, toRelative} from 'utils/converter';

const HistoryView = () => {
  const { t } = useTranslation();

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['getListHistories'],
    ({ pageParam: page }) => historyService.getListHistories({ page: page ?? 0}),
    {
      getNextPageParam: (data, pages) => {
        if (pages.length * 10 >= data.total) return undefined;
        return pages.length + 1;
      },
      keepPreviousData: true,
    },
  );

  return (
    <>
      <div className='flex items-center mb-6'>
        <IconButton>
          <HistoryRounded />
        </IconButton>
        <Typography variant='h6'>{t('Histories')}</Typography>
      </div>

      <Paper>
        <List disablePadding>
          <ListItem divider></ListItem>
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()}>
            {data?.pages.map(({ data: items }, index) => (
              <Fragment key={index}>
                {items.map((item) => (
                  <ListItem
                    key={item.id}
                    dense
                    button
                    divider
                    className='hover:bg-black/10'
                  >
                    <ListItemAvatar>
                      <Avatar src={getImageHistory(item)}>
                        <Avatar src='/gap_logo.png' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.message}
                      secondary={
                        <div className='flex items-center gap-1'>
                          <AccessTimeOutlined fontSize='small' />
                          {toRelative(item.createTime)}
                        </div>
                      }
                    />

                    <ListItemSecondaryAction>
                      <IconButton
                          edge='end'
                          size='small'
                      >
                        <MoreHorizOutlined fontSize='small' />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </Fragment>
            ))}
            {data?.pages.length === 0 && (
              <ListItem>
                <ListItemText primary={t("You don't have any histories")} />
              </ListItem>
            )}
          </InfiniteScroll>
        </List>

        <ListItem divider></ListItem>
      </Paper>
    </>
  );
};

export default HistoryView;
