import { Form, FormInstance, Input } from 'antd';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Switch } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Alert } from 'components';
import { adsService } from 'services';
import { useMutation } from '@tanstack/react-query';
interface IFormCreateProps {
    onClose: () => void;
    showDrawer?: () => void;
    open: boolean;
    setFoceUpdate?: Dispatch<SetStateAction<boolean>>;
    foceUpdate?: boolean;
    form?: FormInstance<any>;
}
const FormCreate: FC<IFormCreateProps> = ({ onClose, open, foceUpdate, setFoceUpdate }) => {
    const [form] = Form.useForm();
    const { TextArea } = Input;
    useEffect(() => {
        form.resetFields();
    }, [open, form]);
    const onFinish = async () => {
        await form?.validateFields();
        const data = await form?.getFieldsValue();
        createBreakNews(data);
    };
    const { t } = useTranslation();
    const onChangeSwitch = (value: any) => {
        form.setFieldsValue({ active: value });
    };
    const { mutate: createBreakNews, isLoading: isLoadingUpdate } = useMutation(adsService.createBreakNews, {
        onSuccess: (res) => {
            setFoceUpdate && setFoceUpdate(!foceUpdate);
            onClose && onClose();
            Alert.success({ message: t('Create break news successfully') });
        },
    });
    return (
        <div>
            <Form form={form} layout='vertical' key={open ? 'form-open' : 'form-closed'}>
                <Dialog open={open} onClose={onClose} disableEscapeKeyDown sx ={{with: 600}} >
                    <DialogTitle>
                        Create break news
                        <IconButton
                            aria-label='close'
                            sx={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                color: (theme) => theme.palette.grey[500],
                            }}
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Form.Item name='content' label='Content' rules={[{ required: true, message: t('Content is required') }]}>
                            <TextArea
                                rows={4}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        onFinish();
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Active'
                            name='active'
                            rules={[{ required: true, message: t('Active is required') }]}
                            initialValue={false}
                        >
                            <Switch onChange={(event: any) => onChangeSwitch(event.target.checked)} />
                        </Form.Item>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color='primary'>
                            Cancel
                        </Button>
                        <Button onClick={onFinish} color='primary'>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Form>
        </div>
    );
};

export default FormCreate;
